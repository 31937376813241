export const nodeList1 = [
  {
    index: 0,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 5.687869822,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 1,
    speciesID: 300,
    biomass: 47981.15827,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Plants",
    trophicLevel: 1.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 2,
    speciesID: 350,
    biomass: 45684.9337,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Wave Attenuation",
    trophicLevel: 2.0,
    desc: "Wave attenuation is the decrease in wave intensity. Plants and other aquatic structures (e.g., coral reefs) play a large role in taming waves and violent storms that could damage shorelines.",
    imgFile: "wave.jpg",
    imgCaption: "Ocean waves.",
  },
  {
    index: 3,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organism",
    trophicLevel: 4.459143421,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 4,
    speciesID: 450,
    biomass: 47509.3846,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Shoreline Protection",
    trophicLevel: 2.0,
    desc: "Shoreline protection is the strengthening and stabilizing of coastal areas. Naturally, plants with deep roots can prevent erosion, enhancing shoreline protection.",
    imgFile: "saltmarsh.jpg",
    imgCaption: "Saltmarsh.",
  },
  {
    index: 5,
    speciesID: 500,
    biomass: 1788.910924,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Molluscs",
    trophicLevel: 3.72957171,
    desc: "Molluscs are invertebrate animals, meaning they do not have backbones. There are more than 85,000 species of molluscs today. Mollsucs are shell-forming animals and are vulnerable to ocean acidifcation and pollution.",
    imgFile: "mussels.jpg",
    imgCaption: "Many mussels underwater.",
  },
  {
    index: 6,
    speciesID: 550,
    biomass: 49779.82555,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Carbon Storage",
    trophicLevel: 5.136526134,
    desc: "Carbon storage (also known as carbon sequestration) is the process of capturing and storing carbon. Carbon can be stored in plants, soils and other organic materials.",
    imgFile: "carbon.jpg",
    imgCaption: "Salt marsh with vehicle in the background.",
  },
  {
    index: 7,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 5.035502959,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 8,
    speciesID: 650,
    biomass: 47789.69028,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Water Filtration",
    trophicLevel: 3.364785855,
    desc: "Water filtration is the process of removing and reducing the number of particles suspended in water. This helps improve water clarity by reducing sediment loads.",
    imgFile: "filtration.jpg",
    imgCaption: "Mussels filtering water.",
  },
  {
    index: 9,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Plankton",
    trophicLevel: 3.802690899,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 10,
    speciesID: 750,
    biomass: 0.9714345,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Commercial Fishery",
    trophicLevel: 5.772048464,
    desc: "Commercial fisheries are large-scale harvesting operations that target fish and other seafood for commercial profit. These fisheries do not occur in salt marshes. However, salt marshes and other coastal ecosystems like mangroves provide importance habitat and breeding grounds for many commercially important species.",
    imgFile: "comm-fish.jpg",
    imgCaption: "Commercial fishing boat in a harbor.",
  },
  {
    index: 11,
    speciesID: 800,
    biomass: 191.3924808,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Crustaceans",
    trophicLevel: 4.605381798,
    desc: 'Crustaceans are invertebrate animals, meaning they do not have backbones. There are many different types of crustaceans, but they are generally categorixed by a hard exoskeleton, a segmented body and at least 4 pairs of jointed appendages ("legs" and "claws"). Crustaceans are vulnerable to over fishing, plastic pollution, habitat loss and ocean acidification. Ocean acidification occurs when there are changes in the chemical make up (specifically decreases in the pH due to increased carbon dioxide) of oceans, which can make it difficult for shell-builiding organisms to build their shells.',
    imgFile: "crab.jpg",
    imgCaption: "Crab standing in sand.",
  },
  {
    index: 12,
    speciesID: 850,
    biomass: 0.369631471,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Circle",
    nodeName: "Birdwatching",
    trophicLevel: 5.914905607,
    desc: "Birdwatching, or birding, is a type of wildlife viewing, where people track, look and listen for birds. This can be done for recreation and/or citizen science.",
    imgFile: "birdwatch.jpg",
    imgCaption: "Binoculars on bird guide.",
  },
  {
    index: 13,
    speciesID: 900,
    biomass: 19.1147404,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Fish",
    trophicLevel: 4.772048464,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
  {
    index: 14,
    speciesID: 950,
    biomass: 0.8204547,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Waterfowl Hunting",
    trophicLevel: 5.914905607,
    desc: "Waterfowl hunting is the activity of hunting ducks, geese or other water birds for fun and/or food.",
    imgFile: "wfowl-hunt.jpg",
    imgCaption: "Dog carrying waterfowl in grass.",
  },
  {
    index: 15,
    speciesID: 1000,
    biomass: 3.173470346,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Birds",
    trophicLevel: 4.914905607,
    desc: "Birds are vertebrates charactereized by feathers, a beak, and wings. There are more than 9,000 species of birds with a wide array of fascinating behaviors. Like fish, they vary in their vulnerability but are generally impacted by pollution and habitat loss.",
    imgFile: "birds.jpg",
    imgCaption: "Flock of birds flying over water at sunset.",
  },
  {
    index: 16,
    speciesID: 1050,
    biomass: 584.6903931,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Recreational Fishery",
    trophicLevel: 5.250810087,
    desc: "Recreational fishing involves fishing for fun. This type of fishing can take place on small boats, on the shoreline, or on piers in salt marshes.",
    imgFile: "rec-fish.jpg",
    imgCaption: "Fishing rod on pier over water.",
  },
];

export const edgeList1 = [
  { target: 1, source: 3, Type: "Feeding" },
  { target: 13, source: 3, Type: "Feeding" },
  { target: 11, source: 3, Type: "Feeding" },
  { target: 1, source: 5, Type: "Feeding" },
  { target: 3, source: 5, Type: "Feeding" },
  { target: 15, source: 7, Type: "Feeding" },
  { target: 0, source: 7, Type: "Feeding" },
  { target: 13, source: 7, Type: "Feeding" },
  { target: 1, source: 7, Type: "Feeding" },
  { target: 1, source: 9, Type: "Feeding" },
  { target: 9, source: 7, Type: "Feeding" },
  { target: 1, source: 11, Type: "Feeding" },
  { target: 3, source: 11, Type: "Feeding" },
  { target: 5, source: 11, Type: "Feeding" },
  { target: 9, source: 11, Type: "Feeding" },
  { target: 7, source: 11, Type: "Feeding" },
  { target: 3, source: 13, Type: "Feeding" },
  { target: 9, source: 13, Type: "Feeding" },
  { target: 11, source: 13, Type: "Feeding" },
  { target: 1, source: 13, Type: "Feeding" },
  { target: 5, source: 13, Type: "Feeding" },
  { target: 7, source: 13, Type: "Feeding" },
  { target: 3, source: 15, Type: "Feeding" },
  { target: 5, source: 15, Type: "Feeding" },
  { target: 7, source: 15, Type: "Feeding" },
  { target: 13, source: 15, Type: "Feeding" },
  { target: 1, source: 15, Type: "Feeding" },
  { target: 9, source: 15, Type: "Feeding" },
  { target: 11, source: 15, Type: "Feeding" },
  { target: 3, source: 0, Type: "Feeding" },
  { target: 11, source: 0, Type: "Feeding" },
  { target: 13, source: 0, Type: "Feeding" },
  { target: 15, source: 0, Type: "Feeding" },
  { target: 11, source: 9, Type: "Feeding" },
  { target: 1, source: 2, Type: "ES" },
  { target: 1, source: 4, Type: "ES" },
  { target: 1, source: 8, Type: "ES" },
  { target: 5, source: 8, Type: "ES" },
  { target: 1, source: 6, Type: "ES" },
  { target: 3, source: 6, Type: "ES" },
  { target: 5, source: 6, Type: "ES" },
  { target: 9, source: 6, Type: "ES" },
  { target: 7, source: 6, Type: "ES" },
  { target: 11, source: 6, Type: "ES" },
  { target: 13, source: 6, Type: "ES" },
  { target: 0, source: 6, Type: "ES" },
  { target: 13, source: 16, Type: "ES" },
  { target: 13, source: 10, Type: "ES" },
  { target: 5, source: 16, Type: "ES" },
  { target: 15, source: 14, Type: "ES" },
  { target: 15, source: 12, Type: "ES" },
];
export const nodeList2 = [
  {
    index: 0,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 6.053132013,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 1,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 6.1750051,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 2,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 5.693753825,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 3,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 6.1750051,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 4,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 6.1750051,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 5,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 6.1750051,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 6,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 5.693753825,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 7,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 5.728823482,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 8,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 6.1750051,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 9,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 6.011465346,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 10,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 5.401044854,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 11,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 5.209172277,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 12,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 6.129049882,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 13,
    speciesID: 300,
    biomass: 47981.15827,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Plants",
    trophicLevel: 1.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 14,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organisms",
    trophicLevel: 4.727781178,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 15,
    speciesID: 500,
    biomass: 1788.910924,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Molluscs",
    trophicLevel: 3.863890589,
    desc: "Molluscs are invertebrate animals, meaning they do not have backbones. There are more than 85,000 species of molluscs today. Mollsucs are shell-forming animals and are vulnerable to ocean acidifcation and pollution.",
    imgFile: "mussels.jpg",
    imgCaption: "Many mussels underwater.",
  },
  {
    index: 16,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 6.445851185,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 17,
    speciesID: 650,
    biomass: 47789.69028,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Water Filtration",
    trophicLevel: 3.431945295,
    desc: "Water filtration is the process of removing and reducing the number of particles suspended in water. This helps improve water clarity by reducing sediment loads.",
    imgFile: "filtration.jpg",
    imgCaption: "Mussels filtering water.",
  },
  {
    index: 18,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Plankton",
    trophicLevel: 4.004169217,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 19,
    speciesID: 800,
    biomass: 191.3924808,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Crustaceans",
    trophicLevel: 5.008338434,
    desc: 'Crustaceans are invertebrate animals, meaning they do not have backbones. There are many different types of crustaceans, but they are generally categorixed by a hard exoskeleton, a segmented body and at least 4 pairs of jointed appendages ("legs" and "claws"). Crustaceans are vulnerable to over fishing, plastic pollution, habitat loss and ocean acidification. Ocean acidification occurs when there are changes in the chemical make up (specifically decreases in the pH due to increased carbon dioxide) of oceans, which can make it difficult for shell-builiding organisms to build their shells.',
    imgFile: "crab.jpg",
    imgCaption: "Crab standing in sand.",
  },
  {
    index: 20,
    speciesID: 900,
    biomass: 19.1147404,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Fish",
    trophicLevel: 5.1750051,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
  {
    index: 21,
    speciesID: 1000,
    biomass: 2.460564575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Other Birds",
    trophicLevel: 5.605074814,
    desc: "Birds are vertebrates charactereized by feathers, a beak, and wings. There are more than 9,000 species of birds with a wide array of fascinating behaviors. Like fish, they vary in their vulnerability but are generally impacted by pollution and habitat loss.",
    imgFile: "birds.jpg",
    imgCaption: "Flock of birds flying over water at sunset.",
  },
];

export const edgeList2 = [
  { target: 13, source: 14, Type: "Feeding" },
  { target: 20, source: 14, Type: "Feeding" },
  { target: 19, source: 14, Type: "Feeding" },
  { target: 13, source: 15, Type: "Feeding" },
  { target: 14, source: 15, Type: "Feeding" },
  { target: 0, source: 16, Type: "Feeding" },
  { target: 21, source: 16, Type: "Feeding" },
  { target: 1, source: 16, Type: "Feeding" },
  { target: 2, source: 16, Type: "Feeding" },
  { target: 3, source: 16, Type: "Feeding" },
  { target: 4, source: 16, Type: "Feeding" },
  { target: 5, source: 16, Type: "Feeding" },
  { target: 6, source: 16, Type: "Feeding" },
  { target: 7, source: 16, Type: "Feeding" },
  { target: 8, source: 16, Type: "Feeding" },
  { target: 9, source: 16, Type: "Feeding" },
  { target: 10, source: 16, Type: "Feeding" },
  { target: 11, source: 16, Type: "Feeding" },
  { target: 12, source: 16, Type: "Feeding" },
  { target: 20, source: 16, Type: "Feeding" },
  { target: 13, source: 16, Type: "Feeding" },
  { target: 13, source: 18, Type: "Feeding" },
  { target: 18, source: 16, Type: "Feeding" },
  { target: 13, source: 19, Type: "Feeding" },
  { target: 14, source: 19, Type: "Feeding" },
  { target: 15, source: 19, Type: "Feeding" },
  { target: 18, source: 19, Type: "Feeding" },
  { target: 16, source: 19, Type: "Feeding" },
  { target: 14, source: 20, Type: "Feeding" },
  { target: 18, source: 20, Type: "Feeding" },
  { target: 19, source: 20, Type: "Feeding" },
  { target: 13, source: 20, Type: "Feeding" },
  { target: 15, source: 20, Type: "Feeding" },
  { target: 16, source: 20, Type: "Feeding" },
  { target: 14, source: 0, Type: "Feeding" },
  { target: 15, source: 0, Type: "Feeding" },
  { target: 16, source: 0, Type: "Feeding" },
  { target: 20, source: 0, Type: "Feeding" },
  { target: 13, source: 21, Type: "Feeding" },
  { target: 15, source: 21, Type: "Feeding" },
  { target: 18, source: 21, Type: "Feeding" },
  { target: 14, source: 21, Type: "Feeding" },
  { target: 19, source: 21, Type: "Feeding" },
  { target: 20, source: 21, Type: "Feeding" },
  { target: 16, source: 21, Type: "Feeding" },
  { target: 20, source: 1, Type: "Feeding" },
  { target: 14, source: 2, Type: "Feeding" },
  { target: 15, source: 2, Type: "Feeding" },
  { target: 19, source: 2, Type: "Feeding" },
  { target: 20, source: 2, Type: "Feeding" },
  { target: 9, source: 21, Type: "Feeding" },
  { target: 11, source: 21, Type: "Feeding" },
  { target: 20, source: 3, Type: "Feeding" },
  { target: 20, source: 4, Type: "Feeding" },
  { target: 20, source: 5, Type: "Feeding" },
  { target: 14, source: 6, Type: "Feeding" },
  { target: 15, source: 6, Type: "Feeding" },
  { target: 19, source: 6, Type: "Feeding" },
  { target: 20, source: 6, Type: "Feeding" },
  { target: 14, source: 7, Type: "Feeding" },
  { target: 18, source: 7, Type: "Feeding" },
  { target: 19, source: 7, Type: "Feeding" },
  { target: 20, source: 7, Type: "Feeding" },
  { target: 20, source: 8, Type: "Feeding" },
  { target: 14, source: 9, Type: "Feeding" },
  { target: 15, source: 9, Type: "Feeding" },
  { target: 16, source: 9, Type: "Feeding" },
  { target: 19, source: 9, Type: "Feeding" },
  { target: 14, source: 10, Type: "Feeding" },
  { target: 15, source: 10, Type: "Feeding" },
  { target: 18, source: 10, Type: "Feeding" },
  { target: 19, source: 10, Type: "Feeding" },
  { target: 13, source: 11, Type: "Feeding" },
  { target: 14, source: 11, Type: "Feeding" },
  { target: 15, source: 11, Type: "Feeding" },
  { target: 16, source: 11, Type: "Feeding" },
  { target: 19, source: 11, Type: "Feeding" },
  { target: 14, source: 12, Type: "Feeding" },
  { target: 19, source: 12, Type: "Feeding" },
  { target: 20, source: 12, Type: "Feeding" },
  { target: 21, source: 12, Type: "Feeding" },
  { target: 19, source: 18, Type: "Feeding" },
  { target: 13, source: 17, Type: "ES" },
  { target: 15, source: 17, Type: "ES" },
];

export const nodeList3 = [
  {
    index: 0,
    speciesID: 118,
    biomass: 4.5752376,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Topsmelt",
    trophicLevel: 4.430684945,
    desc: "Topsmelt is a small fish species found in bays and estuaries, among other coastal ecosystems. This species is targeted by commercial fisheries but is considered to have a stable population size.",
    imgFile: "topsmelt.jpg",
    imgCaption: "Topsmelt Silverside on towel.",
  },
  {
    index: 1,
    speciesID: 120,
    biomass: 0.0449312,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Shiner Surfperch",
    trophicLevel: 5.410529209,
    desc: "Shiner surfperch are non-migratory fish that live in marine and brackish (slightly salty, occuring where salt and fresh water meet) waters. There are small commercial fisheries that target this species, and it is occasionally used as bait by recreational fishers. This species is vulnerable to habitat loss.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch swimming.",
  },
  {
    index: 2,
    speciesID: 125,
    biomass: 0.2618094,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Diamond Turbot",
    trophicLevel: 5.757226194,
    desc: "Diamond Turbot is a type of flatfish that occurs in coastal and brackish waters, living on muddy and sandy bottoms. They are not commercially targeted, but some recreational fishers may target this specices. It is not considered to be vulnerable. ",
    imgFile: "turbot.jpg",
    imgCaption:
      "Windowpane flounder laying on table. Similar species to Diamond turbot.",
  },
  {
    index: 3,
    speciesID: 132,
    biomass: 0.1536856,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Halibut",
    trophicLevel: 6.066483666,
    desc: "California Halibut is a large species of flatfish that lives on the Pacific Coast of the United States. This species is targeted by both commercial and recreational fisheries. It is considered to be vulnerable to the loss of critical nursery habitat.",
    imgFile: "halibut.jpg",
    imgCaption:
      "Three men standing next two many of the California Halibut that they caught.",
  },
  {
    index: 4,
    speciesID: 139,
    biomass: 0.0863925,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Leopard Shark",
    trophicLevel: 5.977527996,
    desc: "Leopard sharks are a small species of shark that lives in shallow waters in the Pacific Coast of the United States. It is targeted by both commercial and recreational fisheries, and is susceptible to overfishing.",
    imgFile: "leo-shark.jpg",
    imgCaption: "Leopard shark in sand.",
  },
  {
    index: 5,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 6.133489506,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 6,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 6.071970314,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 7,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 5.542780104,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 8,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 6.40792416,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 9,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 6.40792416,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 10,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 6.071970314,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 11,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 5.542780104,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 12,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 6.112008826,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 13,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 6.40792416,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 14,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 6.048663155,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 15,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 5.439532791,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 16,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 5.238930524,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 17,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 6.039274433,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 18,
    speciesID: 300,
    biomass: 47981.15827,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Plants",
    trophicLevel: 1.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 19,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organism",
    trophicLevel: 4.803362725,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 20,
    speciesID: 500,
    biomass: 1788.910924,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Molluscs",
    trophicLevel: 3.901681362,
    desc: "Molluscs are invertebrate animals, meaning they do not have backbones. There are more than 85,000 species of molluscs today. Mollsucs are shell-forming animals and are vulnerable to ocean acidifcation and pollution.",
    imgFile: "mussels.jpg",
    imgCaption: "Many mussels underwater.",
  },
  {
    index: 21,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 6.454217148,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 22,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Plankton",
    trophicLevel: 4.017695693,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 23,
    speciesID: 800,
    biomass: 191.3924808,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Crustaceans",
    trophicLevel: 5.035391385,
    desc: 'Crustaceans are invertebrate animals, meaning they do not have backbones. There are many different types of crustaceans, but they are generally categorixed by a hard exoskeleton, a segmented body and at least 4 pairs of jointed appendages ("legs" and "claws"). Crustaceans are vulnerable to over fishing, plastic pollution, habitat loss and ocean acidification. Ocean acidification occurs when there are changes in the chemical make up (specifically decreases in the pH due to increased carbon dioxide) of oceans, which can make it difficult for shell-builiding organisms to build their shells.',
    imgFile: "crab.jpg",
    imgCaption: "Crab standing in sand.",
  },
  {
    index: 24,
    speciesID: 900,
    biomass: 13.9926841,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Other fish",
    trophicLevel: 5.374696789,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
  {
    index: 25,
    speciesID: 1000,
    biomass: 2.460564575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Other birds",
    trophicLevel: 5.887658676,
    desc: "Birds are vertebrates charactereized by feathers, a beak, and wings. There are more than 9,000 species of birds with a wide array of fascinating behaviors. Like fish, they vary in their vulnerability but are generally impacted by pollution and habitat loss.",
    imgFile: "birds.jpg",
    imgCaption: "Flock of birds flying over water at sunset.",
  },
  {
    index: 26,
    speciesID: 1050,
    biomass: 584.6903931,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Recreational Fishery",
    trophicLevel: 6.274118594,
    desc: "Recreational fishing involves fishing for fun. This type of fishing can take place on small boats, on the shoreline, or on piers in salt marshes.",
    imgFile: "rec-fish.jpg",
    imgCaption: "Fishing rod on pier over water.",
  },
];

export const edgeList3 = [
  { target: 18, source: 19, Type: "Feeding" },
  { target: 24, source: 19, Type: "Feeding" },
  { target: 23, source: 19, Type: "Feeding" },
  { target: 18, source: 20, Type: "Feeding" },
  { target: 19, source: 20, Type: "Feeding" },
  { target: 5, source: 21, Type: "Feeding" },
  { target: 25, source: 21, Type: "Feeding" },
  { target: 6, source: 21, Type: "Feeding" },
  { target: 7, source: 21, Type: "Feeding" },
  { target: 8, source: 21, Type: "Feeding" },
  { target: 9, source: 21, Type: "Feeding" },
  { target: 10, source: 21, Type: "Feeding" },
  { target: 11, source: 21, Type: "Feeding" },
  { target: 12, source: 21, Type: "Feeding" },
  { target: 13, source: 21, Type: "Feeding" },
  { target: 14, source: 21, Type: "Feeding" },
  { target: 15, source: 21, Type: "Feeding" },
  { target: 16, source: 21, Type: "Feeding" },
  { target: 17, source: 21, Type: "Feeding" },
  { target: 0, source: 21, Type: "Feeding" },
  { target: 24, source: 21, Type: "Feeding" },
  { target: 18, source: 21, Type: "Feeding" },
  { target: 18, source: 22, Type: "Feeding" },
  { target: 22, source: 21, Type: "Feeding" },
  { target: 18, source: 23, Type: "Feeding" },
  { target: 19, source: 23, Type: "Feeding" },
  { target: 20, source: 23, Type: "Feeding" },
  { target: 22, source: 23, Type: "Feeding" },
  { target: 21, source: 23, Type: "Feeding" },
  { target: 19, source: 24, Type: "Feeding" },
  { target: 22, source: 24, Type: "Feeding" },
  { target: 23, source: 24, Type: "Feeding" },
  { target: 18, source: 0, Type: "Feeding" },
  { target: 19, source: 0, Type: "Feeding" },
  { target: 20, source: 0, Type: "Feeding" },
  { target: 22, source: 0, Type: "Feeding" },
  { target: 20, source: 24, Type: "Feeding" },
  { target: 21, source: 24, Type: "Feeding" },
  { target: 19, source: 1, Type: "Feeding" },
  { target: 22, source: 1, Type: "Feeding" },
  { target: 18, source: 24, Type: "Feeding" },
  { target: 1, source: 24, Type: "Feeding" },
  { target: 19, source: 2, Type: "Feeding" },
  { target: 20, source: 2, Type: "Feeding" },
  { target: 22, source: 2, Type: "Feeding" },
  { target: 23, source: 2, Type: "Feeding" },
  { target: 24, source: 2, Type: "Feeding" },
  { target: 1, source: 2, Type: "Feeding" },
  { target: 19, source: 3, Type: "Feeding" },
  { target: 22, source: 3, Type: "Feeding" },
  { target: 23, source: 3, Type: "Feeding" },
  { target: 24, source: 3, Type: "Feeding" },
  { target: 1, source: 3, Type: "Feeding" },
  { target: 2, source: 3, Type: "Feeding" },
  { target: 19, source: 4, Type: "Feeding" },
  { target: 20, source: 4, Type: "Feeding" },
  { target: 23, source: 4, Type: "Feeding" },
  { target: 0, source: 4, Type: "Feeding" },
  { target: 24, source: 4, Type: "Feeding" },
  { target: 1, source: 4, Type: "Feeding" },
  { target: 2, source: 4, Type: "Feeding" },
  { target: 3, source: 4, Type: "Feeding" },
  { target: 22, source: 4, Type: "Feeding" },
  { target: 19, source: 5, Type: "Feeding" },
  { target: 20, source: 5, Type: "Feeding" },
  { target: 21, source: 5, Type: "Feeding" },
  { target: 24, source: 5, Type: "Feeding" },
  { target: 18, source: 25, Type: "Feeding" },
  { target: 20, source: 25, Type: "Feeding" },
  { target: 22, source: 25, Type: "Feeding" },
  { target: 19, source: 25, Type: "Feeding" },
  { target: 23, source: 25, Type: "Feeding" },
  { target: 0, source: 25, Type: "Feeding" },
  { target: 21, source: 25, Type: "Feeding" },
  { target: 24, source: 25, Type: "Feeding" },
  { target: 1, source: 25, Type: "Feeding" },
  { target: 2, source: 25, Type: "Feeding" },
  { target: 3, source: 25, Type: "Feeding" },
  { target: 0, source: 6, Type: "Feeding" },
  { target: 1, source: 6, Type: "Feeding" },
  { target: 24, source: 6, Type: "Feeding" },
  { target: 19, source: 7, Type: "Feeding" },
  { target: 20, source: 7, Type: "Feeding" },
  { target: 23, source: 7, Type: "Feeding" },
  { target: 0, source: 7, Type: "Feeding" },
  { target: 14, source: 25, Type: "Feeding" },
  { target: 16, source: 25, Type: "Feeding" },
  { target: 24, source: 8, Type: "Feeding" },
  { target: 0, source: 8, Type: "Feeding" },
  { target: 1, source: 8, Type: "Feeding" },
  { target: 2, source: 8, Type: "Feeding" },
  { target: 3, source: 8, Type: "Feeding" },
  { target: 24, source: 9, Type: "Feeding" },
  { target: 0, source: 9, Type: "Feeding" },
  { target: 1, source: 9, Type: "Feeding" },
  { target: 2, source: 9, Type: "Feeding" },
  { target: 3, source: 9, Type: "Feeding" },
  { target: 0, source: 10, Type: "Feeding" },
  { target: 24, source: 10, Type: "Feeding" },
  { target: 1, source: 10, Type: "Feeding" },
  { target: 19, source: 11, Type: "Feeding" },
  { target: 20, source: 11, Type: "Feeding" },
  { target: 23, source: 11, Type: "Feeding" },
  { target: 0, source: 11, Type: "Feeding" },
  { target: 19, source: 12, Type: "Feeding" },
  { target: 22, source: 12, Type: "Feeding" },
  { target: 23, source: 12, Type: "Feeding" },
  { target: 24, source: 12, Type: "Feeding" },
  { target: 0, source: 12, Type: "Feeding" },
  { target: 1, source: 12, Type: "Feeding" },
  { target: 2, source: 12, Type: "Feeding" },
  { target: 3, source: 12, Type: "Feeding" },
  { target: 24, source: 13, Type: "Feeding" },
  { target: 0, source: 13, Type: "Feeding" },
  { target: 1, source: 13, Type: "Feeding" },
  { target: 2, source: 13, Type: "Feeding" },
  { target: 3, source: 13, Type: "Feeding" },
  { target: 19, source: 14, Type: "Feeding" },
  { target: 20, source: 14, Type: "Feeding" },
  { target: 21, source: 14, Type: "Feeding" },
  { target: 23, source: 14, Type: "Feeding" },
  { target: 19, source: 15, Type: "Feeding" },
  { target: 20, source: 15, Type: "Feeding" },
  { target: 22, source: 15, Type: "Feeding" },
  { target: 23, source: 15, Type: "Feeding" },
  { target: 18, source: 16, Type: "Feeding" },
  { target: 19, source: 16, Type: "Feeding" },
  { target: 20, source: 16, Type: "Feeding" },
  { target: 21, source: 16, Type: "Feeding" },
  { target: 23, source: 16, Type: "Feeding" },
  { target: 19, source: 17, Type: "Feeding" },
  { target: 23, source: 17, Type: "Feeding" },
  { target: 0, source: 17, Type: "Feeding" },
  { target: 25, source: 17, Type: "Feeding" },
  { target: 23, source: 22, Type: "Feeding" },
  { target: 0, source: 26, Type: "ES" },
  { target: 2, source: 26, Type: "ES" },
  { target: 24, source: 26, Type: "ES" },
  { target: 4, source: 26, Type: "ES" },
  { target: 20, source: 26, Type: "ES" },
  { target: 1, source: 26, Type: "ES" },
  { target: 3, source: 26, Type: "ES" },
];
export const nodeList4 = [
  {
    index: 0,
    speciesID: 118,
    biomass: 4.5752376,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Topsmelt",
    trophicLevel: 4.435691704,
    desc: "Topsmelt is a small fish species found in bays and estuaries, among other coastal ecosystems. This species is targeted by commercial fisheries but is considered to have a stable population size.",
    imgFile: "topsmelt.jpg",
    imgCaption: "Topsmelt Silverside on towel.",
  },
  {
    index: 1,
    speciesID: 120,
    biomass: 0.0449312,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Shiner Surfperch",
    trophicLevel: 5.418340584,
    desc: "Shiner surfperch are non-migratory fish that live in marine and brackish (slightly salty, occuring where salt and fresh water meet) waters. There are small commercial fisheries that target this species, and it is occasionally used as bait by recreational fishers. This species is vulnerable to habitat loss.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch swimming.",
  },
  {
    index: 2,
    speciesID: 125,
    biomass: 0.2618094,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Diamond Turbot",
    trophicLevel: 5.766270215,
    desc: "Diamond Turbot is a type of flatfish that occurs in coastal and brackish waters, living on muddy and sandy bottoms. They are not commercially targeted, but some recreational fishers may target this specices. It is not considered to be vulnerable. ",
    imgFile: "turbot.jpg",
    imgCaption:
      "Windowpane flounder laying on table. Similar species to Diamond turbot.",
  },
  {
    index: 3,
    speciesID: 132,
    biomass: 0.1536856,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Halibut",
    trophicLevel: 6.076300976,
    desc: "California Halibut is a large species of flatfish that lives on the Pacific Coast of the United States. This species is targeted by both commercial and recreational fisheries. It is considered to be vulnerable to the loss of critical nursery habitat.",
    imgFile: "halibut.jpg",
    imgCaption:
      "Three men standing next two many of the California Halibut that they caught.",
  },
  {
    index: 4,
    speciesID: 139,
    biomass: 0.0863925,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Leopard Shark",
    trophicLevel: 5.986209354,
    desc: "Leopard sharks are a small species of shark that lives in shallow waters in the Pacific Coast of the United States. It is targeted by both commercial and recreational fisheries, and is susceptible to overfishing.",
    imgFile: "leo-shark.jpg",
    imgCaption: "Leopard shark in sand.",
  },
  {
    index: 5,
    speciesID: 140,
    biomass: 0.0284862,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Haller's Round Ray",
    trophicLevel: 5.635856141,
    desc: "Haller's Round Ray is a species of stingray that lives in shallow coastal waters along the coast of California. Its range extends south to Ecuador. There a some fisheries that target this species but it is generally not considered vulnerable. ",
    imgFile: "Urobatis.jpg",
    imgCaption:
      "Yellow stingray, a species of stingray close to Haller's Round Ray.",
  },
  {
    index: 6,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 6.152020747,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 7,
    speciesID: 143,
    biomass: 0.0922059,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Coot",
    trophicLevel: 3.976865173,
    desc: "The American Coot is considered to be an adaptable waterbird with a range covering most of the United States, extending into Canada and Mexico. It has a low vulnerability status.",
    imgFile: "amer-coot.jpg",
    imgCaption: "American Coot getting ready to take flight from water.",
  },
  {
    index: 8,
    speciesID: 144,
    biomass: 0.0013822,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Crow",
    trophicLevel: 5.550742098,
    desc: "The American Crow is considered one of the most intelligent birds and is widespread across the United States. This species has a low vulnerability status.",
    imgFile: "crow.jpg",
    imgCaption: "American Crow standing on a branch.",
  },
  {
    index: 9,
    speciesID: 145,
    biomass: 0.0674677,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Wigeon",
    trophicLevel: 4.858231854,
    desc: "The American Wigeon is a species of duck. It is considered to be moderately vulnerable to climate change.",
    imgFile: "amer-wigeon.jpg",
    imgCaption: "American Wigeon sitting in water.",
  },
  {
    index: 10,
    speciesID: 146,
    biomass: 0.0063977,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-bellied Plover",
    trophicLevel: 5.946935434,
    desc: "The Black-bellied plover spends winter on the Pacific coast of the United States. In the summers, it lives in Northern Canada. This species is highly vulnerable to climate change. ",
    imgFile: "bb-plover.jpg",
    imgCaption: "Black-bellied Plover standing in field.",
  },
  {
    index: 11,
    speciesID: 147,
    biomass: 0.0300871,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-crowned Night Heron",
    trophicLevel: 6.277898381,
    desc: "The Black-crowned Night Heron can be found across the United States and is vulnerable to water pollution and habitat loss.",
    imgFile: "night-heron.jpg",
    imgCaption: "Black-crowned Night Heron standing on rock.",
  },
  {
    index: 12,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 6.080508812,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 13,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 5.550742098,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 14,
    speciesID: 154,
    biomass: 0.0915692,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bufflehead",
    trophicLevel: 5.44794664,
    desc: "The Bufflehead is a smaller duck species that occurs in North America. It is generally considered to have a stable population, but is vulnerable to habitat loss and highly vulnerable to climate change.",
    imgFile: "bufflehead.jpg",
    imgCaption: "Two Buffleheads sitting in water.",
  },
  {
    index: 15,
    speciesID: 155,
    biomass: 0.0516353,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Blue-winged Teal",
    trophicLevel: 4.858231854,
    desc: "The Blue-winged Teal is a small species of duck that spends its summers in Canada and the United States, migrating south towards South America in the winter. It is considered to have a low vulnerability status. ",
    imgFile: "bw-teal.jpg",
    imgCaption: "Blue-winged Teal sitting near water.",
  },
  {
    index: 16,
    speciesID: 157,
    biomass: 0.249866575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "California Gull",
    trophicLevel: 5.718092508,
    desc: "The California Gull is a gull species that occurs mainly on the Pacific coast of North America. It is moderately vulnerable to climate change.",
    imgFile: "ca-gull.jpg",
    imgCaption: "California Gull standing on a rock near water.",
  },
  {
    index: 17,
    speciesID: 158,
    biomass: 0.0036287,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Caspian Tern",
    trophicLevel: 6.080508812,
    desc: "The Caspian Tern is the largest of all tern species. These birds have a low vulnerability to climate change.",
    imgFile: "c-tern.jpg",
    imgCaption: "Two Caspian Terns standing in sand.",
  },
  {
    index: 18,
    speciesID: 159,
    biomass: 0.0149052,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cinnamon Teal",
    trophicLevel: 4.858231854,
    desc: "The Cinnamon Teal is a common species of duck that can be found only in the Western half of the United States, in addition to their range in Mexico. It is moderately vulnerable to climate change. ",
    imgFile: "cinn-teal.jpg",
    imgCaption: "Cinnamon Teal swimming in water.",
  },
  {
    index: 19,
    speciesID: 161,
    biomass: 0.002242,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cooper's Hawk",
    trophicLevel: 6.75988327,
    desc: "The Cooper's Hawk is a widespread species that is not considered to be vulnerable to climate change.",
    imgFile: "c-hawk.jpg",
    imgCaption: "Cooper's Hawk sitting on a tree branch.",
  },
  {
    index: 20,
    speciesID: 163,
    biomass: 0.0124805,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Double-crested Cormorant",
    trophicLevel: 6.416819526,
    desc: "The Double-crested Cormorant is a species of diving birds that is not considered to be vulnerable to climate change. However, it may be impacted by pollution.",
    imgFile: "cormorant.jpg",
    imgCaption: "Double-crested Cormorant sitting in water.",
  },
  {
    index: 21,
    speciesID: 164,
    biomass: 0.0090074,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Dunlin",
    trophicLevel: 5.70972031,
    desc: "The Dunlin is a migrating species that spends its winters on the Pacific coast of the United States. This species has seen considerable declines in their wintering seasons and is highly vulnerable to climate change.",
    imgFile: "dunlin.jpg",
    imgCaption: "Dunlin standing in water.",
  },
  {
    index: 22,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 6.416819526,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 23,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 6.416819526,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 24,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 6.080508812,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 25,
    speciesID: 172,
    biomass: 0.0396191,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Greater Yellowlegs",
    trophicLevel: 6.152020747,
    desc: "Greater Yellowlegs are a migrating, wading bird species. It is highly vulnerable to climate change, and there is not consensus on the stability of its population.",
    imgFile: "g-yellow.jpg",
    imgCaption: "Greater Yellowlegs standing in shallow water.",
  },
  {
    index: 26,
    speciesID: 173,
    biomass: 0.1386956,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green-winged Teal",
    trophicLevel: 4.858231854,
    desc: "This small duck species is common and widespread. The Green-winged Teal is considered to have low vulnerability to climate change.",
    imgFile: "gw-teal.jpg",
    imgCaption: "Green-winged Teal swimming in water.",
  },
  {
    index: 27,
    speciesID: 174,
    biomass: 0.0062451,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Killdeer",
    trophicLevel: 6.263420883,
    desc: "The Killdeer is a common species of bird, and is not considered to be vulnerable to climate change. ",
    imgFile: "killdeer.jpg",
    imgCaption: "Killdeer sitting in gravel.",
  },
  {
    index: 28,
    speciesID: 175,
    biomass: 0.0835354,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Curlew",
    trophicLevel: 5.635856141,
    desc: "The Long-billed Curlew is one of the largest shorebirds, migrating across the Western United States and Mexico. It is vulnerable to habitat loss, as much of their breeding habitat has been converted to agriculture. They are also highly vulnerable to climate change.",
    imgFile: "lb-curlew.jpg",
    imgCaption: "Long-billed Curlew taking flight from water.",
  },
  {
    index: 29,
    speciesID: 176,
    biomass: 0.0553906,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Dowitcher",
    trophicLevel: 5.946935434,
    desc: "The Long-billed Dowitcher is a similar species to the Long-billed Curlew. While they overlap in range, the Long-billed Dowitcher prefers fresh water to salt water. This species is highly vulnerable to climate change.",
    imgFile: "lb-dowitcher.jpg",
    imgCaption: "Long-billed Dowitcher standing in shallow water.",
  },
  {
    index: 30,
    speciesID: 177,
    biomass: 0.0035291,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Little Blue Heron",
    trophicLevel: 6.43095257,
    desc: "The Little Blue Heron is a migrating bird that is not considered to be vulnerable to climate change.",
    imgFile: "lb-heron.jpg",
    imgCaption: "Little Blue Heron standing in marsh water.",
  },
  {
    index: 31,
    speciesID: 178,
    biomass: 0.0356641,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Least Sandpiper",
    trophicLevel: 6.008488907,
    desc: 'The Least Sandpiper is a migrating species. This species relies less on "stopover" sites than other migrating birds, and is considered to be moderately vulnerable to climate change.',
    imgFile: "least-sand.jpg",
    imgCaption: "Least Sandpiper standing at water's edge.",
  },
  {
    index: 32,
    speciesID: 181,
    biomass: 0.1057894,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Marbled Godwit",
    trophicLevel: 5.635856141,
    desc: "The Marbled Godwit is a migrating bird that is declining due to habitat loss in their summer habitat. They are moderately vulnerable to climate change.",
    imgFile: "m-godwit.jpg",
    imgCaption: "Marbled Godwit standing in waves.",
  },
  {
    index: 33,
    speciesID: 182,
    biomass: 0.4704502,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mallard",
    trophicLevel: 4.858231854,
    desc: "Mallards are one of the most abundant ducks in the world. They migrate across North America and are considered to have a low vulnerability to climate change.",
    imgFile: "mallard.jpg",
    imgCaption: "Three female and two male Mallards standing in shallow water.",
  },
  {
    index: 34,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 5.550742098,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 35,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 6.121224817,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 36,
    speciesID: 189,
    biomass: 0.0064582,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ring-billed Gull",
    trophicLevel: 5.550742098,
    desc: "The Ring-billed Gull is one of the most common gulls in North America, and has benefited greatly from the aavailability of food provided by humans. It is considered to have a low vulnerability status to climate change. ",
    imgFile: "rb-gull.jpg",
    imgCaption: "Ring-billed Gull standing in shallow water.",
  },
  {
    index: 37,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 6.416819526,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 38,
    speciesID: 194,
    biomass: 0.0019375,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ruddy Duck",
    trophicLevel: 5.049019742,
    desc: "Ruddy Ducks are a species of stiff-tailed ducks in North America. It is vulnerable to habitat loss, and mildly vulnerable to climate change.",
    imgFile: "ruddy.jpg",
    imgCaption: "Ruddy Dyck taking flight from water. ",
  },
  {
    index: 39,
    speciesID: 197,
    biomass: 0.0070175,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Semipalmated Plover",
    trophicLevel: 6.073529614,
    desc: "While the Semipalmated is widespread and common in coastal ecosystems across the United States, this species is considered to be highly vulnerable to climate change.",
    imgFile: "semi-plover.jpg",
    imgCaption: "Juvenile Semipalmated Plover walking in sand. ",
  },
  {
    index: 40,
    speciesID: 198,
    biomass: 0.0758358,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Snowy Egret",
    trophicLevel: 6.43095257,
    desc: "The Snowy Egret is a small species of egret that is found mostly in the Southern United States and Mexico. It is continuously recovering from historic population decreases and is not considered to be vulnerable to climate change.",
    imgFile: "snow-egret.jpg",
    imgCaption: "Snowy Egret standing on rocks near rushing water.",
  },
  {
    index: 41,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 6.067402146,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 42,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 5.44794664,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 43,
    speciesID: 204,
    biomass: 0.0470025,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Turkey Vulture",
    trophicLevel: 1.0,
    desc: "Turkey Vultures are widespread across North America and are not considered to be vulnerable to climate change.",
    imgFile: "t-vulture.jpg",
    imgCaption: "Turkey Vulture flying.",
  },
  {
    index: 44,
    speciesID: 205,
    biomass: 0.0218222,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Grebe",
    trophicLevel: 6.416819526,
    desc: "The Western Grebe is a species of aquatic birds that migrates between fresh water lakes and brackish coastal waters in North America. While this species used to be targeted for its feathers, it is currently only vulnerable to climate change.",
    imgFile: "w-grebe.jpg",
    imgCaption: "Two Western Grebes swimming.",
  },
  {
    index: 45,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 5.253921717,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 46,
    speciesID: 208,
    biomass: 0.0192895,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Whimbrel",
    trophicLevel: 5.589092229,
    desc: "Whimbrels are a shorebird that occurs in North America. They are highly vulnerable to climate change, and are still recovering from historically depleted populations.",
    imgFile: "whimbrel.jpg",
    imgCaption: "Whimbrel standing in shallow water.",
  },
  {
    index: 47,
    speciesID: 209,
    biomass: 0.7094073,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Willet",
    trophicLevel: 5.946935434,
    desc: "Willets are large shorebirds that occur in prairie and salt marshes in North America. They are vulnerable to habitat disturbance and loss.",
    imgFile: "willet.jpg",
    imgCaption: "Willet flying over water.",
  },
  {
    index: 48,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 5.899233442,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 49,
    speciesID: 300,
    biomass: 47981.15827,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Plants",
    trophicLevel: 1.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 50,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organism",
    trophicLevel: 4.812171297,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 51,
    speciesID: 500,
    biomass: 1788.910924,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Molluscs",
    trophicLevel: 3.906085648,
    desc: "Molluscs are invertebrate animals, meaning they do not have backbones. There are more than 85,000 species of molluscs today. Mollsucs are shell-forming animals and are vulnerable to ocean acidifcation and pollution.",
    imgFile: "mussels.jpg",
    imgCaption: "Many mussels underwater.",
  },
  {
    index: 52,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 6.502331896,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 53,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Plankton",
    trophicLevel: 4.024509871,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 54,
    speciesID: 800,
    biomass: 191.3924808,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Crustaceans",
    trophicLevel: 5.049019742,
    desc: 'Crustaceans are invertebrate animals, meaning they do not have backbones. There are many different types of crustaceans, but they are generally categorixed by a hard exoskeleton, a segmented body and at least 4 pairs of jointed appendages ("legs" and "claws"). Crustaceans are vulnerable to over fishing, plastic pollution, habitat loss and ocean acidification. Ocean acidification occurs when there are changes in the chemical make up (specifically decreases in the pH due to increased carbon dioxide) of oceans, which can make it difficult for shell-builiding organisms to build their shells.',
    imgFile: "crab.jpg",
    imgCaption: "Crab standing in sand.",
  },
  {
    index: 55,
    speciesID: 900,
    biomass: 13.9641979,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Other fish",
    trophicLevel: 5.387494148,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
  {
    index: 56,
    speciesID: 950,
    biomass: 0.8204547,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Waterfowl Hunting",
    trophicLevel: 5.681958518,
    desc: "Waterfowl hunting is the activity of hunting ducks, geese or other water birds for fun and/or food.",
    imgFile: "wfowl-hunt.jpg",
    imgCaption: "Dog carrying waterfowl in grass.",
  },
];

export const edgeList4 = [
  { target: 49, source: 50, Type: "Feeding" },
  { target: 55, source: 50, Type: "Feeding" },
  { target: 54, source: 50, Type: "Feeding" },
  { target: 49, source: 51, Type: "Feeding" },
  { target: 50, source: 51, Type: "Feeding" },
  { target: 6, source: 52, Type: "Feeding" },
  { target: 7, source: 52, Type: "Feeding" },
  { target: 8, source: 52, Type: "Feeding" },
  { target: 9, source: 52, Type: "Feeding" },
  { target: 10, source: 52, Type: "Feeding" },
  { target: 11, source: 52, Type: "Feeding" },
  { target: 12, source: 52, Type: "Feeding" },
  { target: 13, source: 52, Type: "Feeding" },
  { target: 14, source: 52, Type: "Feeding" },
  { target: 15, source: 52, Type: "Feeding" },
  { target: 16, source: 52, Type: "Feeding" },
  { target: 17, source: 52, Type: "Feeding" },
  { target: 18, source: 52, Type: "Feeding" },
  { target: 19, source: 52, Type: "Feeding" },
  { target: 20, source: 52, Type: "Feeding" },
  { target: 21, source: 52, Type: "Feeding" },
  { target: 22, source: 52, Type: "Feeding" },
  { target: 23, source: 52, Type: "Feeding" },
  { target: 24, source: 52, Type: "Feeding" },
  { target: 25, source: 52, Type: "Feeding" },
  { target: 26, source: 52, Type: "Feeding" },
  { target: 27, source: 52, Type: "Feeding" },
  { target: 28, source: 52, Type: "Feeding" },
  { target: 29, source: 52, Type: "Feeding" },
  { target: 30, source: 52, Type: "Feeding" },
  { target: 31, source: 52, Type: "Feeding" },
  { target: 32, source: 52, Type: "Feeding" },
  { target: 33, source: 52, Type: "Feeding" },
  { target: 34, source: 52, Type: "Feeding" },
  { target: 35, source: 52, Type: "Feeding" },
  { target: 36, source: 52, Type: "Feeding" },
  { target: 37, source: 52, Type: "Feeding" },
  { target: 38, source: 52, Type: "Feeding" },
  { target: 39, source: 52, Type: "Feeding" },
  { target: 40, source: 52, Type: "Feeding" },
  { target: 41, source: 52, Type: "Feeding" },
  { target: 42, source: 52, Type: "Feeding" },
  { target: 43, source: 52, Type: "Feeding" },
  { target: 44, source: 52, Type: "Feeding" },
  { target: 45, source: 52, Type: "Feeding" },
  { target: 46, source: 52, Type: "Feeding" },
  { target: 47, source: 52, Type: "Feeding" },
  { target: 48, source: 52, Type: "Feeding" },
  { target: 0, source: 52, Type: "Feeding" },
  { target: 55, source: 52, Type: "Feeding" },
  { target: 49, source: 52, Type: "Feeding" },
  { target: 49, source: 53, Type: "Feeding" },
  { target: 53, source: 52, Type: "Feeding" },
  { target: 49, source: 54, Type: "Feeding" },
  { target: 50, source: 54, Type: "Feeding" },
  { target: 51, source: 54, Type: "Feeding" },
  { target: 53, source: 54, Type: "Feeding" },
  { target: 52, source: 54, Type: "Feeding" },
  { target: 50, source: 55, Type: "Feeding" },
  { target: 53, source: 55, Type: "Feeding" },
  { target: 54, source: 55, Type: "Feeding" },
  { target: 49, source: 0, Type: "Feeding" },
  { target: 50, source: 0, Type: "Feeding" },
  { target: 51, source: 0, Type: "Feeding" },
  { target: 53, source: 0, Type: "Feeding" },
  { target: 51, source: 55, Type: "Feeding" },
  { target: 52, source: 55, Type: "Feeding" },
  { target: 50, source: 1, Type: "Feeding" },
  { target: 53, source: 1, Type: "Feeding" },
  { target: 49, source: 55, Type: "Feeding" },
  { target: 1, source: 55, Type: "Feeding" },
  { target: 50, source: 2, Type: "Feeding" },
  { target: 51, source: 2, Type: "Feeding" },
  { target: 53, source: 2, Type: "Feeding" },
  { target: 54, source: 2, Type: "Feeding" },
  { target: 55, source: 2, Type: "Feeding" },
  { target: 1, source: 2, Type: "Feeding" },
  { target: 50, source: 3, Type: "Feeding" },
  { target: 53, source: 3, Type: "Feeding" },
  { target: 54, source: 3, Type: "Feeding" },
  { target: 55, source: 3, Type: "Feeding" },
  { target: 1, source: 3, Type: "Feeding" },
  { target: 2, source: 3, Type: "Feeding" },
  { target: 50, source: 4, Type: "Feeding" },
  { target: 51, source: 4, Type: "Feeding" },
  { target: 54, source: 4, Type: "Feeding" },
  { target: 0, source: 4, Type: "Feeding" },
  { target: 55, source: 4, Type: "Feeding" },
  { target: 1, source: 4, Type: "Feeding" },
  { target: 2, source: 4, Type: "Feeding" },
  { target: 3, source: 4, Type: "Feeding" },
  { target: 53, source: 4, Type: "Feeding" },
  { target: 50, source: 5, Type: "Feeding" },
  { target: 51, source: 5, Type: "Feeding" },
  { target: 54, source: 5, Type: "Feeding" },
  { target: 55, source: 5, Type: "Feeding" },
  { target: 53, source: 5, Type: "Feeding" },
  { target: 50, source: 6, Type: "Feeding" },
  { target: 51, source: 6, Type: "Feeding" },
  { target: 52, source: 6, Type: "Feeding" },
  { target: 55, source: 6, Type: "Feeding" },
  { target: 49, source: 7, Type: "Feeding" },
  { target: 51, source: 7, Type: "Feeding" },
  { target: 53, source: 7, Type: "Feeding" },
  { target: 50, source: 8, Type: "Feeding" },
  { target: 51, source: 8, Type: "Feeding" },
  { target: 54, source: 8, Type: "Feeding" },
  { target: 0, source: 8, Type: "Feeding" },
  { target: 49, source: 9, Type: "Feeding" },
  { target: 51, source: 9, Type: "Feeding" },
  { target: 53, source: 9, Type: "Feeding" },
  { target: 52, source: 9, Type: "Feeding" },
  { target: 50, source: 10, Type: "Feeding" },
  { target: 51, source: 10, Type: "Feeding" },
  { target: 52, source: 10, Type: "Feeding" },
  { target: 53, source: 10, Type: "Feeding" },
  { target: 54, source: 10, Type: "Feeding" },
  { target: 55, source: 10, Type: "Feeding" },
  { target: 50, source: 11, Type: "Feeding" },
  { target: 54, source: 11, Type: "Feeding" },
  { target: 55, source: 11, Type: "Feeding" },
  { target: 0, source: 11, Type: "Feeding" },
  { target: 1, source: 11, Type: "Feeding" },
  { target: 2, source: 11, Type: "Feeding" },
  { target: 3, source: 11, Type: "Feeding" },
  { target: 0, source: 12, Type: "Feeding" },
  { target: 1, source: 12, Type: "Feeding" },
  { target: 55, source: 12, Type: "Feeding" },
  { target: 50, source: 13, Type: "Feeding" },
  { target: 51, source: 13, Type: "Feeding" },
  { target: 54, source: 13, Type: "Feeding" },
  { target: 0, source: 13, Type: "Feeding" },
  { target: 50, source: 14, Type: "Feeding" },
  { target: 51, source: 14, Type: "Feeding" },
  { target: 53, source: 14, Type: "Feeding" },
  { target: 54, source: 14, Type: "Feeding" },
  { target: 49, source: 15, Type: "Feeding" },
  { target: 51, source: 15, Type: "Feeding" },
  { target: 53, source: 15, Type: "Feeding" },
  { target: 52, source: 15, Type: "Feeding" },
  { target: 50, source: 16, Type: "Feeding" },
  { target: 51, source: 16, Type: "Feeding" },
  { target: 54, source: 16, Type: "Feeding" },
  { target: 0, source: 16, Type: "Feeding" },
  { target: 55, source: 16, Type: "Feeding" },
  { target: 0, source: 17, Type: "Feeding" },
  { target: 55, source: 17, Type: "Feeding" },
  { target: 1, source: 17, Type: "Feeding" },
  { target: 49, source: 18, Type: "Feeding" },
  { target: 51, source: 18, Type: "Feeding" },
  { target: 53, source: 18, Type: "Feeding" },
  { target: 52, source: 18, Type: "Feeding" },
  { target: 21, source: 19, Type: "Feeding" },
  { target: 31, source: 19, Type: "Feeding" },
  { target: 41, source: 19, Type: "Feeding" },
  { target: 45, source: 19, Type: "Feeding" },
  { target: 55, source: 20, Type: "Feeding" },
  { target: 0, source: 20, Type: "Feeding" },
  { target: 1, source: 20, Type: "Feeding" },
  { target: 2, source: 20, Type: "Feeding" },
  { target: 3, source: 20, Type: "Feeding" },
  { target: 50, source: 21, Type: "Feeding" },
  { target: 51, source: 21, Type: "Feeding" },
  { target: 53, source: 21, Type: "Feeding" },
  { target: 1, source: 21, Type: "Feeding" },
  { target: 55, source: 21, Type: "Feeding" },
  { target: 55, source: 22, Type: "Feeding" },
  { target: 0, source: 22, Type: "Feeding" },
  { target: 1, source: 22, Type: "Feeding" },
  { target: 2, source: 22, Type: "Feeding" },
  { target: 3, source: 22, Type: "Feeding" },
  { target: 55, source: 23, Type: "Feeding" },
  { target: 0, source: 23, Type: "Feeding" },
  { target: 1, source: 23, Type: "Feeding" },
  { target: 2, source: 23, Type: "Feeding" },
  { target: 3, source: 23, Type: "Feeding" },
  { target: 0, source: 24, Type: "Feeding" },
  { target: 55, source: 24, Type: "Feeding" },
  { target: 1, source: 24, Type: "Feeding" },
  { target: 50, source: 25, Type: "Feeding" },
  { target: 51, source: 25, Type: "Feeding" },
  { target: 52, source: 25, Type: "Feeding" },
  { target: 55, source: 25, Type: "Feeding" },
  { target: 49, source: 26, Type: "Feeding" },
  { target: 51, source: 26, Type: "Feeding" },
  { target: 53, source: 26, Type: "Feeding" },
  { target: 52, source: 26, Type: "Feeding" },
  { target: 52, source: 27, Type: "Feeding" },
  { target: 53, source: 27, Type: "Feeding" },
  { target: 50, source: 28, Type: "Feeding" },
  { target: 51, source: 28, Type: "Feeding" },
  { target: 54, source: 28, Type: "Feeding" },
  { target: 55, source: 28, Type: "Feeding" },
  { target: 53, source: 28, Type: "Feeding" },
  { target: 50, source: 29, Type: "Feeding" },
  { target: 51, source: 29, Type: "Feeding" },
  { target: 53, source: 29, Type: "Feeding" },
  { target: 52, source: 29, Type: "Feeding" },
  { target: 54, source: 29, Type: "Feeding" },
  { target: 55, source: 29, Type: "Feeding" },
  { target: 50, source: 30, Type: "Feeding" },
  { target: 52, source: 30, Type: "Feeding" },
  { target: 54, source: 30, Type: "Feeding" },
  { target: 55, source: 30, Type: "Feeding" },
  { target: 0, source: 30, Type: "Feeding" },
  { target: 1, source: 30, Type: "Feeding" },
  { target: 2, source: 30, Type: "Feeding" },
  { target: 3, source: 30, Type: "Feeding" },
  { target: 50, source: 31, Type: "Feeding" },
  { target: 51, source: 31, Type: "Feeding" },
  { target: 52, source: 31, Type: "Feeding" },
  { target: 53, source: 31, Type: "Feeding" },
  { target: 1, source: 31, Type: "Feeding" },
  { target: 55, source: 31, Type: "Feeding" },
  { target: 50, source: 32, Type: "Feeding" },
  { target: 51, source: 32, Type: "Feeding" },
  { target: 53, source: 32, Type: "Feeding" },
  { target: 54, source: 32, Type: "Feeding" },
  { target: 55, source: 32, Type: "Feeding" },
  { target: 49, source: 33, Type: "Feeding" },
  { target: 51, source: 33, Type: "Feeding" },
  { target: 53, source: 33, Type: "Feeding" },
  { target: 52, source: 33, Type: "Feeding" },
  { target: 50, source: 34, Type: "Feeding" },
  { target: 51, source: 34, Type: "Feeding" },
  { target: 54, source: 34, Type: "Feeding" },
  { target: 0, source: 34, Type: "Feeding" },
  { target: 50, source: 35, Type: "Feeding" },
  { target: 53, source: 35, Type: "Feeding" },
  { target: 54, source: 35, Type: "Feeding" },
  { target: 55, source: 35, Type: "Feeding" },
  { target: 0, source: 35, Type: "Feeding" },
  { target: 1, source: 35, Type: "Feeding" },
  { target: 2, source: 35, Type: "Feeding" },
  { target: 3, source: 35, Type: "Feeding" },
  { target: 50, source: 36, Type: "Feeding" },
  { target: 51, source: 36, Type: "Feeding" },
  { target: 54, source: 36, Type: "Feeding" },
  { target: 0, source: 36, Type: "Feeding" },
  { target: 55, source: 37, Type: "Feeding" },
  { target: 0, source: 37, Type: "Feeding" },
  { target: 1, source: 37, Type: "Feeding" },
  { target: 2, source: 37, Type: "Feeding" },
  { target: 3, source: 37, Type: "Feeding" },
  { target: 49, source: 38, Type: "Feeding" },
  { target: 50, source: 38, Type: "Feeding" },
  { target: 51, source: 38, Type: "Feeding" },
  { target: 53, source: 38, Type: "Feeding" },
  { target: 52, source: 38, Type: "Feeding" },
  { target: 50, source: 39, Type: "Feeding" },
  { target: 51, source: 39, Type: "Feeding" },
  { target: 52, source: 39, Type: "Feeding" },
  { target: 50, source: 40, Type: "Feeding" },
  { target: 52, source: 40, Type: "Feeding" },
  { target: 54, source: 40, Type: "Feeding" },
  { target: 55, source: 40, Type: "Feeding" },
  { target: 0, source: 40, Type: "Feeding" },
  { target: 1, source: 40, Type: "Feeding" },
  { target: 2, source: 40, Type: "Feeding" },
  { target: 3, source: 40, Type: "Feeding" },
  { target: 50, source: 41, Type: "Feeding" },
  { target: 51, source: 41, Type: "Feeding" },
  { target: 52, source: 41, Type: "Feeding" },
  { target: 54, source: 41, Type: "Feeding" },
  { target: 50, source: 42, Type: "Feeding" },
  { target: 51, source: 42, Type: "Feeding" },
  { target: 53, source: 42, Type: "Feeding" },
  { target: 54, source: 42, Type: "Feeding" },
  { target: 55, source: 44, Type: "Feeding" },
  { target: 0, source: 44, Type: "Feeding" },
  { target: 1, source: 44, Type: "Feeding" },
  { target: 2, source: 44, Type: "Feeding" },
  { target: 3, source: 44, Type: "Feeding" },
  { target: 49, source: 45, Type: "Feeding" },
  { target: 50, source: 45, Type: "Feeding" },
  { target: 51, source: 45, Type: "Feeding" },
  { target: 52, source: 45, Type: "Feeding" },
  { target: 54, source: 45, Type: "Feeding" },
  { target: 50, source: 46, Type: "Feeding" },
  { target: 51, source: 46, Type: "Feeding" },
  { target: 54, source: 46, Type: "Feeding" },
  { target: 50, source: 47, Type: "Feeding" },
  { target: 51, source: 47, Type: "Feeding" },
  { target: 53, source: 47, Type: "Feeding" },
  { target: 52, source: 47, Type: "Feeding" },
  { target: 54, source: 47, Type: "Feeding" },
  { target: 55, source: 47, Type: "Feeding" },
  { target: 50, source: 48, Type: "Feeding" },
  { target: 54, source: 48, Type: "Feeding" },
  { target: 0, source: 48, Type: "Feeding" },
  { target: 7, source: 48, Type: "Feeding" },
  { target: 27, source: 48, Type: "Feeding" },
  { target: 33, source: 48, Type: "Feeding" },
  { target: 54, source: 53, Type: "Feeding" },
  { target: 7, source: 56, Type: "ES" },
  { target: 9, source: 56, Type: "ES" },
  { target: 15, source: 56, Type: "ES" },
  { target: 26, source: 56, Type: "ES" },
  { target: 33, source: 56, Type: "ES" },
];
export const nodeList5 = [
  {
    index: 0,
    speciesID: 61,
    biomass: 0.0001898,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Softshell Clam",
    trophicLevel: 2.0,
    desc: "The California Softshell Clam is one of many softshell clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 1,
    speciesID: 66,
    biomass: 0.000352,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Blue Mussel",
    trophicLevel: 2.0,
    desc: "The Blue Mussel is a medium-sized, edible mussel. It is vulnerable to ocean acidification and pollution.",
    imgFile: "b-mussel.jpg",
    imgCaption: "Clusters of blue mussels in shallow water.",
  },
  {
    index: 2,
    speciesID: 68,
    biomass: 569.15303,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Pacific Littleneck Clam",
    trophicLevel: 2.0,
    desc: "The Pacific Littleneck Clam is one of many clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 3,
    speciesID: 111,
    biomass: 7.1600804,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "California Ghost Shrimp",
    trophicLevel: 2.0,
    desc: "The California Ghost Shrimp, also known as the Bay Ghost Shrimp, is one of many species of ghost shrimp. ",
    imgFile: "ghost-shrimp.jpg",
    imgCaption: "A similar species of ghost shrimp.",
  },
  {
    index: 4,
    speciesID: 112,
    biomass: 8.277904,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Southern Mud Shrimp",
    trophicLevel: 3.367679719,
    desc: "The Southern Mud Shrimp is a species of crustacean that spends most of its time burrowed in mud and tidal flats.",
    imgFile: "mud-shrimp.jpg",
    imgCaption: "A similar species of mud shrimp.",
  },
  {
    index: 5,
    speciesID: 113,
    biomass: 0.1412562,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Green Shore Crab",
    trophicLevel: 3.761686501,
    desc: "The Green Shore Crab is one of the most common littoral crabs. This species is vulnerable to pollution.",
    imgFile: "g-crab.jpg",
    imgCaption: "A Green Shore Crab underwater in sand.",
  },
  {
    index: 6,
    speciesID: 114,
    biomass: 175.59909,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Striped Shore Crab",
    trophicLevel: 3.7593492,
    desc: "The Striped Shore Crab is a species of crab that lives in muddy areas. This species is likely vulnerable to pollution.",
    imgFile: "stripe-crab.jpg",
    imgCaption: "A Striped Shore Crab standing in a rocky sand patch.",
  },
  {
    index: 7,
    speciesID: 115,
    biomass: 0.2141502,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Fiddler Crab",
    trophicLevel: 2.0,
    desc: "Fiddler crabs are small crustacenas with a distinctive large claw. ",
    imgFile: "fiddler.jpg",
    imgCaption: "A fiddler crab standing above its burrow in rocky sand.",
  },
  {
    index: 8,
    speciesID: 118,
    biomass: 4.5752376,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Topsmelt",
    trophicLevel: 3.367679719,
    desc: "Topsmelt is a small fish species found in bays and estuaries, among other coastal ecosystems. This species is targeted by commercial fisheries but is considered to have a stable population size.",
    imgFile: "topsmelt.jpg",
    imgCaption: "Topsmelt Silverside on towel.",
  },
  {
    index: 9,
    speciesID: 120,
    biomass: 0.0449312,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Shiner Surfperch",
    trophicLevel: 4.19739918,
    desc: "Shiner surfperch are non-migratory fish that live in marine and brackish (slightly salty, occuring where salt and fresh water meet) waters. There are small commercial fisheries that target this species, and it is occasionally used as bait by recreational fishers. This species is vulnerable to habitat loss.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch swimming.",
  },
  {
    index: 10,
    speciesID: 125,
    biomass: 0.2618094,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Diamond Turbot",
    trophicLevel: 4.143871019,
    desc: "Diamond Turbot is a type of flatfish that occurs in coastal and brackish waters, living on muddy and sandy bottoms. They are not commercially targeted, but some recreational fishers may target this specices. It is not considered to be vulnerable. ",
    imgFile: "turbot.jpg",
    imgCaption:
      "Windowpane flounder laying on table. Similar species to Diamond turbot.",
  },
  {
    index: 11,
    speciesID: 132,
    biomass: 0.1536856,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Halibut",
    trophicLevel: 4.739973263,
    desc: "California Halibut is a large species of flatfish that lives on the Pacific Coast of the United States. This species is targeted by both commercial and recreational fisheries. It is considered to be vulnerable to the loss of critical nursery habitat.",
    imgFile: "halibut.jpg",
    imgCaption:
      "Three men standing next two many of the California Halibut that they caught.",
  },
  {
    index: 12,
    speciesID: 139,
    biomass: 0.0863925,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Leopard Shark",
    trophicLevel: 4.345293229,
    desc: "Leopard sharks are a small species of shark that lives in shallow waters in the Pacific Coast of the United States. It is targeted by both commercial and recreational fisheries, and is susceptible to overfishing.",
    imgFile: "leo-shark.jpg",
    imgCaption: "Leopard shark in sand.",
  },
  {
    index: 13,
    speciesID: 140,
    biomass: 0.0284862,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Haller's Round Ray",
    trophicLevel: 4.038518203,
    desc: "Haller's Round Ray is a species of stingray that lives in shallow coastal waters along the coast of California. Its range extends south to Ecuador. There a some fisheries that target this species but it is generally not considered vulnerable. ",
    imgFile: "Urobatis.jpg",
    imgCaption:
      "Yellow stingray, a species of stingray close to Haller's Round Ray.",
  },
  {
    index: 14,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 5.063628521,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 15,
    speciesID: 143,
    biomass: 0.0922059,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Coot",
    trophicLevel: 3.226319891,
    desc: "The American Coot is considered to be an adaptable waterbird with a range covering most of the United States, extending into Canada and Mexico. It has a low vulnerability status.",
    imgFile: "amer-coot.jpg",
    imgCaption: "American Coot getting ready to take flight from water.",
  },
  {
    index: 16,
    speciesID: 144,
    biomass: 0.0013822,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Crow",
    trophicLevel: 4.327078909,
    desc: "The American Crow is considered one of the most intelligent birds and is widespread across the United States. This species has a low vulnerability status.",
    imgFile: "crow.jpg",
    imgCaption: "American Crow standing on a branch.",
  },
  {
    index: 17,
    speciesID: 145,
    biomass: 0.0674677,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Wigeon",
    trophicLevel: 3.984251786,
    desc: "The American Wigeon is a species of duck. It is considered to be moderately vulnerable to climate change.",
    imgFile: "amer-wigeon.jpg",
    imgCaption: "American Wigeon sitting in water.",
  },
  {
    index: 18,
    speciesID: 146,
    biomass: 0.0063977,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-bellied Plover",
    trophicLevel: 4.115959071,
    desc: "The Black-bellied plover spends winter on the Pacific coast of the United States. In the summers, it lives in Northern Canada. This species is highly vulnerable to climate change. ",
    imgFile: "bb-plover.jpg",
    imgCaption: "Black-bellied Plover standing in field.",
  },
  {
    index: 19,
    speciesID: 147,
    biomass: 0.0300871,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-crowned Night Heron",
    trophicLevel: 4.754162869,
    desc: "The Black-crowned Night Heron can be found across the United States and is vulnerable to water pollution and habitat loss.",
    imgFile: "night-heron.jpg",
    imgCaption: "Black-crowned Night Heron standing on rock.",
  },
  {
    index: 20,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 4.829262493,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 21,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 4.137496207,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 22,
    speciesID: 154,
    biomass: 0.0915692,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bufflehead",
    trophicLevel: 3.886849217,
    desc: "The Bufflehead is a smaller duck species that occurs in North America. It is generally considered to have a stable population, but is vulnerable to habitat loss and highly vulnerable to climate change.",
    imgFile: "bufflehead.jpg",
    imgCaption: "Two Buffleheads sitting in water.",
  },
  {
    index: 23,
    speciesID: 155,
    biomass: 0.0516353,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Blue-winged Teal",
    trophicLevel: 3.984251786,
    desc: "The Blue-winged Teal is a small species of duck that spends its summers in Canada and the United States, migrating south towards South America in the winter. It is considered to have a low vulnerability status. ",
    imgFile: "bw-teal.jpg",
    imgCaption: "Blue-winged Teal sitting near water.",
  },
  {
    index: 24,
    speciesID: 157,
    biomass: 0.249866575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "California Gull",
    trophicLevel: 4.235647754,
    desc: "The California Gull is a gull species that occurs mainly on the Pacific coast of North America. It is moderately vulnerable to climate change.",
    imgFile: "ca-gull.jpg",
    imgCaption: "California Gull standing on a rock near water.",
  },
  {
    index: 25,
    speciesID: 158,
    biomass: 0.0036287,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Caspian Tern",
    trophicLevel: 4.829262493,
    desc: "The Caspian Tern is the largest of all tern species. These birds have a low vulnerability to climate change.",
    imgFile: "c-tern.jpg",
    imgCaption: "Two Caspian Terns standing in sand.",
  },
  {
    index: 26,
    speciesID: 159,
    biomass: 0.0149052,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cinnamon Teal",
    trophicLevel: 3.984251786,
    desc: "The Cinnamon Teal is a common species of duck that can be found only in the Western half of the United States, in addition to their range in Mexico. It is moderately vulnerable to climate change. ",
    imgFile: "cinn-teal.jpg",
    imgCaption: "Cinnamon Teal swimming in water.",
  },
  {
    index: 27,
    speciesID: 161,
    biomass: 0.002242,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cooper's Hawk",
    trophicLevel: 5.477144228,
    desc: "The Cooper's Hawk is a widespread species that is not considered to be vulnerable to climate change.",
    imgFile: "c-hawk.jpg",
    imgCaption: "Cooper's Hawk sitting on a tree branch.",
  },
  {
    index: 28,
    speciesID: 163,
    biomass: 0.0124805,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Double-crested Cormorant",
    trophicLevel: 5.074326352,
    desc: "The Double-crested Cormorant is a species of diving birds that is not considered to be vulnerable to climate change. However, it may be impacted by pollution.",
    imgFile: "cormorant.jpg",
    imgCaption: "Double-crested Cormorant sitting in water.",
  },
  {
    index: 29,
    speciesID: 164,
    biomass: 0.0090074,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Dunlin",
    trophicLevel: 4.282310965,
    desc: "The Dunlin is a migrating species that spends its winters on the Pacific coast of the United States. This species has seen considerable declines in their wintering seasons and is highly vulnerable to climate change.",
    imgFile: "dunlin.jpg",
    imgCaption: "Dunlin standing in water.",
  },
  {
    index: 30,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 5.074326352,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 31,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 5.074326352,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 32,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 4.829262493,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 33,
    speciesID: 172,
    biomass: 0.0396191,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Greater Yellowlegs",
    trophicLevel: 5.063628521,
    desc: "Greater Yellowlegs are a migrating, wading bird species. It is highly vulnerable to climate change, and there is not consensus on the stability of its population.",
    imgFile: "g-yellow.jpg",
    imgCaption: "Greater Yellowlegs standing in shallow water.",
  },
  {
    index: 34,
    speciesID: 173,
    biomass: 0.1386956,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green-winged Teal",
    trophicLevel: 3.984251786,
    desc: "This small duck species is common and widespread. The Green-winged Teal is considered to have low vulnerability to climate change.",
    imgFile: "gw-teal.jpg",
    imgCaption: "Green-winged Teal swimming in water.",
  },
  {
    index: 35,
    speciesID: 174,
    biomass: 0.0062451,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Killdeer",
    trophicLevel: 4.879023736,
    desc: "The Killdeer is a common species of bird, and is not considered to be vulnerable to climate change. ",
    imgFile: "killdeer.jpg",
    imgCaption: "Killdeer sitting in gravel.",
  },
  {
    index: 36,
    speciesID: 175,
    biomass: 0.0835354,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Curlew",
    trophicLevel: 4.038518203,
    desc: "The Long-billed Curlew is one of the largest shorebirds, migrating across the Western United States and Mexico. It is vulnerable to habitat loss, as much of their breeding habitat has been converted to agriculture. They are also highly vulnerable to climate change.",
    imgFile: "lb-curlew.jpg",
    imgCaption: "Long-billed Curlew taking flight from water.",
  },
  {
    index: 37,
    speciesID: 176,
    biomass: 0.0553906,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Dowitcher",
    trophicLevel: 4.502314369,
    desc: "The Long-billed Dowitcher is a similar species to the Long-billed Curlew. While they overlap in range, the Long-billed Dowitcher prefers fresh water to salt water. This species is highly vulnerable to climate change.",
    imgFile: "lb-dowitcher.jpg",
    imgCaption: "Long-billed Dowitcher standing in shallow water.",
  },
  {
    index: 38,
    speciesID: 177,
    biomass: 0.0035291,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Little Blue Heron",
    trophicLevel: 4.904551329,
    desc: "The Little Blue Heron is a migrating bird that is not considered to be vulnerable to climate change.",
    imgFile: "lb-heron.jpg",
    imgCaption: "Little Blue Heron standing in marsh water.",
  },
  {
    index: 39,
    speciesID: 178,
    biomass: 0.0356641,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Least Sandpiper",
    trophicLevel: 4.825318877,
    desc: 'The Least Sandpiper is a migrating species. This species relies less on "stopover" sites than other migrating birds, and is considered to be moderately vulnerable to climate change.',
    imgFile: "least-sand.jpg",
    imgCaption: "Least Sandpiper standing at water's edge.",
  },
  {
    index: 40,
    speciesID: 181,
    biomass: 0.1057894,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Marbled Godwit",
    trophicLevel: 4.127187789,
    desc: "The Marbled Godwit is a migrating bird that is declining due to habitat loss in their summer habitat. They are moderately vulnerable to climate change.",
    imgFile: "m-godwit.jpg",
    imgCaption: "Marbled Godwit standing in waves.",
  },
  {
    index: 41,
    speciesID: 182,
    biomass: 0.4704502,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mallard",
    trophicLevel: 3.984251786,
    desc: "Mallards are one of the most abundant ducks in the world. They migrate across North America and are considered to have a low vulnerability to climate change.",
    imgFile: "mallard.jpg",
    imgCaption: "Three female and two male Mallards standing in shallow water.",
  },
  {
    index: 42,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 4.137496207,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 43,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 4.628746582,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 44,
    speciesID: 189,
    biomass: 0.0064582,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ring-billed Gull",
    trophicLevel: 4.137496207,
    desc: "The Ring-billed Gull is one of the most common gulls in North America, and has benefited greatly from the aavailability of food provided by humans. It is considered to have a low vulnerability status to climate change. ",
    imgFile: "rb-gull.jpg",
    imgCaption: "Ring-billed Gull standing in shallow water.",
  },
  {
    index: 45,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 5.074326352,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 46,
    speciesID: 194,
    biomass: 0.0019375,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ruddy Duck",
    trophicLevel: 4.166361101,
    desc: "Ruddy Ducks are a species of stiff-tailed ducks in North America. It is vulnerable to habitat loss, and mildly vulnerable to climate change.",
    imgFile: "ruddy.jpg",
    imgCaption: "Ruddy Dyck taking flight from water. ",
  },
  {
    index: 47,
    speciesID: 197,
    biomass: 0.0070175,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Semipalmated Plover",
    trophicLevel: 5.110601835,
    desc: "While the Semipalmated is widespread and common in coastal ecosystems across the United States, this species is considered to be highly vulnerable to climate change.",
    imgFile: "semi-plover.jpg",
    imgCaption: "Juvenile Semipalmated Plover walking in sand. ",
  },
  {
    index: 48,
    speciesID: 198,
    biomass: 0.0758358,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Snowy Egret",
    trophicLevel: 4.904551329,
    desc: "The Snowy Egret is a small species of egret that is found mostly in the Southern United States and Mexico. It is continuously recovering from historic population decreases and is not considered to be vulnerable to climate change.",
    imgFile: "snow-egret.jpg",
    imgCaption: "Snowy Egret standing on rocks near rushing water.",
  },
  {
    index: 49,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 4.618698401,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 50,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 3.846247345,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 51,
    speciesID: 204,
    biomass: 0.0470025,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Turkey Vulture",
    trophicLevel: 1.0,
    desc: "Turkey Vultures are widespread across North America and are not considered to be vulnerable to climate change.",
    imgFile: "t-vulture.jpg",
    imgCaption: "Turkey Vulture flying.",
  },
  {
    index: 52,
    speciesID: 205,
    biomass: 0.0218222,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Grebe",
    trophicLevel: 5.074326352,
    desc: "The Western Grebe is a species of aquatic birds that migrates between fresh water lakes and brackish coastal waters in North America. While this species used to be targeted for its feathers, it is currently only vulnerable to climate change.",
    imgFile: "w-grebe.jpg",
    imgCaption: "Two Western Grebes swimming.",
  },
  {
    index: 53,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 4.182248667,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 54,
    speciesID: 208,
    biomass: 0.0192895,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Whimbrel",
    trophicLevel: 4.318958747,
    desc: "Whimbrels are a shorebird that occurs in North America. They are highly vulnerable to climate change, and are still recovering from historically depleted populations.",
    imgFile: "whimbrel.jpg",
    imgCaption: "Whimbrel standing in shallow water.",
  },
  {
    index: 55,
    speciesID: 209,
    biomass: 0.7094073,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Willet",
    trophicLevel: 4.049479192,
    desc: "Willets are large shorebirds that occur in prairie and salt marshes in North America. They are vulnerable to habitat disturbance and loss.",
    imgFile: "willet.jpg",
    imgCaption: "Willet flying over water.",
  },
  {
    index: 56,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 4.609138649,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 57,
    speciesID: 300,
    biomass: 47981.15827,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Plants",
    trophicLevel: 1.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 58,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organisms",
    trophicLevel: 3.89479836,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 59,
    speciesID: 500,
    biomass: 1219.757352,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Other Molluscs",
    trophicLevel: 3.178959672,
    desc: "Molluscs are invertebrate animals, meaning they do not have backbones. There are more than 85,000 species of molluscs today. Mollsucs are shell-forming animals and are vulnerable to ocean acidifcation and pollution.",
    imgFile: "mussels.jpg",
    imgCaption: "Many mussels underwater.",
  },
  {
    index: 60,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 5.258047472,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 61,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Plankton",
    trophicLevel: 2.5,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 62,
    speciesID: 850,
    biomass: 0.369631471,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Birdwatching",
    trophicLevel: 5.649293492,
    desc: "Birdwatching, or birding, is a type of wildlife viewing, where people track, look and listen for birds. This can be done for recreation and/or citizen science.",
    imgFile: "birdwatch.jpg",
    imgCaption: "Binoculars on bird guide.",
  },
  {
    index: 63,
    speciesID: 900,
    biomass: 13.9641979,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Other Fish",
    trophicLevel: 3.922708579,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
];

export const edgeList5 = [
  { target: 57, source: 58, Type: "Feeding" },
  { target: 63, source: 58, Type: "Feeding" },
  { target: 5, source: 58, Type: "Feeding" },
  { target: 57, source: 0, Type: "Feeding" },
  { target: 57, source: 59, Type: "Feeding" },
  { target: 58, source: 59, Type: "Feeding" },
  { target: 0, source: 59, Type: "Feeding" },
  { target: 1, source: 59, Type: "Feeding" },
  { target: 2, source: 59, Type: "Feeding" },
  { target: 57, source: 1, Type: "Feeding" },
  { target: 57, source: 2, Type: "Feeding" },
  { target: 14, source: 60, Type: "Feeding" },
  { target: 15, source: 60, Type: "Feeding" },
  { target: 16, source: 60, Type: "Feeding" },
  { target: 17, source: 60, Type: "Feeding" },
  { target: 18, source: 60, Type: "Feeding" },
  { target: 19, source: 60, Type: "Feeding" },
  { target: 20, source: 60, Type: "Feeding" },
  { target: 21, source: 60, Type: "Feeding" },
  { target: 22, source: 60, Type: "Feeding" },
  { target: 23, source: 60, Type: "Feeding" },
  { target: 24, source: 60, Type: "Feeding" },
  { target: 25, source: 60, Type: "Feeding" },
  { target: 26, source: 60, Type: "Feeding" },
  { target: 27, source: 60, Type: "Feeding" },
  { target: 28, source: 60, Type: "Feeding" },
  { target: 29, source: 60, Type: "Feeding" },
  { target: 30, source: 60, Type: "Feeding" },
  { target: 31, source: 60, Type: "Feeding" },
  { target: 32, source: 60, Type: "Feeding" },
  { target: 33, source: 60, Type: "Feeding" },
  { target: 34, source: 60, Type: "Feeding" },
  { target: 35, source: 60, Type: "Feeding" },
  { target: 36, source: 60, Type: "Feeding" },
  { target: 37, source: 60, Type: "Feeding" },
  { target: 38, source: 60, Type: "Feeding" },
  { target: 39, source: 60, Type: "Feeding" },
  { target: 40, source: 60, Type: "Feeding" },
  { target: 41, source: 60, Type: "Feeding" },
  { target: 42, source: 60, Type: "Feeding" },
  { target: 43, source: 60, Type: "Feeding" },
  { target: 44, source: 60, Type: "Feeding" },
  { target: 45, source: 60, Type: "Feeding" },
  { target: 46, source: 60, Type: "Feeding" },
  { target: 47, source: 60, Type: "Feeding" },
  { target: 48, source: 60, Type: "Feeding" },
  { target: 49, source: 60, Type: "Feeding" },
  { target: 50, source: 60, Type: "Feeding" },
  { target: 51, source: 60, Type: "Feeding" },
  { target: 52, source: 60, Type: "Feeding" },
  { target: 53, source: 60, Type: "Feeding" },
  { target: 54, source: 60, Type: "Feeding" },
  { target: 55, source: 60, Type: "Feeding" },
  { target: 56, source: 60, Type: "Feeding" },
  { target: 8, source: 60, Type: "Feeding" },
  { target: 63, source: 60, Type: "Feeding" },
  { target: 57, source: 60, Type: "Feeding" },
  { target: 57, source: 61, Type: "Feeding" },
  { target: 61, source: 60, Type: "Feeding" },
  { target: 57, source: 3, Type: "Feeding" },
  { target: 57, source: 4, Type: "Feeding" },
  { target: 58, source: 4, Type: "Feeding" },
  { target: 0, source: 4, Type: "Feeding" },
  { target: 59, source: 4, Type: "Feeding" },
  { target: 1, source: 4, Type: "Feeding" },
  { target: 2, source: 4, Type: "Feeding" },
  { target: 61, source: 4, Type: "Feeding" },
  { target: 57, source: 5, Type: "Feeding" },
  { target: 58, source: 5, Type: "Feeding" },
  { target: 0, source: 5, Type: "Feeding" },
  { target: 59, source: 5, Type: "Feeding" },
  { target: 1, source: 5, Type: "Feeding" },
  { target: 2, source: 5, Type: "Feeding" },
  { target: 60, source: 5, Type: "Feeding" },
  { target: 57, source: 6, Type: "Feeding" },
  { target: 58, source: 6, Type: "Feeding" },
  { target: 0, source: 6, Type: "Feeding" },
  { target: 59, source: 6, Type: "Feeding" },
  { target: 1, source: 6, Type: "Feeding" },
  { target: 2, source: 6, Type: "Feeding" },
  { target: 61, source: 6, Type: "Feeding" },
  { target: 60, source: 6, Type: "Feeding" },
  { target: 5, source: 6, Type: "Feeding" },
  { target: 7, source: 6, Type: "Feeding" },
  { target: 57, source: 7, Type: "Feeding" },
  { target: 58, source: 63, Type: "Feeding" },
  { target: 61, source: 63, Type: "Feeding" },
  { target: 5, source: 63, Type: "Feeding" },
  { target: 6, source: 63, Type: "Feeding" },
  { target: 57, source: 8, Type: "Feeding" },
  { target: 58, source: 8, Type: "Feeding" },
  { target: 0, source: 8, Type: "Feeding" },
  { target: 59, source: 8, Type: "Feeding" },
  { target: 1, source: 8, Type: "Feeding" },
  { target: 2, source: 8, Type: "Feeding" },
  { target: 61, source: 8, Type: "Feeding" },
  { target: 0, source: 63, Type: "Feeding" },
  { target: 59, source: 63, Type: "Feeding" },
  { target: 1, source: 63, Type: "Feeding" },
  { target: 2, source: 63, Type: "Feeding" },
  { target: 60, source: 63, Type: "Feeding" },
  { target: 58, source: 9, Type: "Feeding" },
  { target: 61, source: 9, Type: "Feeding" },
  { target: 57, source: 63, Type: "Feeding" },
  { target: 3, source: 63, Type: "Feeding" },
  { target: 4, source: 63, Type: "Feeding" },
  { target: 7, source: 63, Type: "Feeding" },
  { target: 9, source: 63, Type: "Feeding" },
  { target: 58, source: 10, Type: "Feeding" },
  { target: 59, source: 10, Type: "Feeding" },
  { target: 2, source: 10, Type: "Feeding" },
  { target: 61, source: 10, Type: "Feeding" },
  { target: 3, source: 10, Type: "Feeding" },
  { target: 4, source: 10, Type: "Feeding" },
  { target: 5, source: 10, Type: "Feeding" },
  { target: 6, source: 10, Type: "Feeding" },
  { target: 7, source: 10, Type: "Feeding" },
  { target: 63, source: 10, Type: "Feeding" },
  { target: 9, source: 10, Type: "Feeding" },
  { target: 58, source: 11, Type: "Feeding" },
  { target: 61, source: 11, Type: "Feeding" },
  { target: 5, source: 11, Type: "Feeding" },
  { target: 6, source: 11, Type: "Feeding" },
  { target: 63, source: 11, Type: "Feeding" },
  { target: 9, source: 11, Type: "Feeding" },
  { target: 10, source: 11, Type: "Feeding" },
  { target: 58, source: 12, Type: "Feeding" },
  { target: 59, source: 12, Type: "Feeding" },
  { target: 2, source: 12, Type: "Feeding" },
  { target: 3, source: 12, Type: "Feeding" },
  { target: 4, source: 12, Type: "Feeding" },
  { target: 5, source: 12, Type: "Feeding" },
  { target: 6, source: 12, Type: "Feeding" },
  { target: 7, source: 12, Type: "Feeding" },
  { target: 8, source: 12, Type: "Feeding" },
  { target: 63, source: 12, Type: "Feeding" },
  { target: 9, source: 12, Type: "Feeding" },
  { target: 10, source: 12, Type: "Feeding" },
  { target: 11, source: 12, Type: "Feeding" },
  { target: 61, source: 12, Type: "Feeding" },
  { target: 58, source: 13, Type: "Feeding" },
  { target: 59, source: 13, Type: "Feeding" },
  { target: 2, source: 13, Type: "Feeding" },
  { target: 3, source: 13, Type: "Feeding" },
  { target: 4, source: 13, Type: "Feeding" },
  { target: 5, source: 13, Type: "Feeding" },
  { target: 6, source: 13, Type: "Feeding" },
  { target: 7, source: 13, Type: "Feeding" },
  { target: 63, source: 13, Type: "Feeding" },
  { target: 61, source: 13, Type: "Feeding" },
  { target: 58, source: 14, Type: "Feeding" },
  { target: 59, source: 14, Type: "Feeding" },
  { target: 60, source: 14, Type: "Feeding" },
  { target: 63, source: 14, Type: "Feeding" },
  { target: 57, source: 15, Type: "Feeding" },
  { target: 59, source: 15, Type: "Feeding" },
  { target: 61, source: 15, Type: "Feeding" },
  { target: 58, source: 16, Type: "Feeding" },
  { target: 59, source: 16, Type: "Feeding" },
  { target: 2, source: 16, Type: "Feeding" },
  { target: 5, source: 16, Type: "Feeding" },
  { target: 6, source: 16, Type: "Feeding" },
  { target: 8, source: 16, Type: "Feeding" },
  { target: 57, source: 17, Type: "Feeding" },
  { target: 59, source: 17, Type: "Feeding" },
  { target: 61, source: 17, Type: "Feeding" },
  { target: 60, source: 17, Type: "Feeding" },
  { target: 58, source: 18, Type: "Feeding" },
  { target: 0, source: 18, Type: "Feeding" },
  { target: 59, source: 18, Type: "Feeding" },
  { target: 1, source: 18, Type: "Feeding" },
  { target: 2, source: 18, Type: "Feeding" },
  { target: 60, source: 18, Type: "Feeding" },
  { target: 61, source: 18, Type: "Feeding" },
  { target: 5, source: 18, Type: "Feeding" },
  { target: 6, source: 18, Type: "Feeding" },
  { target: 7, source: 18, Type: "Feeding" },
  { target: 63, source: 18, Type: "Feeding" },
  { target: 58, source: 19, Type: "Feeding" },
  { target: 5, source: 19, Type: "Feeding" },
  { target: 6, source: 19, Type: "Feeding" },
  { target: 7, source: 19, Type: "Feeding" },
  { target: 63, source: 19, Type: "Feeding" },
  { target: 8, source: 19, Type: "Feeding" },
  { target: 9, source: 19, Type: "Feeding" },
  { target: 10, source: 19, Type: "Feeding" },
  { target: 11, source: 19, Type: "Feeding" },
  { target: 8, source: 20, Type: "Feeding" },
  { target: 9, source: 20, Type: "Feeding" },
  { target: 63, source: 20, Type: "Feeding" },
  { target: 58, source: 21, Type: "Feeding" },
  { target: 59, source: 21, Type: "Feeding" },
  { target: 1, source: 21, Type: "Feeding" },
  { target: 2, source: 21, Type: "Feeding" },
  { target: 5, source: 21, Type: "Feeding" },
  { target: 6, source: 21, Type: "Feeding" },
  { target: 8, source: 21, Type: "Feeding" },
  { target: 58, source: 22, Type: "Feeding" },
  { target: 59, source: 22, Type: "Feeding" },
  { target: 1, source: 22, Type: "Feeding" },
  { target: 2, source: 22, Type: "Feeding" },
  { target: 61, source: 22, Type: "Feeding" },
  { target: 5, source: 22, Type: "Feeding" },
  { target: 6, source: 22, Type: "Feeding" },
  { target: 7, source: 22, Type: "Feeding" },
  { target: 57, source: 23, Type: "Feeding" },
  { target: 59, source: 23, Type: "Feeding" },
  { target: 61, source: 23, Type: "Feeding" },
  { target: 60, source: 23, Type: "Feeding" },
  { target: 58, source: 24, Type: "Feeding" },
  { target: 59, source: 24, Type: "Feeding" },
  { target: 1, source: 24, Type: "Feeding" },
  { target: 2, source: 24, Type: "Feeding" },
  { target: 5, source: 24, Type: "Feeding" },
  { target: 6, source: 24, Type: "Feeding" },
  { target: 8, source: 24, Type: "Feeding" },
  { target: 63, source: 24, Type: "Feeding" },
  { target: 8, source: 25, Type: "Feeding" },
  { target: 63, source: 25, Type: "Feeding" },
  { target: 9, source: 25, Type: "Feeding" },
  { target: 57, source: 26, Type: "Feeding" },
  { target: 59, source: 26, Type: "Feeding" },
  { target: 61, source: 26, Type: "Feeding" },
  { target: 60, source: 26, Type: "Feeding" },
  { target: 29, source: 27, Type: "Feeding" },
  { target: 39, source: 27, Type: "Feeding" },
  { target: 49, source: 27, Type: "Feeding" },
  { target: 53, source: 27, Type: "Feeding" },
  { target: 63, source: 28, Type: "Feeding" },
  { target: 8, source: 28, Type: "Feeding" },
  { target: 9, source: 28, Type: "Feeding" },
  { target: 10, source: 28, Type: "Feeding" },
  { target: 11, source: 28, Type: "Feeding" },
  { target: 58, source: 29, Type: "Feeding" },
  { target: 59, source: 29, Type: "Feeding" },
  { target: 2, source: 29, Type: "Feeding" },
  { target: 61, source: 29, Type: "Feeding" },
  { target: 9, source: 29, Type: "Feeding" },
  { target: 63, source: 29, Type: "Feeding" },
  { target: 63, source: 30, Type: "Feeding" },
  { target: 8, source: 30, Type: "Feeding" },
  { target: 9, source: 30, Type: "Feeding" },
  { target: 10, source: 30, Type: "Feeding" },
  { target: 11, source: 30, Type: "Feeding" },
  { target: 63, source: 31, Type: "Feeding" },
  { target: 8, source: 31, Type: "Feeding" },
  { target: 9, source: 31, Type: "Feeding" },
  { target: 10, source: 31, Type: "Feeding" },
  { target: 11, source: 31, Type: "Feeding" },
  { target: 8, source: 32, Type: "Feeding" },
  { target: 63, source: 32, Type: "Feeding" },
  { target: 9, source: 32, Type: "Feeding" },
  { target: 58, source: 33, Type: "Feeding" },
  { target: 59, source: 33, Type: "Feeding" },
  { target: 60, source: 33, Type: "Feeding" },
  { target: 63, source: 33, Type: "Feeding" },
  { target: 57, source: 34, Type: "Feeding" },
  { target: 59, source: 34, Type: "Feeding" },
  { target: 61, source: 34, Type: "Feeding" },
  { target: 60, source: 34, Type: "Feeding" },
  { target: 60, source: 35, Type: "Feeding" },
  { target: 61, source: 35, Type: "Feeding" },
  { target: 58, source: 36, Type: "Feeding" },
  { target: 0, source: 36, Type: "Feeding" },
  { target: 59, source: 36, Type: "Feeding" },
  { target: 3, source: 36, Type: "Feeding" },
  { target: 4, source: 36, Type: "Feeding" },
  { target: 5, source: 36, Type: "Feeding" },
  { target: 6, source: 36, Type: "Feeding" },
  { target: 7, source: 36, Type: "Feeding" },
  { target: 63, source: 36, Type: "Feeding" },
  { target: 61, source: 36, Type: "Feeding" },
  { target: 58, source: 37, Type: "Feeding" },
  { target: 59, source: 37, Type: "Feeding" },
  { target: 2, source: 37, Type: "Feeding" },
  { target: 61, source: 37, Type: "Feeding" },
  { target: 60, source: 37, Type: "Feeding" },
  { target: 5, source: 37, Type: "Feeding" },
  { target: 63, source: 37, Type: "Feeding" },
  { target: 58, source: 38, Type: "Feeding" },
  { target: 60, source: 38, Type: "Feeding" },
  { target: 5, source: 38, Type: "Feeding" },
  { target: 6, source: 38, Type: "Feeding" },
  { target: 7, source: 38, Type: "Feeding" },
  { target: 63, source: 38, Type: "Feeding" },
  { target: 8, source: 38, Type: "Feeding" },
  { target: 9, source: 38, Type: "Feeding" },
  { target: 10, source: 38, Type: "Feeding" },
  { target: 11, source: 38, Type: "Feeding" },
  { target: 58, source: 39, Type: "Feeding" },
  { target: 59, source: 39, Type: "Feeding" },
  { target: 60, source: 39, Type: "Feeding" },
  { target: 61, source: 39, Type: "Feeding" },
  { target: 9, source: 39, Type: "Feeding" },
  { target: 63, source: 39, Type: "Feeding" },
  { target: 58, source: 40, Type: "Feeding" },
  { target: 59, source: 40, Type: "Feeding" },
  { target: 2, source: 40, Type: "Feeding" },
  { target: 61, source: 40, Type: "Feeding" },
  { target: 5, source: 40, Type: "Feeding" },
  { target: 6, source: 40, Type: "Feeding" },
  { target: 7, source: 40, Type: "Feeding" },
  { target: 63, source: 40, Type: "Feeding" },
  { target: 57, source: 41, Type: "Feeding" },
  { target: 59, source: 41, Type: "Feeding" },
  { target: 61, source: 41, Type: "Feeding" },
  { target: 60, source: 41, Type: "Feeding" },
  { target: 58, source: 42, Type: "Feeding" },
  { target: 59, source: 42, Type: "Feeding" },
  { target: 1, source: 42, Type: "Feeding" },
  { target: 2, source: 42, Type: "Feeding" },
  { target: 5, source: 42, Type: "Feeding" },
  { target: 6, source: 42, Type: "Feeding" },
  { target: 8, source: 42, Type: "Feeding" },
  { target: 58, source: 43, Type: "Feeding" },
  { target: 61, source: 43, Type: "Feeding" },
  { target: 5, source: 43, Type: "Feeding" },
  { target: 6, source: 43, Type: "Feeding" },
  { target: 7, source: 43, Type: "Feeding" },
  { target: 63, source: 43, Type: "Feeding" },
  { target: 8, source: 43, Type: "Feeding" },
  { target: 9, source: 43, Type: "Feeding" },
  { target: 10, source: 43, Type: "Feeding" },
  { target: 11, source: 43, Type: "Feeding" },
  { target: 58, source: 44, Type: "Feeding" },
  { target: 59, source: 44, Type: "Feeding" },
  { target: 1, source: 44, Type: "Feeding" },
  { target: 2, source: 44, Type: "Feeding" },
  { target: 5, source: 44, Type: "Feeding" },
  { target: 6, source: 44, Type: "Feeding" },
  { target: 8, source: 44, Type: "Feeding" },
  { target: 63, source: 45, Type: "Feeding" },
  { target: 8, source: 45, Type: "Feeding" },
  { target: 9, source: 45, Type: "Feeding" },
  { target: 10, source: 45, Type: "Feeding" },
  { target: 11, source: 45, Type: "Feeding" },
  { target: 57, source: 46, Type: "Feeding" },
  { target: 58, source: 46, Type: "Feeding" },
  { target: 59, source: 46, Type: "Feeding" },
  { target: 61, source: 46, Type: "Feeding" },
  { target: 60, source: 46, Type: "Feeding" },
  { target: 58, source: 47, Type: "Feeding" },
  { target: 59, source: 47, Type: "Feeding" },
  { target: 60, source: 47, Type: "Feeding" },
  { target: 58, source: 48, Type: "Feeding" },
  { target: 60, source: 48, Type: "Feeding" },
  { target: 5, source: 48, Type: "Feeding" },
  { target: 6, source: 48, Type: "Feeding" },
  { target: 7, source: 48, Type: "Feeding" },
  { target: 63, source: 48, Type: "Feeding" },
  { target: 8, source: 48, Type: "Feeding" },
  { target: 9, source: 48, Type: "Feeding" },
  { target: 10, source: 48, Type: "Feeding" },
  { target: 11, source: 48, Type: "Feeding" },
  { target: 58, source: 49, Type: "Feeding" },
  { target: 0, source: 49, Type: "Feeding" },
  { target: 59, source: 49, Type: "Feeding" },
  { target: 60, source: 49, Type: "Feeding" },
  { target: 5, source: 49, Type: "Feeding" },
  { target: 58, source: 50, Type: "Feeding" },
  { target: 59, source: 50, Type: "Feeding" },
  { target: 1, source: 50, Type: "Feeding" },
  { target: 2, source: 50, Type: "Feeding" },
  { target: 61, source: 50, Type: "Feeding" },
  { target: 3, source: 50, Type: "Feeding" },
  { target: 4, source: 50, Type: "Feeding" },
  { target: 5, source: 50, Type: "Feeding" },
  { target: 6, source: 50, Type: "Feeding" },
  { target: 7, source: 50, Type: "Feeding" },
  { target: 63, source: 52, Type: "Feeding" },
  { target: 8, source: 52, Type: "Feeding" },
  { target: 9, source: 52, Type: "Feeding" },
  { target: 10, source: 52, Type: "Feeding" },
  { target: 11, source: 52, Type: "Feeding" },
  { target: 57, source: 53, Type: "Feeding" },
  { target: 58, source: 53, Type: "Feeding" },
  { target: 0, source: 53, Type: "Feeding" },
  { target: 59, source: 53, Type: "Feeding" },
  { target: 60, source: 53, Type: "Feeding" },
  { target: 5, source: 53, Type: "Feeding" },
  { target: 58, source: 54, Type: "Feeding" },
  { target: 59, source: 54, Type: "Feeding" },
  { target: 5, source: 54, Type: "Feeding" },
  { target: 6, source: 54, Type: "Feeding" },
  { target: 7, source: 54, Type: "Feeding" },
  { target: 58, source: 55, Type: "Feeding" },
  { target: 0, source: 55, Type: "Feeding" },
  { target: 59, source: 55, Type: "Feeding" },
  { target: 1, source: 55, Type: "Feeding" },
  { target: 2, source: 55, Type: "Feeding" },
  { target: 61, source: 55, Type: "Feeding" },
  { target: 60, source: 55, Type: "Feeding" },
  { target: 3, source: 55, Type: "Feeding" },
  { target: 4, source: 55, Type: "Feeding" },
  { target: 5, source: 55, Type: "Feeding" },
  { target: 6, source: 55, Type: "Feeding" },
  { target: 7, source: 55, Type: "Feeding" },
  { target: 63, source: 55, Type: "Feeding" },
  { target: 58, source: 56, Type: "Feeding" },
  { target: 5, source: 56, Type: "Feeding" },
  { target: 6, source: 56, Type: "Feeding" },
  { target: 7, source: 56, Type: "Feeding" },
  { target: 8, source: 56, Type: "Feeding" },
  { target: 15, source: 56, Type: "Feeding" },
  { target: 35, source: 56, Type: "Feeding" },
  { target: 41, source: 56, Type: "Feeding" },
  { target: 3, source: 61, Type: "Feeding" },
  { target: 27, source: 62, Type: "ES" },
  { target: 21, source: 62, Type: "ES" },
  { target: 42, source: 62, Type: "ES" },
  { target: 20, source: 62, Type: "ES" },
  { target: 50, source: 62, Type: "ES" },
  { target: 45, source: 62, Type: "ES" },
  { target: 43, source: 62, Type: "ES" },
  { target: 14, source: 62, Type: "ES" },
];
export const nodeList6 = [
  {
    index: 0,
    speciesID: 5,
    biomass: 475.33333,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Salt Grass",
    trophicLevel: 1.0,
    desc: "Salt grass is a species of grass that grows in salt marshes.",
    imgFile: "salt-grass.jpg",
    imgCaption: "Salt Grass.",
  },
  {
    index: 1,
    speciesID: 6,
    biomass: 296.72,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Epipelic Flore",
    trophicLevel: 1.0,
    desc: "Epipelic flora includes plants that live in mud at the edge of water and land.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 2,
    speciesID: 7,
    biomass: 900.0,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Alkali Heath",
    trophicLevel: 1.0,
    desc: "Alkali Heath is a short, wood plant that grows in salt marshes.",
    imgFile: "heath.jpg",
    imgCaption: "Close up of Alkali Heath.",
  },
  {
    index: 3,
    speciesID: 8,
    biomass: 4488.6667,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Fleshy Jaumea",
    trophicLevel: 1.0,
    desc: "Fleshy Jaumea is a succulent species native to the western coast of North America.",
    imgFile: "fleshy-j.jpg",
    imgCaption: "Close up of Fleshy Jaumea.",
  },
  {
    index: 4,
    speciesID: 9,
    biomass: 120.66667,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "California Sea Lavender",
    trophicLevel: 1.0,
    desc: "California Sea Lavendar is a speces of ea lavender that's native to western North America.",
    imgFile: "ca-lav.jpg",
    imgCaption: "Close up of California Sea Lavender. ",
  },
  {
    index: 5,
    speciesID: 10,
    biomass: 256.49524,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Macroalgae",
    trophicLevel: 1.0,
    desc: "Macroalgae, also known as seaweed, includes many species of large, multicelluarl marine algae. ",
    imgFile: "macro.jpg",
    imgCaption: "Red Estuarine macroalgae in a dish.",
  },
  {
    index: 6,
    speciesID: 11,
    biomass: 1272.0,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Shore Grass",
    trophicLevel: 1.0,
    desc: "Shore Grass is a species of frass that grows along shorelines and in salt marshes.",
    imgFile: "shore-grass.jpg",
    imgCaption: "Shore Grass in front of water.",
  },
  {
    index: 7,
    speciesID: 12,
    biomass: 1527.7309,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Phytoplankton",
    trophicLevel: 1.0,
    desc: "Phytoplankton are plankton consisting of microscopic plants.",
    imgFile: "phyto.jpg",
    imgCaption: "Microscope image of one type of phytoplankton.",
  },
  {
    index: 8,
    speciesID: 16,
    biomass: 38428.267,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Pickleweed",
    trophicLevel: 1.0,
    desc: "Pickleweed is a type of succulent plant that is very common salt marshes.",
    imgFile: "pickle.jpg",
    imgCaption: "Close up of Pickleweed.",
  },
  {
    index: 9,
    speciesID: 61,
    biomass: 0.0001898,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Softshell Clam",
    trophicLevel: 2.0,
    desc: "The California Softshell Clam is one of many softshell clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 10,
    speciesID: 64,
    biomass: 0.0080906,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Bent-nosed Clam",
    trophicLevel: 2.0,
    desc: "Bent-nosed clams are a species of saltwater clams that are vulnerable to ocean acidification.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 11,
    speciesID: 65,
    biomass: 210.72214,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "White Sand Macoma",
    trophicLevel: 3.448965094,
    desc: "White Sant Macoma are a species of saltwater clams that are vulnerable to ocean acidification.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 12,
    speciesID: 66,
    biomass: 0.000352,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Blue Mussel",
    trophicLevel: 2.0,
    desc: "The Blue Mussel is a medium-sized, edible mussel. It is vulnerable to ocean acidification and pollution.",
    imgFile: "b-mussel.jpg",
    imgCaption: "Clusters of blue mussels in shallow water.",
  },
  {
    index: 13,
    speciesID: 67,
    biomass: 45.118247,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Mahogany Clam",
    trophicLevel: 3.448965094,
    desc: "California Mahogany Clams are a species of saltwater clams.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 14,
    speciesID: 68,
    biomass: 569.15303,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Pacific Littleneck Clam",
    trophicLevel: 2.0,
    desc: "The Pacific Littleneck Clam is one of many clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 15,
    speciesID: 70,
    biomass: 151.8597,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Butter Clam",
    trophicLevel: 3.448965094,
    desc: "Butter clams are a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "butt-clam.jpg",
    imgCaption: "Close up of a Butter Clam.",
  },
  {
    index: 16,
    speciesID: 71,
    biomass: 0.0005142,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Fat Jackknife Clam",
    trophicLevel: 3.569712185,
    desc: "The Fat Jackknife Clam is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption:
      "A different but similar species of jackknife clam in sand and seaweed.",
  },
  {
    index: 17,
    speciesID: 72,
    biomass: 257.61279,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Jackknife Clam",
    trophicLevel: 3.569712185,
    desc: "The California Jackknife Clam is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption:
      "A different but similar species of jackknife clam in sand and seaweed.",
  },
  {
    index: 18,
    speciesID: 73,
    biomass: 317.06629,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Lesser Tagelus",
    trophicLevel: 2.0,
    desc: "The lesser Tagelus is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption: "A different species of clam in sand and seaweed.",
  },
  {
    index: 19,
    speciesID: 75,
    biomass: 2.2624235,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Acteocina inculta",
    trophicLevel: 2.0,
    desc: "Acteocina inculta is a species of sea snail.",
    imgFile: "sea-snail.jpg",
    imgCaption: "A different but similar species of sea snail.",
  },
  {
    index: 20,
    speciesID: 76,
    biomass: 6.2561136,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Assiminea",
    trophicLevel: 1.0,
    desc: "California Assiminea is a species of small, salt-tolerant snails.",
    imgFile: "assiminea.jpg",
    imgCaption:
      "Close up of the shell of a snail species similar to California Assiminea.",
  },
  {
    index: 21,
    speciesID: 79,
    biomass: 143.83576,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Horn Snail",
    trophicLevel: 2.0,
    desc: "The California Horn Snail is a species of sea snail.",
    imgFile: "ca-horn.jpg",
    imgCaption: "Top and side view of three California Horn Snail shells.",
  },
  {
    index: 22,
    speciesID: 82,
    biomass: 85.015283,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Salt Marsh Snail",
    trophicLevel: 1.0,
    desc: "The Salt Marsh Snail, also known as the Marsh periwinkle is a species of sea snail.",
    imgFile: "snail.jpg",
    imgCaption: "A close up of a Salt Marsh Snail.",
  },
  {
    index: 23,
    speciesID: 111,
    biomass: 7.1600804,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Calfornia Ghost Shrimp",
    trophicLevel: 2.0,
    desc: "The California Ghost Shrimp, also known as the Bay Ghost Shrimp, is one of many species of ghost shrimp. ",
    imgFile: "ghost-shrimp.jpg",
    imgCaption: "A similar species of ghost shrimp.",
  },
  {
    index: 24,
    speciesID: 112,
    biomass: 8.277904,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Southern Mud Shrimp",
    trophicLevel: 3.622042017,
    desc: "The Southern Mud Shrimp is a species of crustacean that spends most of its time burrowed in mud and tidal flats.",
    imgFile: "mud-shrimp.jpg",
    imgCaption: "A similar species of mud shrimp.",
  },
  {
    index: 25,
    speciesID: 113,
    biomass: 0.1412562,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Green Shore Crab",
    trophicLevel: 3.650236168,
    desc: "The Green Shore Crab is one of the most common littoral crabs. This species is vulnerable to pollution.",
    imgFile: "g-crab.jpg",
    imgCaption: "A Green Shore Crab underwater in sand.",
  },
  {
    index: 26,
    speciesID: 114,
    biomass: 175.59909,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Striped Shore Crab",
    trophicLevel: 3.438808122,
    desc: "The Striped Shore Crab is a species of crab that lives in muddy areas. This species is likely vulnerable to pollution.",
    imgFile: "stripe-crab.jpg",
    imgCaption: "A Striped Shore Crab standing in a rocky sand patch.",
  },
  {
    index: 27,
    speciesID: 115,
    biomass: 0.2141502,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Fiddler Crab",
    trophicLevel: 2.0,
    desc: "Fiddler crabs are small crustacenas with a distinctive large claw. ",
    imgFile: "fiddler.jpg",
    imgCaption: "A fiddler crab standing above its burrow in rocky sand.",
  },
  {
    index: 28,
    speciesID: 118,
    biomass: 4.5752376,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Topsmelt",
    trophicLevel: 3.622042017,
    desc: "Topsmelt is a small fish species found in bays and estuaries, among other coastal ecosystems. This species is targeted by commercial fisheries but is considered to have a stable population size.",
    imgFile: "topsmelt.jpg",
    imgCaption: "Topsmelt Silverside on towel.",
  },
  {
    index: 29,
    speciesID: 120,
    biomass: 0.0449312,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Shiner Surfperch",
    trophicLevel: 3.800113283,
    desc: "Shiner surfperch are non-migratory fish that live in marine and brackish (slightly salty, occuring where salt and fresh water meet) waters. There are small commercial fisheries that target this species, and it is occasionally used as bait by recreational fishers. This species is vulnerable to habitat loss.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch swimming.",
  },
  {
    index: 30,
    speciesID: 125,
    biomass: 0.2618094,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Diamond Turbot",
    trophicLevel: 4.020495053,
    desc: "Diamond Turbot is a type of flatfish that occurs in coastal and brackish waters, living on muddy and sandy bottoms. They are not commercially targeted, but some recreational fishers may target this specices. It is not considered to be vulnerable. ",
    imgFile: "turbot.jpg",
    imgCaption:
      "Windowpane flounder laying on table. Similar species to Diamond turbot.",
  },
  {
    index: 31,
    speciesID: 132,
    biomass: 0.1536856,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Halibut",
    trophicLevel: 4.465792755,
    desc: "California Halibut is a large species of flatfish that lives on the Pacific Coast of the United States. This species is targeted by both commercial and recreational fisheries. It is considered to be vulnerable to the loss of critical nursery habitat.",
    imgFile: "halibut.jpg",
    imgCaption:
      "Three men standing next two many of the California Halibut that they caught.",
  },
  {
    index: 32,
    speciesID: 139,
    biomass: 0.0863925,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Leopard Shark",
    trophicLevel: 4.172837286,
    desc: "Leopard sharks are a small species of shark that lives in shallow waters in the Pacific Coast of the United States. It is targeted by both commercial and recreational fisheries, and is susceptible to overfishing.",
    imgFile: "leo-shark.jpg",
    imgCaption: "Leopard shark in sand.",
  },
  {
    index: 33,
    speciesID: 140,
    biomass: 0.0284862,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Haller's Round Ray",
    trophicLevel: 3.971768914,
    desc: "Haller's Round Ray is a species of stingray that lives in shallow coastal waters along the coast of California. Its range extends south to Ecuador. There a some fisheries that target this species but it is generally not considered vulnerable. ",
    imgFile: "Urobatis.jpg",
    imgCaption:
      "Yellow stingray, a species of stingray close to Haller's Round Ray.",
  },
  {
    index: 34,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 4.504473241,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 35,
    speciesID: 143,
    biomass: 0.0922059,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Coot",
    trophicLevel: 2.678994182,
    desc: "The American Coot is considered to be an adaptable waterbird with a range covering most of the United States, extending into Canada and Mexico. It has a low vulnerability status.",
    imgFile: "amer-coot.jpg",
    imgCaption: "American Coot getting ready to take flight from water.",
  },
  {
    index: 36,
    speciesID: 144,
    biomass: 0.0013822,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Crow",
    trophicLevel: 4.128969377,
    desc: "The American Crow is considered one of the most intelligent birds and is widespread across the United States. This species has a low vulnerability status.",
    imgFile: "crow.jpg",
    imgCaption: "American Crow standing on a branch.",
  },
  {
    index: 37,
    speciesID: 145,
    biomass: 0.0674677,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Wigeon",
    trophicLevel: 3.194499384,
    desc: "The American Wigeon is a species of duck. It is considered to be moderately vulnerable to climate change.",
    imgFile: "amer-wigeon.jpg",
    imgCaption: "American Wigeon sitting in water.",
  },
  {
    index: 38,
    speciesID: 146,
    biomass: 0.0063977,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-bellied Plover",
    trophicLevel: 3.913588836,
    desc: "The Black-bellied plover spends winter on the Pacific coast of the United States. In the summers, it lives in Northern Canada. This species is highly vulnerable to climate change. ",
    imgFile: "bb-plover.jpg",
    imgCaption: "Black-bellied Plover standing in field.",
  },
  {
    index: 39,
    speciesID: 147,
    biomass: 0.0300871,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-crowned Night Heron",
    trophicLevel: 4.566487118,
    desc: "The Black-crowned Night Heron can be found across the United States and is vulnerable to water pollution and habitat loss.",
    imgFile: "night-heron.jpg",
    imgCaption: "Black-crowned Night Heron standing on rock.",
  },
  {
    index: 40,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 4.724275132,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 41,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 4.042125579,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 42,
    speciesID: 154,
    biomass: 0.0915692,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bufflehead",
    trophicLevel: 3.695089703,
    desc: "The Bufflehead is a smaller duck species that occurs in North America. It is generally considered to have a stable population, but is vulnerable to habitat loss and highly vulnerable to climate change.",
    imgFile: "bufflehead.jpg",
    imgCaption: "Two Buffleheads sitting in water.",
  },
  {
    index: 43,
    speciesID: 155,
    biomass: 0.0516353,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Blue-winged Teal",
    trophicLevel: 3.194499384,
    desc: "The Blue-winged Teal is a small species of duck that spends its summers in Canada and the United States, migrating south towards South America in the winter. It is considered to have a low vulnerability status. ",
    imgFile: "bw-teal.jpg",
    imgCaption: "Blue-winged Teal sitting near water.",
  },
  {
    index: 44,
    speciesID: 157,
    biomass: 0.249866575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "California Gull",
    trophicLevel: 4.092735901,
    desc: "The California Gull is a gull species that occurs mainly on the Pacific coast of North America. It is moderately vulnerable to climate change.",
    imgFile: "ca-gull.jpg",
    imgCaption: "California Gull standing on a rock near water.",
  },
  {
    index: 45,
    speciesID: 158,
    biomass: 0.0036287,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Caspian Tern",
    trophicLevel: 4.724275132,
    desc: "The Caspian Tern is the largest of all tern species. These birds have a low vulnerability to climate change.",
    imgFile: "c-tern.jpg",
    imgCaption: "Two Caspian Terns standing in sand.",
  },
  {
    index: 46,
    speciesID: 159,
    biomass: 0.0149052,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cinnamon Teal",
    trophicLevel: 3.194499384,
    desc: "The Cinnamon Teal is a common species of duck that can be found only in the Western half of the United States, in addition to their range in Mexico. It is moderately vulnerable to climate change. ",
    imgFile: "cinn-teal.jpg",
    imgCaption: "Cinnamon Teal swimming in water.",
  },
  {
    index: 47,
    speciesID: 161,
    biomass: 0.002242,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cooper's Hawk",
    trophicLevel: 4.982313629,
    desc: "The Cooper's Hawk is a widespread species that is not considered to be vulnerable to climate change.",
    imgFile: "c-hawk.jpg",
    imgCaption: "Cooper's Hawk sitting on a tree branch.",
  },
  {
    index: 48,
    speciesID: 163,
    biomass: 0.0124805,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Double-crested Cormorant",
    trophicLevel: 4.931822641,
    desc: "The Double-crested Cormorant is a species of diving birds that is not considered to be vulnerable to climate change. However, it may be impacted by pollution.",
    imgFile: "cormorant.jpg",
    imgCaption: "Double-crested Cormorant sitting in water.",
  },
  {
    index: 49,
    speciesID: 164,
    biomass: 0.0090074,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Dunlin",
    trophicLevel: 3.949790523,
    desc: "The Dunlin is a migrating species that spends its winters on the Pacific coast of the United States. This species has seen considerable declines in their wintering seasons and is highly vulnerable to climate change.",
    imgFile: "dunlin.jpg",
    imgCaption: "Dunlin standing in water.",
  },
  {
    index: 50,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 4.931822641,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 51,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 4.931822641,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 52,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 4.724275132,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 53,
    speciesID: 172,
    biomass: 0.0396191,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Greater Yellowlegs",
    trophicLevel: 4.504473241,
    desc: "Greater Yellowlegs are a migrating, wading bird species. It is highly vulnerable to climate change, and there is not consensus on the stability of its population.",
    imgFile: "g-yellow.jpg",
    imgCaption: "Greater Yellowlegs standing in shallow water.",
  },
  {
    index: 54,
    speciesID: 173,
    biomass: 0.1386956,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green-winged Teal",
    trophicLevel: 3.194499384,
    desc: "This small duck species is common and widespread. The Green-winged Teal is considered to have low vulnerability to climate change.",
    imgFile: "gw-teal.jpg",
    imgCaption: "Green-winged Teal swimming in water.",
  },
  {
    index: 55,
    speciesID: 174,
    biomass: 0.0062451,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Killdeer",
    trophicLevel: 4.583498151,
    desc: "The Killdeer is a common species of bird, and is not considered to be vulnerable to climate change. ",
    imgFile: "killdeer.jpg",
    imgCaption: "Killdeer sitting in gravel.",
  },
  {
    index: 56,
    speciesID: 175,
    biomass: 0.0835354,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Curlew",
    trophicLevel: 3.933450611,
    desc: "The Long-billed Curlew is one of the largest shorebirds, migrating across the Western United States and Mexico. It is vulnerable to habitat loss, as much of their breeding habitat has been converted to agriculture. They are also highly vulnerable to climate change.",
    imgFile: "lb-curlew.jpg",
    imgCaption: "Long-billed Curlew taking flight from water.",
  },
  {
    index: 57,
    speciesID: 176,
    biomass: 0.0553906,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Dowitcher",
    trophicLevel: 3.788496987,
    desc: "The Long-billed Dowitcher is a similar species to the Long-billed Curlew. While they overlap in range, the Long-billed Dowitcher prefers fresh water to salt water. This species is highly vulnerable to climate change.",
    imgFile: "lb-dowitcher.jpg",
    imgCaption: "Long-billed Dowitcher standing in shallow water.",
  },
  {
    index: 58,
    speciesID: 177,
    biomass: 0.0035291,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Little Blue Heron",
    trophicLevel: 4.701538036,
    desc: "The Little Blue Heron is a migrating bird that is not considered to be vulnerable to climate change.",
    imgFile: "lb-heron.jpg",
    imgCaption: "Little Blue Heron standing in marsh water.",
  },
  {
    index: 59,
    speciesID: 178,
    biomass: 0.0356641,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Least Sandpiper",
    trophicLevel: 4.218437382,
    desc: 'The Least Sandpiper is a migrating species. This species relies less on "stopover" sites than other migrating birds, and is considered to be moderately vulnerable to climate change.',
    imgFile: "least-sand.jpg",
    imgCaption: "Least Sandpiper standing at water's edge.",
  },
  {
    index: 60,
    speciesID: 181,
    biomass: 0.1057894,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Marbled Godwit",
    trophicLevel: 3.815569686,
    desc: "The Marbled Godwit is a migrating bird that is declining due to habitat loss in their summer habitat. They are moderately vulnerable to climate change.",
    imgFile: "m-godwit.jpg",
    imgCaption: "Marbled Godwit standing in waves.",
  },
  {
    index: 61,
    speciesID: 182,
    biomass: 0.4704502,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mallard",
    trophicLevel: 2.680583025,
    desc: "Mallards are one of the most abundant ducks in the world. They migrate across North America and are considered to have a low vulnerability to climate change.",
    imgFile: "mallard.jpg",
    imgCaption: "Three female and two male Mallards standing in shallow water.",
  },
  {
    index: 62,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 4.042125579,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 63,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 4.434838406,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 64,
    speciesID: 189,
    biomass: 0.0064582,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ring-billed Gull",
    trophicLevel: 4.042125579,
    desc: "The Ring-billed Gull is one of the most common gulls in North America, and has benefited greatly from the aavailability of food provided by humans. It is considered to have a low vulnerability status to climate change. ",
    imgFile: "rb-gull.jpg",
    imgCaption: "Ring-billed Gull standing in shallow water.",
  },
  {
    index: 65,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 4.931822641,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 66,
    speciesID: 194,
    biomass: 0.0019375,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ruddy Duck",
    trophicLevel: 2.997584854,
    desc: "Ruddy Ducks are a species of stiff-tailed ducks in North America. It is vulnerable to habitat loss, and mildly vulnerable to climate change.",
    imgFile: "ruddy.jpg",
    imgCaption: "Ruddy Dyck taking flight from water. ",
  },
  {
    index: 67,
    speciesID: 197,
    biomass: 0.0070175,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Semipalmated Plover",
    trophicLevel: 4.433030611,
    desc: "While the Semipalmated is widespread and common in coastal ecosystems across the United States, this species is considered to be highly vulnerable to climate change.",
    imgFile: "semi-plover.jpg",
    imgCaption: "Juvenile Semipalmated Plover walking in sand. ",
  },
  {
    index: 68,
    speciesID: 198,
    biomass: 0.0758358,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Snowy Egret",
    trophicLevel: 4.701538036,
    desc: "The Snowy Egret is a small species of egret that is found mostly in the Southern United States and Mexico. It is continuously recovering from historic population decreases and is not considered to be vulnerable to climate change.",
    imgFile: "snow-egret.jpg",
    imgCaption: "Snowy Egret standing on rocks near rushing water.",
  },
  {
    index: 69,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 3.979487691,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 70,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 3.811625442,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 71,
    speciesID: 204,
    biomass: 0.0470025,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Turkey Vulture",
    trophicLevel: 1.0,
    desc: "Turkey Vultures are widespread across North America and are not considered to be vulnerable to climate change.",
    imgFile: "t-vulture.jpg",
    imgCaption: "Turkey Vulture flying.",
  },
  {
    index: 72,
    speciesID: 205,
    biomass: 0.0218222,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Grebe",
    trophicLevel: 4.931822641,
    desc: "The Western Grebe is a species of aquatic birds that migrates between fresh water lakes and brackish coastal waters in North America. While this species used to be targeted for its feathers, it is currently only vulnerable to climate change.",
    imgFile: "w-grebe.jpg",
    imgCaption: "Two Western Grebes swimming.",
  },
  {
    index: 73,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 3.781538922,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 74,
    speciesID: 208,
    biomass: 0.0192895,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Whimbrel",
    trophicLevel: 3.687854171,
    desc: "Whimbrels are a shorebird that occurs in North America. They are highly vulnerable to climate change, and are still recovering from historically depleted populations.",
    imgFile: "whimbrel.jpg",
    imgCaption: "Whimbrel standing in shallow water.",
  },
  {
    index: 75,
    speciesID: 209,
    biomass: 0.7094073,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Willet",
    trophicLevel: 3.923264946,
    desc: "Willets are large shorebirds that occur in prairie and salt marshes in North America. They are vulnerable to habitat disturbance and loss.",
    imgFile: "willet.jpg",
    imgCaption: "Willet flying over water.",
  },
  {
    index: 76,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 4.250548529,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 77,
    speciesID: 300,
    biomass: 215.27843,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Other Plants",
    trophicLevel: 2.0,
    desc: "Plants are non-animal organisms that use photosynthesis to make their own food. They produce a large amount of the world's oxygen during photosynthesis. Plants play a large role in supporting food chains.",
    imgFile: "grasses.jpg",
    imgCaption: "Grasses.",
  },
  {
    index: 78,
    speciesID: 400,
    biomass: 0,
    organismType: "Microscopic Organism",
    nodeColor: "Black-1",
    nodeShape: "Circle",
    nodeName: "Microscopic Organisms",
    trophicLevel: 3.350226566,
    desc: "Microscopic organisms, or microbes, are tiny, single-celled organisms that cannot be seen with the naked eye. Microbes play an important role in nutrient cycling and there are more than 1 trillion different species, many of which have not yet been discovered. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 79,
    speciesID: 550,
    biomass: 49779.82555,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Carbon Storage",
    trophicLevel: 3.55817684,
    desc: "Carbon storage (also known as carbon sequestration) is the process of capturing and storing carbon. Carbon can be stored in plants, soils and other organic materials.",
    imgFile: "carbon.jpg",
    imgCaption: "Salt marsh with vehicle in the background.",
  },
  {
    index: 80,
    speciesID: 600,
    biomass: 18.03491695,
    organismType: "Insect",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Insects",
    trophicLevel: 4.916996303,
    desc: "Insects are invertebrate animals, meaning they do not have backbones. Insects are a class of arthropods with a hard external skeleton (known as the exoskeleton), a segmented body, and at least 3 pairs of legs. Some insects include ants, butterflies, mosquitoes and flies.",
    imgFile: "insect.jpg",
    imgCaption: "Dragonfly on branch.",
  },
  {
    index: 81,
    speciesID: 700,
    biomass: 11.78583508,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Other Plankton",
    trophicLevel: 2.25,
    desc: "Plankton are a diverse colletion of microscopic plants and animals that drift around in aquatic ecosystems. Phytoplankton is are tiny plants and Zooplankton are tiny animals that eat phytoplankton. Plankton form the base of aquatic food webs.",
    imgFile: "plankton.jpg",
    imgCaption: "Microscopic image of plankton.",
  },
  {
    index: 82,
    speciesID: 750,
    biomass: 0.9714345,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Commercial Fishery",
    trophicLevel: 5.005525378,
    desc: "Commercial fisheries are large-scale harvesting operations that target fish and other seafood for commercial profit. These fisheries do not occur in salt marshes. However, salt marshes and other coastal ecosystems like mangroves provide importance habitat and breeding grounds for many commercially important species.",
    imgFile: "comm-fish.jpg",
    imgCaption: "Commercial fishing boat in a harbor.",
  },
  {
    index: 83,
    speciesID: 900,
    biomass: 13.9641979,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Other Fish",
    trophicLevel: 3.750670096,
    desc: "Fish are gill-bearing organisms that live in aquatic ecosystems. There are more than 28,000 species, some of which are of critical importance to fisheries and wildlife viewing. They vary in their vulnerability to disturbances, but can be impacted greatly by overfishing and warming waters.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch, one species of fish.",
  },
];
export const edgeList6 = [
  { target: 1, source: 78, Type: "Feeding" },
  { target: 7, source: 78, Type: "Feeding" },
  { target: 83, source: 78, Type: "Feeding" },
  { target: 25, source: 78, Type: "Feeding" },
  { target: 7, source: 9, Type: "Feeding" },
  { target: 1, source: 10, Type: "Feeding" },
  { target: 7, source: 10, Type: "Feeding" },
  { target: 1, source: 11, Type: "Feeding" },
  { target: 7, source: 11, Type: "Feeding" },
  { target: 78, source: 11, Type: "Feeding" },
  { target: 9, source: 11, Type: "Feeding" },
  { target: 10, source: 11, Type: "Feeding" },
  { target: 12, source: 11, Type: "Feeding" },
  { target: 13, source: 11, Type: "Feeding" },
  { target: 14, source: 11, Type: "Feeding" },
  { target: 15, source: 11, Type: "Feeding" },
  { target: 16, source: 11, Type: "Feeding" },
  { target: 17, source: 11, Type: "Feeding" },
  { target: 18, source: 11, Type: "Feeding" },
  { target: 7, source: 12, Type: "Feeding" },
  { target: 1, source: 13, Type: "Feeding" },
  { target: 7, source: 13, Type: "Feeding" },
  { target: 78, source: 13, Type: "Feeding" },
  { target: 9, source: 13, Type: "Feeding" },
  { target: 10, source: 13, Type: "Feeding" },
  { target: 11, source: 13, Type: "Feeding" },
  { target: 12, source: 13, Type: "Feeding" },
  { target: 14, source: 13, Type: "Feeding" },
  { target: 15, source: 13, Type: "Feeding" },
  { target: 16, source: 13, Type: "Feeding" },
  { target: 17, source: 13, Type: "Feeding" },
  { target: 18, source: 13, Type: "Feeding" },
  { target: 1, source: 14, Type: "Feeding" },
  { target: 7, source: 14, Type: "Feeding" },
  { target: 1, source: 15, Type: "Feeding" },
  { target: 7, source: 15, Type: "Feeding" },
  { target: 78, source: 15, Type: "Feeding" },
  { target: 9, source: 15, Type: "Feeding" },
  { target: 10, source: 15, Type: "Feeding" },
  { target: 11, source: 15, Type: "Feeding" },
  { target: 12, source: 15, Type: "Feeding" },
  { target: 13, source: 15, Type: "Feeding" },
  { target: 14, source: 15, Type: "Feeding" },
  { target: 16, source: 15, Type: "Feeding" },
  { target: 17, source: 15, Type: "Feeding" },
  { target: 18, source: 15, Type: "Feeding" },
  { target: 7, source: 16, Type: "Feeding" },
  { target: 78, source: 16, Type: "Feeding" },
  { target: 9, source: 16, Type: "Feeding" },
  { target: 10, source: 16, Type: "Feeding" },
  { target: 11, source: 16, Type: "Feeding" },
  { target: 12, source: 16, Type: "Feeding" },
  { target: 13, source: 16, Type: "Feeding" },
  { target: 14, source: 16, Type: "Feeding" },
  { target: 15, source: 16, Type: "Feeding" },
  { target: 17, source: 16, Type: "Feeding" },
  { target: 18, source: 16, Type: "Feeding" },
  { target: 7, source: 17, Type: "Feeding" },
  { target: 78, source: 17, Type: "Feeding" },
  { target: 9, source: 17, Type: "Feeding" },
  { target: 10, source: 17, Type: "Feeding" },
  { target: 11, source: 17, Type: "Feeding" },
  { target: 12, source: 17, Type: "Feeding" },
  { target: 13, source: 17, Type: "Feeding" },
  { target: 14, source: 17, Type: "Feeding" },
  { target: 15, source: 17, Type: "Feeding" },
  { target: 16, source: 17, Type: "Feeding" },
  { target: 18, source: 17, Type: "Feeding" },
  { target: 7, source: 18, Type: "Feeding" },
  { target: 1, source: 19, Type: "Feeding" },
  { target: 1, source: 21, Type: "Feeding" },
  { target: 34, source: 80, Type: "Feeding" },
  { target: 35, source: 80, Type: "Feeding" },
  { target: 36, source: 80, Type: "Feeding" },
  { target: 37, source: 80, Type: "Feeding" },
  { target: 38, source: 80, Type: "Feeding" },
  { target: 39, source: 80, Type: "Feeding" },
  { target: 40, source: 80, Type: "Feeding" },
  { target: 41, source: 80, Type: "Feeding" },
  { target: 42, source: 80, Type: "Feeding" },
  { target: 43, source: 80, Type: "Feeding" },
  { target: 44, source: 80, Type: "Feeding" },
  { target: 45, source: 80, Type: "Feeding" },
  { target: 46, source: 80, Type: "Feeding" },
  { target: 47, source: 80, Type: "Feeding" },
  { target: 48, source: 80, Type: "Feeding" },
  { target: 49, source: 80, Type: "Feeding" },
  { target: 50, source: 80, Type: "Feeding" },
  { target: 51, source: 80, Type: "Feeding" },
  { target: 52, source: 80, Type: "Feeding" },
  { target: 53, source: 80, Type: "Feeding" },
  { target: 54, source: 80, Type: "Feeding" },
  { target: 55, source: 80, Type: "Feeding" },
  { target: 56, source: 80, Type: "Feeding" },
  { target: 57, source: 80, Type: "Feeding" },
  { target: 58, source: 80, Type: "Feeding" },
  { target: 59, source: 80, Type: "Feeding" },
  { target: 60, source: 80, Type: "Feeding" },
  { target: 61, source: 80, Type: "Feeding" },
  { target: 62, source: 80, Type: "Feeding" },
  { target: 63, source: 80, Type: "Feeding" },
  { target: 64, source: 80, Type: "Feeding" },
  { target: 65, source: 80, Type: "Feeding" },
  { target: 66, source: 80, Type: "Feeding" },
  { target: 67, source: 80, Type: "Feeding" },
  { target: 68, source: 80, Type: "Feeding" },
  { target: 69, source: 80, Type: "Feeding" },
  { target: 70, source: 80, Type: "Feeding" },
  { target: 71, source: 80, Type: "Feeding" },
  { target: 72, source: 80, Type: "Feeding" },
  { target: 73, source: 80, Type: "Feeding" },
  { target: 74, source: 80, Type: "Feeding" },
  { target: 75, source: 80, Type: "Feeding" },
  { target: 76, source: 80, Type: "Feeding" },
  { target: 28, source: 80, Type: "Feeding" },
  { target: 83, source: 80, Type: "Feeding" },
  { target: 1, source: 80, Type: "Feeding" },
  { target: 1, source: 81, Type: "Feeding" },
  { target: 5, source: 81, Type: "Feeding" },
  { target: 7, source: 81, Type: "Feeding" },
  { target: 81, source: 80, Type: "Feeding" },
  { target: 7, source: 23, Type: "Feeding" },
  { target: 7, source: 24, Type: "Feeding" },
  { target: 78, source: 24, Type: "Feeding" },
  { target: 9, source: 24, Type: "Feeding" },
  { target: 10, source: 24, Type: "Feeding" },
  { target: 11, source: 24, Type: "Feeding" },
  { target: 12, source: 24, Type: "Feeding" },
  { target: 13, source: 24, Type: "Feeding" },
  { target: 14, source: 24, Type: "Feeding" },
  { target: 15, source: 24, Type: "Feeding" },
  { target: 16, source: 24, Type: "Feeding" },
  { target: 17, source: 24, Type: "Feeding" },
  { target: 18, source: 24, Type: "Feeding" },
  { target: 81, source: 24, Type: "Feeding" },
  { target: 1, source: 25, Type: "Feeding" },
  { target: 5, source: 25, Type: "Feeding" },
  { target: 78, source: 25, Type: "Feeding" },
  { target: 9, source: 25, Type: "Feeding" },
  { target: 10, source: 25, Type: "Feeding" },
  { target: 11, source: 25, Type: "Feeding" },
  { target: 12, source: 25, Type: "Feeding" },
  { target: 13, source: 25, Type: "Feeding" },
  { target: 14, source: 25, Type: "Feeding" },
  { target: 15, source: 25, Type: "Feeding" },
  { target: 16, source: 25, Type: "Feeding" },
  { target: 17, source: 25, Type: "Feeding" },
  { target: 18, source: 25, Type: "Feeding" },
  { target: 19, source: 25, Type: "Feeding" },
  { target: 80, source: 25, Type: "Feeding" },
  { target: 1, source: 26, Type: "Feeding" },
  { target: 5, source: 26, Type: "Feeding" },
  { target: 8, source: 26, Type: "Feeding" },
  { target: 78, source: 26, Type: "Feeding" },
  { target: 9, source: 26, Type: "Feeding" },
  { target: 10, source: 26, Type: "Feeding" },
  { target: 11, source: 26, Type: "Feeding" },
  { target: 12, source: 26, Type: "Feeding" },
  { target: 13, source: 26, Type: "Feeding" },
  { target: 14, source: 26, Type: "Feeding" },
  { target: 15, source: 26, Type: "Feeding" },
  { target: 16, source: 26, Type: "Feeding" },
  { target: 17, source: 26, Type: "Feeding" },
  { target: 18, source: 26, Type: "Feeding" },
  { target: 19, source: 26, Type: "Feeding" },
  { target: 21, source: 26, Type: "Feeding" },
  { target: 22, source: 26, Type: "Feeding" },
  { target: 81, source: 26, Type: "Feeding" },
  { target: 80, source: 26, Type: "Feeding" },
  { target: 25, source: 26, Type: "Feeding" },
  { target: 27, source: 26, Type: "Feeding" },
  { target: 77, source: 26, Type: "Feeding" },
  { target: 1, source: 27, Type: "Feeding" },
  { target: 5, source: 27, Type: "Feeding" },
  { target: 78, source: 83, Type: "Feeding" },
  { target: 81, source: 83, Type: "Feeding" },
  { target: 25, source: 83, Type: "Feeding" },
  { target: 26, source: 83, Type: "Feeding" },
  { target: 5, source: 28, Type: "Feeding" },
  { target: 78, source: 28, Type: "Feeding" },
  { target: 9, source: 28, Type: "Feeding" },
  { target: 10, source: 28, Type: "Feeding" },
  { target: 11, source: 28, Type: "Feeding" },
  { target: 12, source: 28, Type: "Feeding" },
  { target: 13, source: 28, Type: "Feeding" },
  { target: 14, source: 28, Type: "Feeding" },
  { target: 15, source: 28, Type: "Feeding" },
  { target: 16, source: 28, Type: "Feeding" },
  { target: 17, source: 28, Type: "Feeding" },
  { target: 18, source: 28, Type: "Feeding" },
  { target: 81, source: 28, Type: "Feeding" },
  { target: 9, source: 83, Type: "Feeding" },
  { target: 10, source: 83, Type: "Feeding" },
  { target: 11, source: 83, Type: "Feeding" },
  { target: 12, source: 83, Type: "Feeding" },
  { target: 13, source: 83, Type: "Feeding" },
  { target: 14, source: 83, Type: "Feeding" },
  { target: 15, source: 83, Type: "Feeding" },
  { target: 16, source: 83, Type: "Feeding" },
  { target: 17, source: 83, Type: "Feeding" },
  { target: 18, source: 83, Type: "Feeding" },
  { target: 80, source: 83, Type: "Feeding" },
  { target: 78, source: 29, Type: "Feeding" },
  { target: 81, source: 29, Type: "Feeding" },
  { target: 5, source: 83, Type: "Feeding" },
  { target: 19, source: 83, Type: "Feeding" },
  { target: 20, source: 83, Type: "Feeding" },
  { target: 23, source: 83, Type: "Feeding" },
  { target: 24, source: 83, Type: "Feeding" },
  { target: 27, source: 83, Type: "Feeding" },
  { target: 29, source: 83, Type: "Feeding" },
  { target: 78, source: 30, Type: "Feeding" },
  { target: 10, source: 30, Type: "Feeding" },
  { target: 11, source: 30, Type: "Feeding" },
  { target: 13, source: 30, Type: "Feeding" },
  { target: 14, source: 30, Type: "Feeding" },
  { target: 15, source: 30, Type: "Feeding" },
  { target: 16, source: 30, Type: "Feeding" },
  { target: 17, source: 30, Type: "Feeding" },
  { target: 18, source: 30, Type: "Feeding" },
  { target: 81, source: 30, Type: "Feeding" },
  { target: 23, source: 30, Type: "Feeding" },
  { target: 24, source: 30, Type: "Feeding" },
  { target: 25, source: 30, Type: "Feeding" },
  { target: 26, source: 30, Type: "Feeding" },
  { target: 27, source: 30, Type: "Feeding" },
  { target: 83, source: 30, Type: "Feeding" },
  { target: 29, source: 30, Type: "Feeding" },
  { target: 78, source: 31, Type: "Feeding" },
  { target: 81, source: 31, Type: "Feeding" },
  { target: 25, source: 31, Type: "Feeding" },
  { target: 26, source: 31, Type: "Feeding" },
  { target: 83, source: 31, Type: "Feeding" },
  { target: 29, source: 31, Type: "Feeding" },
  { target: 30, source: 31, Type: "Feeding" },
  { target: 78, source: 32, Type: "Feeding" },
  { target: 10, source: 32, Type: "Feeding" },
  { target: 11, source: 32, Type: "Feeding" },
  { target: 13, source: 32, Type: "Feeding" },
  { target: 14, source: 32, Type: "Feeding" },
  { target: 15, source: 32, Type: "Feeding" },
  { target: 16, source: 32, Type: "Feeding" },
  { target: 17, source: 32, Type: "Feeding" },
  { target: 18, source: 32, Type: "Feeding" },
  { target: 23, source: 32, Type: "Feeding" },
  { target: 24, source: 32, Type: "Feeding" },
  { target: 25, source: 32, Type: "Feeding" },
  { target: 26, source: 32, Type: "Feeding" },
  { target: 27, source: 32, Type: "Feeding" },
  { target: 28, source: 32, Type: "Feeding" },
  { target: 83, source: 32, Type: "Feeding" },
  { target: 29, source: 32, Type: "Feeding" },
  { target: 30, source: 32, Type: "Feeding" },
  { target: 31, source: 32, Type: "Feeding" },
  { target: 81, source: 32, Type: "Feeding" },
  { target: 78, source: 33, Type: "Feeding" },
  { target: 10, source: 33, Type: "Feeding" },
  { target: 11, source: 33, Type: "Feeding" },
  { target: 13, source: 33, Type: "Feeding" },
  { target: 14, source: 33, Type: "Feeding" },
  { target: 15, source: 33, Type: "Feeding" },
  { target: 16, source: 33, Type: "Feeding" },
  { target: 17, source: 33, Type: "Feeding" },
  { target: 18, source: 33, Type: "Feeding" },
  { target: 23, source: 33, Type: "Feeding" },
  { target: 24, source: 33, Type: "Feeding" },
  { target: 25, source: 33, Type: "Feeding" },
  { target: 26, source: 33, Type: "Feeding" },
  { target: 27, source: 33, Type: "Feeding" },
  { target: 83, source: 33, Type: "Feeding" },
  { target: 81, source: 33, Type: "Feeding" },
  { target: 78, source: 34, Type: "Feeding" },
  { target: 21, source: 34, Type: "Feeding" },
  { target: 80, source: 34, Type: "Feeding" },
  { target: 83, source: 34, Type: "Feeding" },
  { target: 0, source: 35, Type: "Feeding" },
  { target: 2, source: 35, Type: "Feeding" },
  { target: 3, source: 35, Type: "Feeding" },
  { target: 4, source: 35, Type: "Feeding" },
  { target: 5, source: 35, Type: "Feeding" },
  { target: 6, source: 35, Type: "Feeding" },
  { target: 8, source: 35, Type: "Feeding" },
  { target: 10, source: 35, Type: "Feeding" },
  { target: 11, source: 35, Type: "Feeding" },
  { target: 13, source: 35, Type: "Feeding" },
  { target: 81, source: 35, Type: "Feeding" },
  { target: 77, source: 35, Type: "Feeding" },
  { target: 78, source: 36, Type: "Feeding" },
  { target: 10, source: 36, Type: "Feeding" },
  { target: 11, source: 36, Type: "Feeding" },
  { target: 13, source: 36, Type: "Feeding" },
  { target: 14, source: 36, Type: "Feeding" },
  { target: 15, source: 36, Type: "Feeding" },
  { target: 16, source: 36, Type: "Feeding" },
  { target: 17, source: 36, Type: "Feeding" },
  { target: 18, source: 36, Type: "Feeding" },
  { target: 25, source: 36, Type: "Feeding" },
  { target: 26, source: 36, Type: "Feeding" },
  { target: 28, source: 36, Type: "Feeding" },
  { target: 7, source: 37, Type: "Feeding" },
  { target: 8, source: 37, Type: "Feeding" },
  { target: 19, source: 37, Type: "Feeding" },
  { target: 81, source: 37, Type: "Feeding" },
  { target: 80, source: 37, Type: "Feeding" },
  { target: 77, source: 37, Type: "Feeding" },
  { target: 78, source: 38, Type: "Feeding" },
  { target: 9, source: 38, Type: "Feeding" },
  { target: 10, source: 38, Type: "Feeding" },
  { target: 11, source: 38, Type: "Feeding" },
  { target: 12, source: 38, Type: "Feeding" },
  { target: 13, source: 38, Type: "Feeding" },
  { target: 14, source: 38, Type: "Feeding" },
  { target: 15, source: 38, Type: "Feeding" },
  { target: 21, source: 38, Type: "Feeding" },
  { target: 80, source: 38, Type: "Feeding" },
  { target: 81, source: 38, Type: "Feeding" },
  { target: 25, source: 38, Type: "Feeding" },
  { target: 26, source: 38, Type: "Feeding" },
  { target: 27, source: 38, Type: "Feeding" },
  { target: 83, source: 38, Type: "Feeding" },
  { target: 78, source: 39, Type: "Feeding" },
  { target: 25, source: 39, Type: "Feeding" },
  { target: 26, source: 39, Type: "Feeding" },
  { target: 27, source: 39, Type: "Feeding" },
  { target: 83, source: 39, Type: "Feeding" },
  { target: 28, source: 39, Type: "Feeding" },
  { target: 29, source: 39, Type: "Feeding" },
  { target: 30, source: 39, Type: "Feeding" },
  { target: 31, source: 39, Type: "Feeding" },
  { target: 28, source: 40, Type: "Feeding" },
  { target: 29, source: 40, Type: "Feeding" },
  { target: 83, source: 40, Type: "Feeding" },
  { target: 78, source: 41, Type: "Feeding" },
  { target: 10, source: 41, Type: "Feeding" },
  { target: 11, source: 41, Type: "Feeding" },
  { target: 12, source: 41, Type: "Feeding" },
  { target: 13, source: 41, Type: "Feeding" },
  { target: 14, source: 41, Type: "Feeding" },
  { target: 15, source: 41, Type: "Feeding" },
  { target: 16, source: 41, Type: "Feeding" },
  { target: 17, source: 41, Type: "Feeding" },
  { target: 18, source: 41, Type: "Feeding" },
  { target: 25, source: 41, Type: "Feeding" },
  { target: 26, source: 41, Type: "Feeding" },
  { target: 28, source: 41, Type: "Feeding" },
  { target: 78, source: 42, Type: "Feeding" },
  { target: 10, source: 42, Type: "Feeding" },
  { target: 11, source: 42, Type: "Feeding" },
  { target: 12, source: 42, Type: "Feeding" },
  { target: 13, source: 42, Type: "Feeding" },
  { target: 14, source: 42, Type: "Feeding" },
  { target: 15, source: 42, Type: "Feeding" },
  { target: 19, source: 42, Type: "Feeding" },
  { target: 21, source: 42, Type: "Feeding" },
  { target: 81, source: 42, Type: "Feeding" },
  { target: 25, source: 42, Type: "Feeding" },
  { target: 26, source: 42, Type: "Feeding" },
  { target: 27, source: 42, Type: "Feeding" },
  { target: 7, source: 43, Type: "Feeding" },
  { target: 8, source: 43, Type: "Feeding" },
  { target: 19, source: 43, Type: "Feeding" },
  { target: 81, source: 43, Type: "Feeding" },
  { target: 80, source: 43, Type: "Feeding" },
  { target: 77, source: 43, Type: "Feeding" },
  { target: 78, source: 44, Type: "Feeding" },
  { target: 10, source: 44, Type: "Feeding" },
  { target: 11, source: 44, Type: "Feeding" },
  { target: 12, source: 44, Type: "Feeding" },
  { target: 13, source: 44, Type: "Feeding" },
  { target: 14, source: 44, Type: "Feeding" },
  { target: 15, source: 44, Type: "Feeding" },
  { target: 16, source: 44, Type: "Feeding" },
  { target: 17, source: 44, Type: "Feeding" },
  { target: 18, source: 44, Type: "Feeding" },
  { target: 25, source: 44, Type: "Feeding" },
  { target: 26, source: 44, Type: "Feeding" },
  { target: 28, source: 44, Type: "Feeding" },
  { target: 83, source: 44, Type: "Feeding" },
  { target: 28, source: 45, Type: "Feeding" },
  { target: 83, source: 45, Type: "Feeding" },
  { target: 29, source: 45, Type: "Feeding" },
  { target: 7, source: 46, Type: "Feeding" },
  { target: 8, source: 46, Type: "Feeding" },
  { target: 19, source: 46, Type: "Feeding" },
  { target: 81, source: 46, Type: "Feeding" },
  { target: 80, source: 46, Type: "Feeding" },
  { target: 77, source: 46, Type: "Feeding" },
  { target: 49, source: 47, Type: "Feeding" },
  { target: 59, source: 47, Type: "Feeding" },
  { target: 69, source: 47, Type: "Feeding" },
  { target: 73, source: 47, Type: "Feeding" },
  { target: 83, source: 48, Type: "Feeding" },
  { target: 28, source: 48, Type: "Feeding" },
  { target: 29, source: 48, Type: "Feeding" },
  { target: 30, source: 48, Type: "Feeding" },
  { target: 31, source: 48, Type: "Feeding" },
  { target: 78, source: 49, Type: "Feeding" },
  { target: 10, source: 49, Type: "Feeding" },
  { target: 11, source: 49, Type: "Feeding" },
  { target: 13, source: 49, Type: "Feeding" },
  { target: 14, source: 49, Type: "Feeding" },
  { target: 15, source: 49, Type: "Feeding" },
  { target: 21, source: 49, Type: "Feeding" },
  { target: 81, source: 49, Type: "Feeding" },
  { target: 29, source: 49, Type: "Feeding" },
  { target: 83, source: 49, Type: "Feeding" },
  { target: 83, source: 50, Type: "Feeding" },
  { target: 28, source: 50, Type: "Feeding" },
  { target: 29, source: 50, Type: "Feeding" },
  { target: 30, source: 50, Type: "Feeding" },
  { target: 31, source: 50, Type: "Feeding" },
  { target: 83, source: 51, Type: "Feeding" },
  { target: 28, source: 51, Type: "Feeding" },
  { target: 29, source: 51, Type: "Feeding" },
  { target: 30, source: 51, Type: "Feeding" },
  { target: 31, source: 51, Type: "Feeding" },
  { target: 28, source: 52, Type: "Feeding" },
  { target: 83, source: 52, Type: "Feeding" },
  { target: 29, source: 52, Type: "Feeding" },
  { target: 78, source: 53, Type: "Feeding" },
  { target: 21, source: 53, Type: "Feeding" },
  { target: 80, source: 53, Type: "Feeding" },
  { target: 83, source: 53, Type: "Feeding" },
  { target: 7, source: 54, Type: "Feeding" },
  { target: 8, source: 54, Type: "Feeding" },
  { target: 19, source: 54, Type: "Feeding" },
  { target: 81, source: 54, Type: "Feeding" },
  { target: 80, source: 54, Type: "Feeding" },
  { target: 77, source: 54, Type: "Feeding" },
  { target: 80, source: 55, Type: "Feeding" },
  { target: 81, source: 55, Type: "Feeding" },
  { target: 78, source: 56, Type: "Feeding" },
  { target: 9, source: 56, Type: "Feeding" },
  { target: 16, source: 56, Type: "Feeding" },
  { target: 17, source: 56, Type: "Feeding" },
  { target: 18, source: 56, Type: "Feeding" },
  { target: 23, source: 56, Type: "Feeding" },
  { target: 24, source: 56, Type: "Feeding" },
  { target: 25, source: 56, Type: "Feeding" },
  { target: 26, source: 56, Type: "Feeding" },
  { target: 27, source: 56, Type: "Feeding" },
  { target: 83, source: 56, Type: "Feeding" },
  { target: 81, source: 56, Type: "Feeding" },
  { target: 78, source: 57, Type: "Feeding" },
  { target: 10, source: 57, Type: "Feeding" },
  { target: 11, source: 57, Type: "Feeding" },
  { target: 13, source: 57, Type: "Feeding" },
  { target: 14, source: 57, Type: "Feeding" },
  { target: 15, source: 57, Type: "Feeding" },
  { target: 16, source: 57, Type: "Feeding" },
  { target: 17, source: 57, Type: "Feeding" },
  { target: 18, source: 57, Type: "Feeding" },
  { target: 19, source: 57, Type: "Feeding" },
  { target: 20, source: 57, Type: "Feeding" },
  { target: 21, source: 57, Type: "Feeding" },
  { target: 22, source: 57, Type: "Feeding" },
  { target: 81, source: 57, Type: "Feeding" },
  { target: 80, source: 57, Type: "Feeding" },
  { target: 25, source: 57, Type: "Feeding" },
  { target: 83, source: 57, Type: "Feeding" },
  { target: 78, source: 58, Type: "Feeding" },
  { target: 80, source: 58, Type: "Feeding" },
  { target: 25, source: 58, Type: "Feeding" },
  { target: 26, source: 58, Type: "Feeding" },
  { target: 27, source: 58, Type: "Feeding" },
  { target: 83, source: 58, Type: "Feeding" },
  { target: 28, source: 58, Type: "Feeding" },
  { target: 29, source: 58, Type: "Feeding" },
  { target: 30, source: 58, Type: "Feeding" },
  { target: 31, source: 58, Type: "Feeding" },
  { target: 78, source: 59, Type: "Feeding" },
  { target: 10, source: 59, Type: "Feeding" },
  { target: 11, source: 59, Type: "Feeding" },
  { target: 13, source: 59, Type: "Feeding" },
  { target: 21, source: 59, Type: "Feeding" },
  { target: 80, source: 59, Type: "Feeding" },
  { target: 81, source: 59, Type: "Feeding" },
  { target: 29, source: 59, Type: "Feeding" },
  { target: 83, source: 59, Type: "Feeding" },
  { target: 78, source: 60, Type: "Feeding" },
  { target: 10, source: 60, Type: "Feeding" },
  { target: 11, source: 60, Type: "Feeding" },
  { target: 13, source: 60, Type: "Feeding" },
  { target: 14, source: 60, Type: "Feeding" },
  { target: 15, source: 60, Type: "Feeding" },
  { target: 22, source: 60, Type: "Feeding" },
  { target: 81, source: 60, Type: "Feeding" },
  { target: 25, source: 60, Type: "Feeding" },
  { target: 26, source: 60, Type: "Feeding" },
  { target: 27, source: 60, Type: "Feeding" },
  { target: 83, source: 60, Type: "Feeding" },
  { target: 0, source: 61, Type: "Feeding" },
  { target: 2, source: 61, Type: "Feeding" },
  { target: 3, source: 61, Type: "Feeding" },
  { target: 4, source: 61, Type: "Feeding" },
  { target: 5, source: 61, Type: "Feeding" },
  { target: 6, source: 61, Type: "Feeding" },
  { target: 8, source: 61, Type: "Feeding" },
  { target: 19, source: 61, Type: "Feeding" },
  { target: 21, source: 61, Type: "Feeding" },
  { target: 81, source: 61, Type: "Feeding" },
  { target: 80, source: 61, Type: "Feeding" },
  { target: 77, source: 61, Type: "Feeding" },
  { target: 78, source: 62, Type: "Feeding" },
  { target: 10, source: 62, Type: "Feeding" },
  { target: 11, source: 62, Type: "Feeding" },
  { target: 12, source: 62, Type: "Feeding" },
  { target: 13, source: 62, Type: "Feeding" },
  { target: 14, source: 62, Type: "Feeding" },
  { target: 15, source: 62, Type: "Feeding" },
  { target: 16, source: 62, Type: "Feeding" },
  { target: 17, source: 62, Type: "Feeding" },
  { target: 18, source: 62, Type: "Feeding" },
  { target: 25, source: 62, Type: "Feeding" },
  { target: 26, source: 62, Type: "Feeding" },
  { target: 28, source: 62, Type: "Feeding" },
  { target: 78, source: 63, Type: "Feeding" },
  { target: 81, source: 63, Type: "Feeding" },
  { target: 25, source: 63, Type: "Feeding" },
  { target: 26, source: 63, Type: "Feeding" },
  { target: 27, source: 63, Type: "Feeding" },
  { target: 83, source: 63, Type: "Feeding" },
  { target: 28, source: 63, Type: "Feeding" },
  { target: 29, source: 63, Type: "Feeding" },
  { target: 30, source: 63, Type: "Feeding" },
  { target: 31, source: 63, Type: "Feeding" },
  { target: 78, source: 64, Type: "Feeding" },
  { target: 10, source: 64, Type: "Feeding" },
  { target: 11, source: 64, Type: "Feeding" },
  { target: 12, source: 64, Type: "Feeding" },
  { target: 13, source: 64, Type: "Feeding" },
  { target: 14, source: 64, Type: "Feeding" },
  { target: 15, source: 64, Type: "Feeding" },
  { target: 16, source: 64, Type: "Feeding" },
  { target: 17, source: 64, Type: "Feeding" },
  { target: 18, source: 64, Type: "Feeding" },
  { target: 25, source: 64, Type: "Feeding" },
  { target: 26, source: 64, Type: "Feeding" },
  { target: 28, source: 64, Type: "Feeding" },
  { target: 83, source: 65, Type: "Feeding" },
  { target: 28, source: 65, Type: "Feeding" },
  { target: 29, source: 65, Type: "Feeding" },
  { target: 30, source: 65, Type: "Feeding" },
  { target: 31, source: 65, Type: "Feeding" },
  { target: 0, source: 66, Type: "Feeding" },
  { target: 2, source: 66, Type: "Feeding" },
  { target: 3, source: 66, Type: "Feeding" },
  { target: 5, source: 66, Type: "Feeding" },
  { target: 6, source: 66, Type: "Feeding" },
  { target: 8, source: 66, Type: "Feeding" },
  { target: 78, source: 66, Type: "Feeding" },
  { target: 10, source: 66, Type: "Feeding" },
  { target: 11, source: 66, Type: "Feeding" },
  { target: 19, source: 66, Type: "Feeding" },
  { target: 21, source: 66, Type: "Feeding" },
  { target: 81, source: 66, Type: "Feeding" },
  { target: 80, source: 66, Type: "Feeding" },
  { target: 77, source: 66, Type: "Feeding" },
  { target: 78, source: 67, Type: "Feeding" },
  { target: 10, source: 67, Type: "Feeding" },
  { target: 11, source: 67, Type: "Feeding" },
  { target: 13, source: 67, Type: "Feeding" },
  { target: 80, source: 67, Type: "Feeding" },
  { target: 78, source: 68, Type: "Feeding" },
  { target: 80, source: 68, Type: "Feeding" },
  { target: 25, source: 68, Type: "Feeding" },
  { target: 26, source: 68, Type: "Feeding" },
  { target: 27, source: 68, Type: "Feeding" },
  { target: 83, source: 68, Type: "Feeding" },
  { target: 28, source: 68, Type: "Feeding" },
  { target: 29, source: 68, Type: "Feeding" },
  { target: 30, source: 68, Type: "Feeding" },
  { target: 31, source: 68, Type: "Feeding" },
  { target: 78, source: 69, Type: "Feeding" },
  { target: 9, source: 69, Type: "Feeding" },
  { target: 10, source: 69, Type: "Feeding" },
  { target: 11, source: 69, Type: "Feeding" },
  { target: 13, source: 69, Type: "Feeding" },
  { target: 19, source: 69, Type: "Feeding" },
  { target: 21, source: 69, Type: "Feeding" },
  { target: 80, source: 69, Type: "Feeding" },
  { target: 25, source: 69, Type: "Feeding" },
  { target: 78, source: 70, Type: "Feeding" },
  { target: 10, source: 70, Type: "Feeding" },
  { target: 11, source: 70, Type: "Feeding" },
  { target: 12, source: 70, Type: "Feeding" },
  { target: 13, source: 70, Type: "Feeding" },
  { target: 14, source: 70, Type: "Feeding" },
  { target: 15, source: 70, Type: "Feeding" },
  { target: 16, source: 70, Type: "Feeding" },
  { target: 17, source: 70, Type: "Feeding" },
  { target: 18, source: 70, Type: "Feeding" },
  { target: 21, source: 70, Type: "Feeding" },
  { target: 81, source: 70, Type: "Feeding" },
  { target: 23, source: 70, Type: "Feeding" },
  { target: 24, source: 70, Type: "Feeding" },
  { target: 25, source: 70, Type: "Feeding" },
  { target: 26, source: 70, Type: "Feeding" },
  { target: 27, source: 70, Type: "Feeding" },
  { target: 83, source: 72, Type: "Feeding" },
  { target: 28, source: 72, Type: "Feeding" },
  { target: 29, source: 72, Type: "Feeding" },
  { target: 30, source: 72, Type: "Feeding" },
  { target: 31, source: 72, Type: "Feeding" },
  { target: 1, source: 73, Type: "Feeding" },
  { target: 78, source: 73, Type: "Feeding" },
  { target: 9, source: 73, Type: "Feeding" },
  { target: 10, source: 73, Type: "Feeding" },
  { target: 11, source: 73, Type: "Feeding" },
  { target: 13, source: 73, Type: "Feeding" },
  { target: 19, source: 73, Type: "Feeding" },
  { target: 21, source: 73, Type: "Feeding" },
  { target: 80, source: 73, Type: "Feeding" },
  { target: 25, source: 73, Type: "Feeding" },
  { target: 78, source: 74, Type: "Feeding" },
  { target: 22, source: 74, Type: "Feeding" },
  { target: 25, source: 74, Type: "Feeding" },
  { target: 26, source: 74, Type: "Feeding" },
  { target: 27, source: 74, Type: "Feeding" },
  { target: 78, source: 75, Type: "Feeding" },
  { target: 9, source: 75, Type: "Feeding" },
  { target: 10, source: 75, Type: "Feeding" },
  { target: 11, source: 75, Type: "Feeding" },
  { target: 12, source: 75, Type: "Feeding" },
  { target: 13, source: 75, Type: "Feeding" },
  { target: 14, source: 75, Type: "Feeding" },
  { target: 15, source: 75, Type: "Feeding" },
  { target: 16, source: 75, Type: "Feeding" },
  { target: 17, source: 75, Type: "Feeding" },
  { target: 18, source: 75, Type: "Feeding" },
  { target: 21, source: 75, Type: "Feeding" },
  { target: 81, source: 75, Type: "Feeding" },
  { target: 80, source: 75, Type: "Feeding" },
  { target: 23, source: 75, Type: "Feeding" },
  { target: 24, source: 75, Type: "Feeding" },
  { target: 25, source: 75, Type: "Feeding" },
  { target: 26, source: 75, Type: "Feeding" },
  { target: 27, source: 75, Type: "Feeding" },
  { target: 83, source: 75, Type: "Feeding" },
  { target: 78, source: 76, Type: "Feeding" },
  { target: 25, source: 76, Type: "Feeding" },
  { target: 26, source: 76, Type: "Feeding" },
  { target: 27, source: 76, Type: "Feeding" },
  { target: 28, source: 76, Type: "Feeding" },
  { target: 35, source: 76, Type: "Feeding" },
  { target: 55, source: 76, Type: "Feeding" },
  { target: 61, source: 76, Type: "Feeding" },
  { target: 23, source: 81, Type: "Feeding" },
  { target: 0, source: 77, Type: "Feeding" },
  { target: 8, source: 77, Type: "Feeding" },
  { target: 2, source: 77, Type: "Feeding" },
  { target: 3, source: 77, Type: "Feeding" },
  { target: 6, source: 77, Type: "Feeding" },
  { target: 0, source: 79, Type: "ES" },
  { target: 1, source: 79, Type: "ES" },
  { target: 2, source: 79, Type: "ES" },
  { target: 3, source: 79, Type: "ES" },
  { target: 4, source: 79, Type: "ES" },
  { target: 5, source: 79, Type: "ES" },
  { target: 6, source: 79, Type: "ES" },
  { target: 7, source: 79, Type: "ES" },
  { target: 8, source: 79, Type: "ES" },
  { target: 78, source: 79, Type: "ES" },
  { target: 9, source: 79, Type: "ES" },
  { target: 10, source: 79, Type: "ES" },
  { target: 11, source: 79, Type: "ES" },
  { target: 12, source: 79, Type: "ES" },
  { target: 13, source: 79, Type: "ES" },
  { target: 14, source: 79, Type: "ES" },
  { target: 15, source: 79, Type: "ES" },
  { target: 16, source: 79, Type: "ES" },
  { target: 17, source: 79, Type: "ES" },
  { target: 18, source: 79, Type: "ES" },
  { target: 19, source: 79, Type: "ES" },
  { target: 20, source: 79, Type: "ES" },
  { target: 21, source: 79, Type: "ES" },
  { target: 22, source: 79, Type: "ES" },
  { target: 81, source: 79, Type: "ES" },
  { target: 80, source: 79, Type: "ES" },
  { target: 23, source: 79, Type: "ES" },
  { target: 24, source: 79, Type: "ES" },
  { target: 25, source: 79, Type: "ES" },
  { target: 26, source: 79, Type: "ES" },
  { target: 27, source: 79, Type: "ES" },
  { target: 83, source: 79, Type: "ES" },
  { target: 28, source: 79, Type: "ES" },
  { target: 29, source: 79, Type: "ES" },
  { target: 30, source: 79, Type: "ES" },
  { target: 31, source: 79, Type: "ES" },
  { target: 32, source: 79, Type: "ES" },
  { target: 33, source: 79, Type: "ES" },
  { target: 76, source: 79, Type: "ES" },
  { target: 29, source: 82, Type: "ES" },
  { target: 83, source: 82, Type: "ES" },
  { target: 31, source: 82, Type: "ES" },
];
export const nodeList7 = [
  {
    index: 0,
    speciesID: 5,
    biomass: 475.33333,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Salt Grass",
    trophicLevel: 1.0,
    desc: "Salt grass is a species of grass that grows in salt marshes.",
    imgFile: "salt-grass.jpg",
    imgCaption: "Salt Grass.",
  },
  {
    index: 1,
    speciesID: 6,
    biomass: 296.72,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Epipelic flora",
    trophicLevel: 1.0,
    desc: "Epipelic flora includes plants that live in mud at the edge of water and land.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 2,
    speciesID: 7,
    biomass: 900.0,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Alkali Heath",
    trophicLevel: 1.0,
    desc: "Alkali Heath is a short, wood plant that grows in salt marshes.",
    imgFile: "heath.jpg",
    imgCaption: "Close up of Alkali Heath.",
  },
  {
    index: 3,
    speciesID: 8,
    biomass: 4488.6667,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Fleshy Jaumea",
    trophicLevel: 1.0,
    desc: "Fleshy Jaumea is a succulent species native to the western coast of North America.",
    imgFile: "fleshy-j.jpg",
    imgCaption: "Close up of Fleshy Jaumea.",
  },
  {
    index: 4,
    speciesID: 9,
    biomass: 120.66667,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "California Sea Lavender",
    trophicLevel: 1.0,
    desc: "California Sea Lavendar is a speces of ea lavender that's native to western North America.",
    imgFile: "ca-lav.jpg",
    imgCaption: "Close up of California Sea Lavender. ",
  },
  {
    index: 5,
    speciesID: 10,
    biomass: 256.49524,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "macroalgae",
    trophicLevel: 1.0,
    desc: "Macroalgae, also known as seaweed, includes many species of large, multicelluarl marine algae. ",
    imgFile: "macro.jpg",
    imgCaption: "Red Estuarine macroalgae in a dish.",
  },
  {
    index: 6,
    speciesID: 11,
    biomass: 1272.0,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Shore Grass",
    trophicLevel: 1.0,
    desc: "Shore Grass is a species of frass that grows along shorelines and in salt marshes.",
    imgFile: "shore-grass.jpg",
    imgCaption: "Shore Grass in front of water.",
  },
  {
    index: 7,
    speciesID: 12,
    biomass: 1527.7309,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Phytoplankton",
    trophicLevel: 1.0,
    desc: "Phytoplankton are plankton consisting of microscopic plants.",
    imgFile: "phyto.jpg",
    imgCaption: "Microscope image of one type of phytoplankton.",
  },
  {
    index: 8,
    speciesID: 16,
    biomass: 38428.267,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Pickleweed",
    trophicLevel: 1.0,
    desc: "Pickleweed is a type of succulent plant that is very common salt marshes.",
    imgFile: "pickle.jpg",
    imgCaption: "Close up of Pickleweed.",
  },
  {
    index: 9,
    speciesID: 21,
    biomass: 0.1433138,
    organismType: "Plankton",
    nodeColor: "Light Yellow-15",
    nodeShape: "Circle",
    nodeName: "Foraminiferan",
    trophicLevel: 2.0,
    desc: "Foraminiferan is plankton consisting of tiny, single-celled animals.",
    imgFile: "zoo.jpg",
    imgCaption: "A microscopic, labeled image of zooplankton.",
  },
  {
    index: 10,
    speciesID: 25,
    biomass: 2.6102455,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Capitella capitata",
    trophicLevel: 2.0,
    desc: "Capitella capitata is a type of segmented worm.",
    imgFile: "capitella.jpg",
    imgCaption: "Capitella capitata",
  },
  {
    index: 11,
    speciesID: 35,
    biomass: 4.150018008,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Oligochaeta",
    trophicLevel: 1.0,
    desc: "Oligochaeta are the large subclass fo worms known as Earth worms.",
    imgFile: "Oligochaeta.jpg",
    imgCaption: "One species of Oligochaeta.",
  },
  {
    index: 12,
    speciesID: 44,
    biomass: 1.6343817,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Polydora nuchalis",
    trophicLevel: 2.0,
    desc: "Polydora nuchalis is a type of worm.",
    imgFile: "Oligochaeta.jpg",
    imgCaption: "One type of worm",
  },
  {
    index: 13,
    speciesID: 47,
    biomass: 0,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Glossiphoniidae, leech",
    trophicLevel: 4.29474484,
    desc: "Glossiphoniidae is a family of leeches that are most common in fresh water.",
    imgFile: "leech.jpg",
    imgCaption: "Microscope image of one type of leech.",
  },
  {
    index: 14,
    speciesID: 50,
    biomass: 6.143874555,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Carcinonemertes",
    trophicLevel: 4.04805726,
    desc: "Carcinonemertes is a family of ribbon worms.",
    imgFile: "Carcinonemertes.jpg",
    imgCaption: "Microscopic image of a Carcinonemertes.",
  },
  {
    index: 15,
    speciesID: 51,
    biomass: 0.1062877,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Carcinoma mutabilis",
    trophicLevel: 2.666666667,
    desc: "Carcinoma mutabilis is a species of ribbon worm.",
    imgFile: "Carcinonemertes.jpg",
    imgCaption: "Microscopic image of a Carcinonemertes.",
  },
  {
    index: 16,
    speciesID: 53,
    biomass: 0.0245069,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Nemertean",
    trophicLevel: 2.666666667,
    desc: "Nemertean is a phylum of ribbon worms.",
    imgFile: "nemeterea.jpg",
    imgCaption: "Nemeterea in a jar of water.",
  },
  {
    index: 17,
    speciesID: 61,
    biomass: 0.0001898,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Softshell Clam",
    trophicLevel: 2.0,
    desc: "The California Softshell Clam is one of many softshell clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 18,
    speciesID: 64,
    biomass: 0.0080906,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Bent-nosed Clam",
    trophicLevel: 2.0,
    desc: "Bent-nosed clams are a species of saltwater clams that are vulnerable to ocean acidification.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 19,
    speciesID: 65,
    biomass: 210.72214,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "White Sand Macoma",
    trophicLevel: 2.920245399,
    desc: "White Sant Macoma are a species of saltwater clams that are vulnerable to ocean acidification.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 20,
    speciesID: 66,
    biomass: 0.000352,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Blue Mussel",
    trophicLevel: 2.0,
    desc: "The Blue Mussel is a medium-sized, edible mussel. It is vulnerable to ocean acidification and pollution.",
    imgFile: "b-mussel.jpg",
    imgCaption: "Clusters of blue mussels in shallow water.",
  },
  {
    index: 21,
    speciesID: 67,
    biomass: 45.118247,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Mahogany Clam",
    trophicLevel: 2.920245399,
    desc: "California Mahogany Clams are a species of saltwater clams.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 22,
    speciesID: 68,
    biomass: 569.15303,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Pacific Littleneck Clam",
    trophicLevel: 2.0,
    desc: "The Pacific Littleneck Clam is one of many clam species. It is vulnerable to ocean acidification, which is the decrease in the ocean's pH as it uptakes carbon dioxide.",
    imgFile: "softshell-ca.jpg",
    imgCaption:
      "Mya arenaria, a different, but similar species of softshell clam.",
  },
  {
    index: 23,
    speciesID: 70,
    biomass: 151.8597,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Butter Clam",
    trophicLevel: 2.920245399,
    desc: "Butter clams are a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "butt-clam.jpg",
    imgCaption: "Close up of a Butter Clam.",
  },
  {
    index: 24,
    speciesID: 71,
    biomass: 0.0005142,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Fat Jackknife Clam",
    trophicLevel: 2.981595092,
    desc: "The Fat Jackknife Clam is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption:
      "A different but similar species of jackknife clam in sand and seaweed.",
  },
  {
    index: 25,
    speciesID: 72,
    biomass: 257.61279,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Jackknife Clam",
    trophicLevel: 2.981595092,
    desc: "The California Jackknife Clam is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption:
      "A different but similar species of jackknife clam in sand and seaweed.",
  },
  {
    index: 26,
    speciesID: 73,
    biomass: 317.06629,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Lesser Tagelus",
    trophicLevel: 2.0,
    desc: "The lesser Tagelus is a species of saltwater clam that are vulnerable to ocean acidification.",
    imgFile: "jack-clam.jpg",
    imgCaption: "A different species of clam in sand and seaweed.",
  },
  {
    index: 27,
    speciesID: 75,
    biomass: 2.2624235,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Acteocina inculta",
    trophicLevel: 2.0,
    desc: "Acteocina inculta is a species of sea snail.",
    imgFile: "sea-snail.jpg",
    imgCaption: "A different but similar species of sea snail.",
  },
  {
    index: 28,
    speciesID: 76,
    biomass: 6.2561136,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Assiminea",
    trophicLevel: 1.0,
    desc: "California Assiminea is a species of small, salt-tolerant snails.",
    imgFile: "assiminea.jpg",
    imgCaption:
      "Close up of the shell of a snail species similar to California Assiminea.",
  },
  {
    index: 29,
    speciesID: 79,
    biomass: 143.83576,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "California Horn Snail",
    trophicLevel: 2.0,
    desc: "The California Horn Snail is a species of sea snail.",
    imgFile: "ca-horn.jpg",
    imgCaption: "Top and side view of three California Horn Snail shells.",
  },
  {
    index: 30,
    speciesID: 82,
    biomass: 85.015283,
    organismType: "Mollusc",
    nodeColor: "Dark Teal-2",
    nodeShape: "Circle",
    nodeName: "Salt Marsh Snail",
    trophicLevel: 1.0,
    desc: "The Salt Marsh Snail, also known as the Marsh periwinkle is a species of sea snail.",
    imgFile: "snail.jpg",
    imgCaption: "A close up of a Salt Marsh Snail.",
  },
  {
    index: 31,
    speciesID: 86,
    biomass: 3.519108023,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Black Salt Marsh Mosquito",
    trophicLevel: 4.48620229,
    desc: "The Black Salt Marsh mosquito is a type of mosquito.",
    imgFile: "mosquito.jpg",
    imgCaption:
      "Green Mosquito, a species similar to the Black Salt Marsh Mosquito, on a leaf.",
  },
  {
    index: 32,
    speciesID: 87,
    biomass: 5.642390964,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Argulus",
    trophicLevel: 4.200200438,
    desc: "Argulus are a species of small crustaceans, also known as fish lice.",
    imgFile: "argulus.jpg",
    imgCaption: "A type of Argulus in a dish of water.",
  },
  {
    index: 33,
    speciesID: 89,
    biomass: 6.0595817,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Corophiid",
    trophicLevel: 1.0,
    desc: "Corophiids are small crustaceans. ",
    imgFile: "Corophiid.jpg",
    imgCaption: "A microscopic image of a Corophiid.",
  },
  {
    index: 34,
    speciesID: 90,
    biomass: 3.625295867,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Western Encephalitis Mosquito",
    trophicLevel: 4.48620229,
    desc: "The Western Encephalitis mosquito is a type of mosquito.",
    imgFile: "mosquito.jpg",
    imgCaption:
      "Green Mosquito, a species similar to theWestern Encephalitis Mosquito, on a leaf.",
  },
  {
    index: 35,
    speciesID: 92,
    biomass: 0.0246359,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Cyclopoid Copepod",
    trophicLevel: 2.0,
    desc: "Cyclopoid Copepod are small crustaceans. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 36,
    speciesID: 93,
    biomass: 0.0389175,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Dipteran",
    trophicLevel: 1.0,
    desc: "Diptera are a species of common fly, this is the species you likely see most.",
    imgFile: "fly.jpg",
    imgCaption: "Close up of a fly on a leaf.",
  },
  {
    index: 37,
    speciesID: 94,
    biomass: 2.948143,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Ephydra",
    trophicLevel: 2.0,
    desc: "Ephydra are a species of common fly.",
    imgFile: "fly.jpg",
    imgCaption: "Close up of a fly on a leaf.",
  },
  {
    index: 38,
    speciesID: 99,
    biomass: 0.0696413,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Green Gammaridean",
    trophicLevel: 2.0,
    desc: "Green Gammaridean are small crustaceans. ",
    imgFile: "amphipod.jpg",
    imgCaption: "A different species of amphipod.",
  },
  {
    index: 39,
    speciesID: 100,
    biomass: 0.1384038,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Garpacticoid",
    trophicLevel: 1.0,
    desc: "Garpacticoid are small crustaceans. ",
    imgFile: "amphipod.jpg",
    imgCaption: "A different species of amphipod.",
  },
  {
    index: 40,
    speciesID: 102,
    biomass: 1.4327233,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Oniscidean",
    trophicLevel: 1.0,
    desc: "Oniscidean are small crustaceans. ",
    imgFile: "Oniscidean.jpg",
    imgCaption: "Two Oniscidean on wood.",
  },
  {
    index: 41,
    speciesID: 104,
    biomass: 0.1182526,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Ostracod",
    trophicLevel: 2.0,
    desc: "Ostracods are small crustaceans that are vulnerable to hypoxia.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 42,
    speciesID: 106,
    biomass: 1.5349126,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Pardosa",
    trophicLevel: 2.0,
    desc: "Paradosa is a genus of wolf spiders.",
    imgFile: "Pardosa.jpg",
    imgCaption: "A close up of a species of Paradosa.",
  },
  {
    index: 43,
    speciesID: 109,
    biomass: 3.3067643,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Salt Marsh Hopper",
    trophicLevel: 1.0,
    desc: "Salt Marsh Hoppers are small crustaceans. ",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 44,
    speciesID: 110,
    biomass: 0.726149,
    organismType: "Insects, Annelids and Arachnids",
    nodeColor: "Dark Purple-6",
    nodeShape: "Circle",
    nodeName: "Salt Marsh Water Boatman",
    trophicLevel: 2.0,
    desc: "Salt Marsh Water Boatman are small, aquatic insects that can live in marine, brackish, and freshwater.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 45,
    speciesID: 111,
    biomass: 7.1600804,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Calfornia Ghost Shrimp",
    trophicLevel: 2.0,
    desc: "The California Ghost Shrimp, also known as the Bay Ghost Shrimp, is one of many species of ghost shrimp. ",
    imgFile: "ghost-shrimp.jpg",
    imgCaption: "A similar species of ghost shrimp.",
  },
  {
    index: 46,
    speciesID: 112,
    biomass: 8.277904,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Southern Mud Shrimp",
    trophicLevel: 3.232745399,
    desc: "The Southern Mud Shrimp is a species of crustacean that spends most of its time burrowed in mud and tidal flats.",
    imgFile: "mud-shrimp.jpg",
    imgCaption: "A similar species of mud shrimp.",
  },
  {
    index: 47,
    speciesID: 113,
    biomass: 0.1412562,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Green Shore Crab",
    trophicLevel: 3.04805726,
    desc: "The Green Shore Crab is one of the most common littoral crabs. This species is vulnerable to pollution.",
    imgFile: "g-crab.jpg",
    imgCaption: "A Green Shore Crab underwater in sand.",
  },
  {
    index: 48,
    speciesID: 114,
    biomass: 175.59909,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Striped Shore Crab",
    trophicLevel: 3.108737043,
    desc: "The Striped Shore Crab is a species of crab that lives in muddy areas. This species is likely vulnerable to pollution.",
    imgFile: "stripe-crab.jpg",
    imgCaption: "A Striped Shore Crab standing in a rocky sand patch.",
  },
  {
    index: 49,
    speciesID: 115,
    biomass: 0.2141502,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Fiddler Crab",
    trophicLevel: 2.0,
    desc: "Fiddler crabs are small crustacenas with a distinctive large claw. ",
    imgFile: "fiddler.jpg",
    imgCaption: "A fiddler crab standing above its burrow in rocky sand.",
  },
  {
    index: 50,
    speciesID: 116,
    biomass: 0.4240099,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Yellow-fin Goby",
    trophicLevel: 3.660630054,
    desc: "The Yellow-fin Goby is a small fish native to Asia.",
    imgFile: "goby.jpg",
    imgCaption:
      "Round goby, a species similar to the Yellow-fin Goby, in water.",
  },
  {
    index: 51,
    speciesID: 118,
    biomass: 4.5752376,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Topsmelt",
    trophicLevel: 3.232745399,
    desc: "Topsmelt is a small fish species found in bays and estuaries, among other coastal ecosystems. This species is targeted by commercial fisheries but is considered to have a stable population size.",
    imgFile: "topsmelt.jpg",
    imgCaption: "Topsmelt Silverside on towel.",
  },
  {
    index: 52,
    speciesID: 119,
    biomass: 0.6982437,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Arrow Goby",
    trophicLevel: 3.151329243,
    desc: "Arrow Goby are a small fish native to the Pacific coast off the United States.",
    imgFile: "goby.jpg",
    imgCaption: "Round goby, a species similar to the Arrow Goby, in water.",
  },
  {
    index: 53,
    speciesID: 120,
    biomass: 0.0449312,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Shiner Surfperch",
    trophicLevel: 2.333333333,
    desc: "Shiner surfperch are non-migratory fish that live in marine and brackish (slightly salty, occuring where salt and fresh water meet) waters. There are small commercial fisheries that target this species, and it is occasionally used as bait by recreational fishers. This species is vulnerable to habitat loss.",
    imgFile: "surfperch.jpg",
    imgCaption: "Shiner Surfperch swimming.",
  },
  {
    index: 54,
    speciesID: 121,
    biomass: 1.6540096,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Killifish",
    trophicLevel: 3.167655476,
    desc: "The California Killifish is a small fish found around California and Baja California. ",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 55,
    speciesID: 122,
    biomass: 0.7728177,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Long-jawed Mudsucker",
    trophicLevel: 3.154495751,
    desc: "The Long-jawed Mudsucker is a small fish species.",
    imgFile: "goby.jpg",
    imgCaption:
      "Round goby, a species similar to the Long-jawed Mudsucker, in water.",
  },
  {
    index: 56,
    speciesID: 125,
    biomass: 0.2618094,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Diamond Turbot",
    trophicLevel: 3.419220322,
    desc: "Diamond Turbot is a type of flatfish that occurs in coastal and brackish waters, living on muddy and sandy bottoms. They are not commercially targeted, but some recreational fishers may target this specices. It is not considered to be vulnerable. ",
    imgFile: "turbot.jpg",
    imgCaption:
      "Windowpane flounder laying on table. Similar species to Diamond turbot.",
  },
  {
    index: 57,
    speciesID: 127,
    biomass: 10.415117,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Staghorn Sculpin",
    trophicLevel: 3.43499393,
    desc: "The Staghorn Sculpin is a common species of sculpin found off of the Pacific coast of the United States. It is vulnerable to eutrophication.",
    imgFile: "sculpin.jpg",
    imgCaption:
      "Halewood Tidepool Sculpin, a species similar to the Staghorn Sculpin.",
  },
  {
    index: 58,
    speciesID: 132,
    biomass: 0.1536856,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "California Halibut",
    trophicLevel: 3.379770769,
    desc: "California Halibut is a large species of flatfish that lives on the Pacific Coast of the United States. This species is targeted by both commercial and recreational fisheries. It is considered to be vulnerable to the loss of critical nursery habitat.",
    imgFile: "halibut.jpg",
    imgCaption:
      "Three men standing next two many of the California Halibut that they caught.",
  },
  {
    index: 59,
    speciesID: 139,
    biomass: 0.0863925,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Leopard Shark",
    trophicLevel: 3.888481198,
    desc: "Leopard sharks are a small species of shark that lives in shallow waters in the Pacific Coast of the United States. It is targeted by both commercial and recreational fisheries, and is susceptible to overfishing.",
    imgFile: "leo-shark.jpg",
    imgCaption: "Leopard shark in sand.",
  },
  {
    index: 60,
    speciesID: 140,
    biomass: 0.0284862,
    organismType: "Fish",
    nodeColor: "Blue-7",
    nodeShape: "Circle",
    nodeName: "Haller's Round Ray",
    trophicLevel: 3.769553287,
    desc: "Haller's Round Ray is a species of stingray that lives in shallow coastal waters along the coast of California. Its range extends south to Ecuador. There a some fisheries that target this species but it is generally not considered vulnerable. ",
    imgFile: "Urobatis.jpg",
    imgCaption:
      "Yellow stingray, a species of stingray close to Haller's Round Ray.",
  },
  {
    index: 61,
    speciesID: 141,
    biomass: 0.0151609,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Avocet",
    trophicLevel: 3.509594049,
    desc: "The American Avocet is a migrating bird that occurs in the Western half of the United States. This bird is generally stable but is vulnerable to climate change impacts, especially in their salt marsh habitats.",
    imgFile: "avocet.jpg",
    imgCaption: "Two American Avocets standing in water.",
  },
  {
    index: 62,
    speciesID: 143,
    biomass: 0.0922059,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Coot",
    trophicLevel: 2.603114677,
    desc: "The American Coot is considered to be an adaptable waterbird with a range covering most of the United States, extending into Canada and Mexico. It has a low vulnerability status.",
    imgFile: "amer-coot.jpg",
    imgCaption: "American Coot getting ready to take flight from water.",
  },
  {
    index: 63,
    speciesID: 144,
    biomass: 0.0013822,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Crow",
    trophicLevel: 3.846793612,
    desc: "The American Crow is considered one of the most intelligent birds and is widespread across the United States. This species has a low vulnerability status.",
    imgFile: "crow.jpg",
    imgCaption: "American Crow standing on a branch.",
  },
  {
    index: 64,
    speciesID: 145,
    biomass: 0.0674677,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "American Wigeon",
    trophicLevel: 3.088400416,
    desc: "The American Wigeon is a species of duck. It is considered to be moderately vulnerable to climate change.",
    imgFile: "amer-wigeon.jpg",
    imgCaption: "American Wigeon sitting in water.",
  },
  {
    index: 65,
    speciesID: 146,
    biomass: 0.0063977,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-bellied Plover",
    trophicLevel: 3.185427097,
    desc: "The Black-bellied plover spends winter on the Pacific coast of the United States. In the summers, it lives in Northern Canada. This species is highly vulnerable to climate change. ",
    imgFile: "bb-plover.jpg",
    imgCaption: "Black-bellied Plover standing in field.",
  },
  {
    index: 66,
    speciesID: 147,
    biomass: 0.0300871,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Black-crowned Night Heron",
    trophicLevel: 4.164540449,
    desc: "The Black-crowned Night Heron can be found across the United States and is vulnerable to water pollution and habitat loss.",
    imgFile: "night-heron.jpg",
    imgCaption: "Black-crowned Night Heron standing on rock.",
  },
  {
    index: 67,
    speciesID: 148,
    biomass: 0.0036769,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Belted Kingfisher",
    trophicLevel: 3.911244736,
    desc: "Belted Kingfishers are common, migrating birds that occur across North America. Recent surveys indicate a decline in population, as these birds tend to be vulnerable to habitat loss and climate change.",
    imgFile: "kingfisher.jpg",
    imgCaption: "Belted Kingfisher sitting on tree branch above water. ",
  },
  {
    index: 68,
    speciesID: 151,
    biomass: 0.0007309,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bonaparte's Gull",
    trophicLevel: 3.781655642,
    desc: "The Bonaparte's Gull is the smallest gull to occur in North America and migrates from Canada to coastal United States in the winter. While it is vulnerable to climate change and increased temperatures, it is less vulnerable to other human-related disturbances.",
    imgFile: "bonaparte.jpg",
    imgCaption: "Bonaparte's Gull standing on branch.",
  },
  {
    index: 69,
    speciesID: 154,
    biomass: 0.0915692,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Bufflehead",
    trophicLevel: 3.535814443,
    desc: "The Bufflehead is a smaller duck species that occurs in North America. It is generally considered to have a stable population, but is vulnerable to habitat loss and highly vulnerable to climate change.",
    imgFile: "bufflehead.jpg",
    imgCaption: "Two Buffleheads sitting in water.",
  },
  {
    index: 70,
    speciesID: 155,
    biomass: 0.0516353,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Blue-winged Teal",
    trophicLevel: 3.088400416,
    desc: "The Blue-winged Teal is a small species of duck that spends its summers in Canada and the United States, migrating south towards South America in the winter. It is considered to have a low vulnerability status. ",
    imgFile: "bw-teal.jpg",
    imgCaption: "Blue-winged Teal sitting near water.",
  },
  {
    index: 71,
    speciesID: 157,
    biomass: 0.249866575,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "California Gull",
    trophicLevel: 3.809227058,
    desc: "The California Gull is a gull species that occurs mainly on the Pacific coast of North America. It is moderately vulnerable to climate change.",
    imgFile: "ca-gull.jpg",
    imgCaption: "California Gull standing on a rock near water.",
  },
  {
    index: 72,
    speciesID: 158,
    biomass: 0.0036287,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Caspian Tern",
    trophicLevel: 3.971265863,
    desc: "The Caspian Tern is the largest of all tern species. These birds have a low vulnerability to climate change.",
    imgFile: "c-tern.jpg",
    imgCaption: "Two Caspian Terns standing in sand.",
  },
  {
    index: 73,
    speciesID: 159,
    biomass: 0.0149052,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cinnamon Teal",
    trophicLevel: 3.088400416,
    desc: "The Cinnamon Teal is a common species of duck that can be found only in the Western half of the United States, in addition to their range in Mexico. It is moderately vulnerable to climate change. ",
    imgFile: "cinn-teal.jpg",
    imgCaption: "Cinnamon Teal swimming in water.",
  },
  {
    index: 74,
    speciesID: 161,
    biomass: 0.002242,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Cooper's Hawk",
    trophicLevel: 4.080250184,
    desc: "The Cooper's Hawk is a widespread species that is not considered to be vulnerable to climate change.",
    imgFile: "c-hawk.jpg",
    imgCaption: "Cooper's Hawk sitting on a tree branch.",
  },
  {
    index: 75,
    speciesID: 163,
    biomass: 0.0124805,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Double-crested Cormorant",
    trophicLevel: 4.214908253,
    desc: "The Double-crested Cormorant is a species of diving birds that is not considered to be vulnerable to climate change. However, it may be impacted by pollution.",
    imgFile: "cormorant.jpg",
    imgCaption: "Double-crested Cormorant sitting in water.",
  },
  {
    index: 76,
    speciesID: 164,
    biomass: 0.0090074,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Dunlin",
    trophicLevel: 3.093826961,
    desc: "The Dunlin is a migrating species that spends its winters on the Pacific coast of the United States. This species has seen considerable declines in their wintering seasons and is highly vulnerable to climate change.",
    imgFile: "dunlin.jpg",
    imgCaption: "Dunlin standing in water.",
  },
  {
    index: 77,
    speciesID: 168,
    biomass: 0.2673797,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Blue Heron",
    trophicLevel: 4.214908253,
    desc: "Great Blue Herons are large, common water birds and are considered to be highly adaptable. They will likely not be negatively affected by climate change.",
    imgFile: "heron.jpg",
    imgCaption: "Great Blue Heron flying over water.",
  },
  {
    index: 78,
    speciesID: 169,
    biomass: 0.0701152,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Great Egret",
    trophicLevel: 4.214908253,
    desc: "Great Egrets are a tall, water bird that is generally not vulnerable to climate change. This bird has a wide range that spans the Southern pacific and Southern Atlantic coasts of the United States.",
    imgFile: "egret.jpg",
    imgCaption: "Great Egret flying.",
  },
  {
    index: 79,
    speciesID: 170,
    biomass: 0.0075045,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green Heron",
    trophicLevel: 3.971265863,
    desc: "The Green Heron is smaller than the Great Blue Heron but will also likely not be impacted negatively by climate change.",
    imgFile: "green-heron.jpg",
    imgCaption: "Green Heron standing on rock.",
  },
  {
    index: 80,
    speciesID: 172,
    biomass: 0.0396191,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Greater Yellowlegs",
    trophicLevel: 3.496097014,
    desc: "Greater Yellowlegs are a migrating, wading bird species. It is highly vulnerable to climate change, and there is not consensus on the stability of its population.",
    imgFile: "g-yellow.jpg",
    imgCaption: "Greater Yellowlegs standing in shallow water.",
  },
  {
    index: 81,
    speciesID: 173,
    biomass: 0.1386956,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Green-winged Teal",
    trophicLevel: 3.197240458,
    desc: "This small duck species is common and widespread. The Green-winged Teal is considered to have low vulnerability to climate change.",
    imgFile: "gw-teal.jpg",
    imgCaption: "Green-winged Teal swimming in water.",
  },
  {
    index: 82,
    speciesID: 174,
    biomass: 0.0062451,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Killdeer",
    trophicLevel: 2.25,
    desc: "The Killdeer is a common species of bird, and is not considered to be vulnerable to climate change. ",
    imgFile: "killdeer.jpg",
    imgCaption: "Killdeer sitting in gravel.",
  },
  {
    index: 83,
    speciesID: 175,
    biomass: 0.0835354,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Curlew",
    trophicLevel: 3.796009699,
    desc: "The Long-billed Curlew is one of the largest shorebirds, migrating across the Western United States and Mexico. It is vulnerable to habitat loss, as much of their breeding habitat has been converted to agriculture. They are also highly vulnerable to climate change.",
    imgFile: "lb-curlew.jpg",
    imgCaption: "Long-billed Curlew taking flight from water.",
  },
  {
    index: 84,
    speciesID: 176,
    biomass: 0.0553906,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Long-billed Dowitcher",
    trophicLevel: 3.202679961,
    desc: "The Long-billed Dowitcher is a similar species to the Long-billed Curlew. While they overlap in range, the Long-billed Dowitcher prefers fresh water to salt water. This species is highly vulnerable to climate change.",
    imgFile: "lb-dowitcher.jpg",
    imgCaption: "Long-billed Dowitcher standing in shallow water.",
  },
  {
    index: 85,
    speciesID: 177,
    biomass: 0.0035291,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Little Blue Heron",
    trophicLevel: 3.942601723,
    desc: "The Little Blue Heron is a migrating bird that is not considered to be vulnerable to climate change.",
    imgFile: "lb-heron.jpg",
    imgCaption: "Little Blue Heron standing in marsh water.",
  },
  {
    index: 86,
    speciesID: 178,
    biomass: 0.0356641,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Least Sandpiper",
    trophicLevel: 2.889276807,
    desc: 'The Least Sandpiper is a migrating species. This species relies less on "stopover" sites than other migrating birds, and is considered to be moderately vulnerable to climate change.',
    imgFile: "least-sand.jpg",
    imgCaption: "Least Sandpiper standing at water's edge.",
  },
  {
    index: 87,
    speciesID: 181,
    biomass: 0.1057894,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Marbled Godwit",
    trophicLevel: 3.222512517,
    desc: "The Marbled Godwit is a migrating bird that is declining due to habitat loss in their summer habitat. They are moderately vulnerable to climate change.",
    imgFile: "m-godwit.jpg",
    imgCaption: "Marbled Godwit standing in waves.",
  },
  {
    index: 88,
    speciesID: 182,
    biomass: 0.4704502,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mallard",
    trophicLevel: 2.821906152,
    desc: "Mallards are one of the most abundant ducks in the world. They migrate across North America and are considered to have a low vulnerability to climate change.",
    imgFile: "mallard.jpg",
    imgCaption: "Three female and two male Mallards standing in shallow water.",
  },
  {
    index: 89,
    speciesID: 183,
    biomass: 0.273488671,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Mew Gull",
    trophicLevel: 3.781655642,
    desc: "The Mew Gull, also known as the Common Gull, is a medium-sized, migrating water bird that is common along the Pacific Coast and in Alaska and Northern Canada. Like many other birds, the Mew Gull is vulnerable to pollution.",
    imgFile: "gull.jpg",
    imgCaption: "Mew Gull standing near water.",
  },
  {
    index: 90,
    speciesID: 187,
    biomass: 0.0198625,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Pied-billed Grebe",
    trophicLevel: 4.081358989,
    desc: "The Pied-billed Grebe is a common and widespread bird. While there have been declines in this species in the last few decades, it is generally not vulnerable to disturbances.",
    imgFile: "pied-grebe.jpg",
    imgCaption: "Pied-billed Grebe swimming in water.",
  },
  {
    index: 91,
    speciesID: 189,
    biomass: 0.0064582,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ring-billed Gull",
    trophicLevel: 3.781655642,
    desc: "The Ring-billed Gull is one of the most common gulls in North America, and has benefited greatly from the aavailability of food provided by humans. It is considered to have a low vulnerability status to climate change. ",
    imgFile: "rb-gull.jpg",
    imgCaption: "Ring-billed Gull standing in shallow water.",
  },
  {
    index: 92,
    speciesID: 190,
    biomass: 0.0518401,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Red-breasted Merganser",
    trophicLevel: 4.214908253,
    desc: "The Red-breasted Merganser is a species of duck found in coastal United States and the Great Lakes Region. This species may lose about 30% of its range due to climate change.",
    imgFile: "merganser.jpg",
    imgCaption: "Red-breasted Merganser swimming in water.",
  },
  {
    index: 93,
    speciesID: 194,
    biomass: 0.0019375,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Ruddy Duck",
    trophicLevel: 2.822372666,
    desc: "Ruddy Ducks are a species of stiff-tailed ducks in North America. It is vulnerable to habitat loss, and mildly vulnerable to climate change.",
    imgFile: "ruddy.jpg",
    imgCaption: "Ruddy Dyck taking flight from water. ",
  },
  {
    index: 94,
    speciesID: 197,
    biomass: 0.0070175,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Semipalmated Plover",
    trophicLevel: 3.015802194,
    desc: "While the Semipalmated is widespread and common in coastal ecosystems across the United States, this species is considered to be highly vulnerable to climate change.",
    imgFile: "semi-plover.jpg",
    imgCaption: "Juvenile Semipalmated Plover walking in sand. ",
  },
  {
    index: 95,
    speciesID: 198,
    biomass: 0.0758358,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Snowy Egret",
    trophicLevel: 3.942601723,
    desc: "The Snowy Egret is a small species of egret that is found mostly in the Southern United States and Mexico. It is continuously recovering from historic population decreases and is not considered to be vulnerable to climate change.",
    imgFile: "snow-egret.jpg",
    imgCaption: "Snowy Egret standing on rocks near rushing water.",
  },
  {
    index: 96,
    speciesID: 201,
    biomass: 0.0003662,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Spotted Sandpiper",
    trophicLevel: 3.204371166,
    desc: "The Spotted Sandpiper is a comon and widespread bird. It is generally not threatened, however it is vulnerable to habitat loss.",
    imgFile: "spot-sandpiper.jpg",
    imgCaption: "Two Spotted Sandpipers standing in water.",
  },
  {
    index: 97,
    speciesID: 202,
    biomass: 0.0026295,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Surf Scoter",
    trophicLevel: 3.62008463,
    desc: "The Surf Scoter is a species of duck that migrates to the Pacific coast of the United States, where it is vulnerable to oil spills and other pollution.",
    imgFile: "surf-scoter.jpg",
    imgCaption: "Two Surf Scoters swimming in water.",
  },
  {
    index: 98,
    speciesID: 204,
    biomass: 0.0470025,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Turkey Vulture",
    trophicLevel: 1.0,
    desc: "Turkey Vultures are widespread across North America and are not considered to be vulnerable to climate change.",
    imgFile: "t-vulture.jpg",
    imgCaption: "Turkey Vulture flying.",
  },
  {
    index: 99,
    speciesID: 205,
    biomass: 0.0218222,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Grebe",
    trophicLevel: 4.214908253,
    desc: "The Western Grebe is a species of aquatic birds that migrates between fresh water lakes and brackish coastal waters in North America. While this species used to be targeted for its feathers, it is currently only vulnerable to climate change.",
    imgFile: "w-grebe.jpg",
    imgCaption: "Two Western Grebes swimming.",
  },
  {
    index: 100,
    speciesID: 207,
    biomass: 0.0001507,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Western Sandpiper",
    trophicLevel: 3.133525803,
    desc: "The Western Sandpiper is the smallest species of sandpipers and is unlikely to be significantly impacted by climate change.",
    imgFile: "west-sandpiper.jpg",
    imgCaption: "Western Sandpiper standing on branches above water.",
  },
  {
    index: 101,
    speciesID: 208,
    biomass: 0.0192895,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Whimbrel",
    trophicLevel: 3.640970312,
    desc: "Whimbrels are a shorebird that occurs in North America. They are highly vulnerable to climate change, and are still recovering from historically depleted populations.",
    imgFile: "whimbrel.jpg",
    imgCaption: "Whimbrel standing in shallow water.",
  },
  {
    index: 102,
    speciesID: 209,
    biomass: 0.7094073,
    organismType: "Bird",
    nodeColor: "Maroon-11",
    nodeShape: "Circle",
    nodeName: "Willet",
    trophicLevel: 3.371135015,
    desc: "Willets are large shorebirds that occur in prairie and salt marshes in North America. They are vulnerable to habitat disturbance and loss.",
    imgFile: "willet.jpg",
    imgCaption: "Willet flying over water.",
  },
  {
    index: 103,
    speciesID: 210,
    biomass: 0.9857072,
    organismType: "Mammal",
    nodeColor: "Orange/brown-13",
    nodeShape: "Circle",
    nodeName: "Raccoon",
    trophicLevel: 3.889077224,
    desc: "Racoons are medium-sized, nocturnal mammal. Nocturnal animals are chategorized by sleeping during the day and being active at night. Raccoons are considered to be very resilient animals.",
    imgFile: "raccoon.jpg",
    imgCaption: "Raccoon sitting in tree.",
  },
  {
    index: 104,
    speciesID: 211,
    biomass: 6.720049779,
    organismType: "Crustacean",
    nodeColor: "Teal-3",
    nodeShape: "Circle",
    nodeName: "Copepod-Thalassinidea",
    trophicLevel: 3.0,
    desc: "Copepod-Thalassinidea are small crustaceans. ",
    imgFile: "copepod.jpg",
    imgCaption: "Copepod.",
  },
  {
    index: 105,
    speciesID: 212,
    biomass: 95.2,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Salt Marsh Bird's Beak",
    trophicLevel: 2.0,
    desc: "Salt Marsh Bird's Beak is a rare flowering species.",
    imgFile: "",
    imgCaption: "",
  },
  {
    index: 106,
    speciesID: 213,
    biomass: 120.07843,
    organismType: "Plant",
    nodeColor: "Bright Green-14",
    nodeShape: "Circle",
    nodeName: "Dodder",
    trophicLevel: 2.0,
    desc: "Dodder is a plant species that occurs across the United States and Canada.",
    imgFile: "dodder.jpg",
    imgCaption: "Close up of a Dodder plant.",
  },
  {
    index: 107,
    speciesID: 350,
    biomass: 45684.9337,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Wave Attenuation",
    trophicLevel: 2.0,
    desc: "Wave attenuation is the decrease in wave intensity. Plants and other aquatic structures (e.g., coral reefs) play a large role in taming waves and violent storms that could damage shorelines.",
    imgFile: "wave.jpg",
    imgCaption: "Ocean waves.",
  },
  {
    index: 108,
    speciesID: 450,
    biomass: 47509.3846,
    organismType: "Ecosystem Service",
    nodeColor: "Dark Pink-4",
    nodeShape: "Square",
    nodeName: "Shoreline Protection",
    trophicLevel: 2.0,
    desc: "Shoreline protection is the strengthening and stabilizing of coastal areas. Naturally, plants with deep roots can prevent erosion, enhancing shoreline protection.",
    imgFile: "saltmarsh.jpg",
    imgCaption: "Saltmarsh.",
  },
];
export const edgeList7 = [
  { target: 1, source: 9, Type: "Feeding" },
  { target: 7, source: 10, Type: "Feeding" },
  { target: 7, source: 12, Type: "Feeding" },
  { target: 55, source: 13, Type: "Feeding" },
  { target: 57, source: 13, Type: "Feeding" },
  { target: 47, source: 14, Type: "Feeding" },
  { target: 10, source: 15, Type: "Feeding" },
  { target: 11, source: 15, Type: "Feeding" },
  { target: 12, source: 15, Type: "Feeding" },
  { target: 10, source: 16, Type: "Feeding" },
  { target: 11, source: 16, Type: "Feeding" },
  { target: 12, source: 16, Type: "Feeding" },
  { target: 7, source: 17, Type: "Feeding" },
  { target: 1, source: 18, Type: "Feeding" },
  { target: 7, source: 18, Type: "Feeding" },
  { target: 1, source: 19, Type: "Feeding" },
  { target: 7, source: 19, Type: "Feeding" },
  { target: 10, source: 19, Type: "Feeding" },
  { target: 12, source: 19, Type: "Feeding" },
  { target: 17, source: 19, Type: "Feeding" },
  { target: 18, source: 19, Type: "Feeding" },
  { target: 19, source: 19, Type: "Feeding" },
  { target: 20, source: 19, Type: "Feeding" },
  { target: 21, source: 19, Type: "Feeding" },
  { target: 22, source: 19, Type: "Feeding" },
  { target: 23, source: 19, Type: "Feeding" },
  { target: 24, source: 19, Type: "Feeding" },
  { target: 25, source: 19, Type: "Feeding" },
  { target: 26, source: 19, Type: "Feeding" },
  { target: 7, source: 20, Type: "Feeding" },
  { target: 1, source: 21, Type: "Feeding" },
  { target: 7, source: 21, Type: "Feeding" },
  { target: 10, source: 21, Type: "Feeding" },
  { target: 12, source: 21, Type: "Feeding" },
  { target: 17, source: 21, Type: "Feeding" },
  { target: 18, source: 21, Type: "Feeding" },
  { target: 19, source: 21, Type: "Feeding" },
  { target: 20, source: 21, Type: "Feeding" },
  { target: 21, source: 21, Type: "Feeding" },
  { target: 22, source: 21, Type: "Feeding" },
  { target: 23, source: 21, Type: "Feeding" },
  { target: 24, source: 21, Type: "Feeding" },
  { target: 25, source: 21, Type: "Feeding" },
  { target: 26, source: 21, Type: "Feeding" },
  { target: 1, source: 22, Type: "Feeding" },
  { target: 7, source: 22, Type: "Feeding" },
  { target: 1, source: 23, Type: "Feeding" },
  { target: 7, source: 23, Type: "Feeding" },
  { target: 10, source: 23, Type: "Feeding" },
  { target: 12, source: 23, Type: "Feeding" },
  { target: 17, source: 23, Type: "Feeding" },
  { target: 18, source: 23, Type: "Feeding" },
  { target: 19, source: 23, Type: "Feeding" },
  { target: 20, source: 23, Type: "Feeding" },
  { target: 21, source: 23, Type: "Feeding" },
  { target: 22, source: 23, Type: "Feeding" },
  { target: 23, source: 23, Type: "Feeding" },
  { target: 24, source: 23, Type: "Feeding" },
  { target: 25, source: 23, Type: "Feeding" },
  { target: 26, source: 23, Type: "Feeding" },
  { target: 7, source: 24, Type: "Feeding" },
  { target: 10, source: 24, Type: "Feeding" },
  { target: 12, source: 24, Type: "Feeding" },
  { target: 17, source: 24, Type: "Feeding" },
  { target: 18, source: 24, Type: "Feeding" },
  { target: 19, source: 24, Type: "Feeding" },
  { target: 20, source: 24, Type: "Feeding" },
  { target: 21, source: 24, Type: "Feeding" },
  { target: 22, source: 24, Type: "Feeding" },
  { target: 23, source: 24, Type: "Feeding" },
  { target: 24, source: 24, Type: "Feeding" },
  { target: 25, source: 24, Type: "Feeding" },
  { target: 26, source: 24, Type: "Feeding" },
  { target: 7, source: 25, Type: "Feeding" },
  { target: 10, source: 25, Type: "Feeding" },
  { target: 12, source: 25, Type: "Feeding" },
  { target: 17, source: 25, Type: "Feeding" },
  { target: 18, source: 25, Type: "Feeding" },
  { target: 19, source: 25, Type: "Feeding" },
  { target: 20, source: 25, Type: "Feeding" },
  { target: 21, source: 25, Type: "Feeding" },
  { target: 22, source: 25, Type: "Feeding" },
  { target: 23, source: 25, Type: "Feeding" },
  { target: 24, source: 25, Type: "Feeding" },
  { target: 25, source: 25, Type: "Feeding" },
  { target: 26, source: 25, Type: "Feeding" },
  { target: 7, source: 26, Type: "Feeding" },
  { target: 7, source: 17, Type: "Feeding" },
  { target: 7, source: 18, Type: "Feeding" },
  { target: 7, source: 19, Type: "Feeding" },
  { target: 7, source: 20, Type: "Feeding" },
  { target: 7, source: 21, Type: "Feeding" },
  { target: 7, source: 22, Type: "Feeding" },
  { target: 7, source: 23, Type: "Feeding" },
  { target: 7, source: 24, Type: "Feeding" },
  { target: 7, source: 25, Type: "Feeding" },
  { target: 7, source: 26, Type: "Feeding" },
  { target: 1, source: 27, Type: "Feeding" },
  { target: 1, source: 29, Type: "Feeding" },
  { target: 61, source: 31, Type: "Feeding" },
  { target: 62, source: 31, Type: "Feeding" },
  { target: 63, source: 31, Type: "Feeding" },
  { target: 64, source: 31, Type: "Feeding" },
  { target: 65, source: 31, Type: "Feeding" },
  { target: 66, source: 31, Type: "Feeding" },
  { target: 67, source: 31, Type: "Feeding" },
  { target: 68, source: 31, Type: "Feeding" },
  { target: 69, source: 31, Type: "Feeding" },
  { target: 70, source: 31, Type: "Feeding" },
  { target: 71, source: 31, Type: "Feeding" },
  { target: 72, source: 31, Type: "Feeding" },
  { target: 73, source: 31, Type: "Feeding" },
  { target: 74, source: 31, Type: "Feeding" },
  { target: 75, source: 31, Type: "Feeding" },
  { target: 76, source: 31, Type: "Feeding" },
  { target: 77, source: 31, Type: "Feeding" },
  { target: 78, source: 31, Type: "Feeding" },
  { target: 79, source: 31, Type: "Feeding" },
  { target: 80, source: 31, Type: "Feeding" },
  { target: 81, source: 31, Type: "Feeding" },
  { target: 82, source: 31, Type: "Feeding" },
  { target: 83, source: 31, Type: "Feeding" },
  { target: 84, source: 31, Type: "Feeding" },
  { target: 85, source: 31, Type: "Feeding" },
  { target: 86, source: 31, Type: "Feeding" },
  { target: 87, source: 31, Type: "Feeding" },
  { target: 88, source: 31, Type: "Feeding" },
  { target: 89, source: 31, Type: "Feeding" },
  { target: 90, source: 31, Type: "Feeding" },
  { target: 91, source: 31, Type: "Feeding" },
  { target: 92, source: 31, Type: "Feeding" },
  { target: 93, source: 31, Type: "Feeding" },
  { target: 94, source: 31, Type: "Feeding" },
  { target: 95, source: 31, Type: "Feeding" },
  { target: 96, source: 31, Type: "Feeding" },
  { target: 97, source: 31, Type: "Feeding" },
  { target: 98, source: 31, Type: "Feeding" },
  { target: 99, source: 31, Type: "Feeding" },
  { target: 100, source: 31, Type: "Feeding" },
  { target: 101, source: 31, Type: "Feeding" },
  { target: 102, source: 31, Type: "Feeding" },
  { target: 103, source: 31, Type: "Feeding" },
  { target: 51, source: 32, Type: "Feeding" },
  { target: 54, source: 32, Type: "Feeding" },
  { target: 61, source: 34, Type: "Feeding" },
  { target: 62, source: 34, Type: "Feeding" },
  { target: 63, source: 34, Type: "Feeding" },
  { target: 64, source: 34, Type: "Feeding" },
  { target: 65, source: 34, Type: "Feeding" },
  { target: 66, source: 34, Type: "Feeding" },
  { target: 67, source: 34, Type: "Feeding" },
  { target: 68, source: 34, Type: "Feeding" },
  { target: 69, source: 34, Type: "Feeding" },
  { target: 70, source: 34, Type: "Feeding" },
  { target: 71, source: 34, Type: "Feeding" },
  { target: 72, source: 34, Type: "Feeding" },
  { target: 73, source: 34, Type: "Feeding" },
  { target: 74, source: 34, Type: "Feeding" },
  { target: 75, source: 34, Type: "Feeding" },
  { target: 76, source: 34, Type: "Feeding" },
  { target: 77, source: 34, Type: "Feeding" },
  { target: 78, source: 34, Type: "Feeding" },
  { target: 79, source: 34, Type: "Feeding" },
  { target: 80, source: 34, Type: "Feeding" },
  { target: 81, source: 34, Type: "Feeding" },
  { target: 82, source: 34, Type: "Feeding" },
  { target: 83, source: 34, Type: "Feeding" },
  { target: 84, source: 34, Type: "Feeding" },
  { target: 85, source: 34, Type: "Feeding" },
  { target: 86, source: 34, Type: "Feeding" },
  { target: 87, source: 34, Type: "Feeding" },
  { target: 88, source: 34, Type: "Feeding" },
  { target: 89, source: 34, Type: "Feeding" },
  { target: 90, source: 34, Type: "Feeding" },
  { target: 91, source: 34, Type: "Feeding" },
  { target: 92, source: 34, Type: "Feeding" },
  { target: 93, source: 34, Type: "Feeding" },
  { target: 94, source: 34, Type: "Feeding" },
  { target: 95, source: 34, Type: "Feeding" },
  { target: 96, source: 34, Type: "Feeding" },
  { target: 97, source: 34, Type: "Feeding" },
  { target: 98, source: 34, Type: "Feeding" },
  { target: 99, source: 34, Type: "Feeding" },
  { target: 100, source: 34, Type: "Feeding" },
  { target: 101, source: 34, Type: "Feeding" },
  { target: 102, source: 34, Type: "Feeding" },
  { target: 103, source: 34, Type: "Feeding" },
  { target: 7, source: 35, Type: "Feeding" },
  { target: 1, source: 37, Type: "Feeding" },
  { target: 1, source: 38, Type: "Feeding" },
  { target: 5, source: 38, Type: "Feeding" },
  { target: 7, source: 41, Type: "Feeding" },
  { target: 36, source: 42, Type: "Feeding" },
  { target: 37, source: 42, Type: "Feeding" },
  { target: 40, source: 42, Type: "Feeding" },
  { target: 42, source: 42, Type: "Feeding" },
  { target: 43, source: 42, Type: "Feeding" },
  { target: 1, source: 44, Type: "Feeding" },
  { target: 1, source: 37, Type: "Feeding" },
  { target: 7, source: 45, Type: "Feeding" },
  { target: 7, source: 46, Type: "Feeding" },
  { target: 9, source: 46, Type: "Feeding" },
  { target: 10, source: 46, Type: "Feeding" },
  { target: 12, source: 46, Type: "Feeding" },
  { target: 17, source: 46, Type: "Feeding" },
  { target: 18, source: 46, Type: "Feeding" },
  { target: 19, source: 46, Type: "Feeding" },
  { target: 20, source: 46, Type: "Feeding" },
  { target: 21, source: 46, Type: "Feeding" },
  { target: 22, source: 46, Type: "Feeding" },
  { target: 23, source: 46, Type: "Feeding" },
  { target: 24, source: 46, Type: "Feeding" },
  { target: 25, source: 46, Type: "Feeding" },
  { target: 26, source: 46, Type: "Feeding" },
  { target: 35, source: 46, Type: "Feeding" },
  { target: 41, source: 46, Type: "Feeding" },
  { target: 1, source: 47, Type: "Feeding" },
  { target: 5, source: 47, Type: "Feeding" },
  { target: 10, source: 47, Type: "Feeding" },
  { target: 11, source: 47, Type: "Feeding" },
  { target: 12, source: 47, Type: "Feeding" },
  { target: 15, source: 47, Type: "Feeding" },
  { target: 16, source: 47, Type: "Feeding" },
  { target: 17, source: 47, Type: "Feeding" },
  { target: 18, source: 47, Type: "Feeding" },
  { target: 19, source: 47, Type: "Feeding" },
  { target: 20, source: 47, Type: "Feeding" },
  { target: 21, source: 47, Type: "Feeding" },
  { target: 22, source: 47, Type: "Feeding" },
  { target: 23, source: 47, Type: "Feeding" },
  { target: 24, source: 47, Type: "Feeding" },
  { target: 25, source: 47, Type: "Feeding" },
  { target: 26, source: 47, Type: "Feeding" },
  { target: 27, source: 47, Type: "Feeding" },
  { target: 36, source: 47, Type: "Feeding" },
  { target: 37, source: 47, Type: "Feeding" },
  { target: 36, source: 47, Type: "Feeding" },
  { target: 37, source: 47, Type: "Feeding" },
  { target: 1, source: 48, Type: "Feeding" },
  { target: 5, source: 48, Type: "Feeding" },
  { target: 8, source: 48, Type: "Feeding" },
  { target: 10, source: 48, Type: "Feeding" },
  { target: 11, source: 48, Type: "Feeding" },
  { target: 12, source: 48, Type: "Feeding" },
  { target: 14, source: 48, Type: "Feeding" },
  { target: 15, source: 48, Type: "Feeding" },
  { target: 16, source: 48, Type: "Feeding" },
  { target: 17, source: 48, Type: "Feeding" },
  { target: 18, source: 48, Type: "Feeding" },
  { target: 19, source: 48, Type: "Feeding" },
  { target: 20, source: 48, Type: "Feeding" },
  { target: 21, source: 48, Type: "Feeding" },
  { target: 22, source: 48, Type: "Feeding" },
  { target: 23, source: 48, Type: "Feeding" },
  { target: 24, source: 48, Type: "Feeding" },
  { target: 25, source: 48, Type: "Feeding" },
  { target: 26, source: 48, Type: "Feeding" },
  { target: 27, source: 48, Type: "Feeding" },
  { target: 29, source: 48, Type: "Feeding" },
  { target: 30, source: 48, Type: "Feeding" },
  { target: 38, source: 48, Type: "Feeding" },
  { target: 36, source: 48, Type: "Feeding" },
  { target: 37, source: 48, Type: "Feeding" },
  { target: 37, source: 48, Type: "Feeding" },
  { target: 47, source: 48, Type: "Feeding" },
  { target: 49, source: 48, Type: "Feeding" },
  { target: 106, source: 48, Type: "Feeding" },
  { target: 1, source: 49, Type: "Feeding" },
  { target: 5, source: 49, Type: "Feeding" },
  { target: 14, source: 50, Type: "Feeding" },
  { target: 33, source: 50, Type: "Feeding" },
  { target: 38, source: 50, Type: "Feeding" },
  { target: 39, source: 50, Type: "Feeding" },
  { target: 47, source: 50, Type: "Feeding" },
  { target: 48, source: 50, Type: "Feeding" },
  { target: 52, source: 50, Type: "Feeding" },
  { target: 55, source: 50, Type: "Feeding" },
  { target: 57, source: 50, Type: "Feeding" },
  { target: 5, source: 51, Type: "Feeding" },
  { target: 9, source: 51, Type: "Feeding" },
  { target: 10, source: 51, Type: "Feeding" },
  { target: 12, source: 51, Type: "Feeding" },
  { target: 17, source: 51, Type: "Feeding" },
  { target: 18, source: 51, Type: "Feeding" },
  { target: 19, source: 51, Type: "Feeding" },
  { target: 20, source: 51, Type: "Feeding" },
  { target: 21, source: 51, Type: "Feeding" },
  { target: 22, source: 51, Type: "Feeding" },
  { target: 23, source: 51, Type: "Feeding" },
  { target: 24, source: 51, Type: "Feeding" },
  { target: 25, source: 51, Type: "Feeding" },
  { target: 26, source: 51, Type: "Feeding" },
  { target: 35, source: 51, Type: "Feeding" },
  { target: 41, source: 51, Type: "Feeding" },
  { target: 9, source: 52, Type: "Feeding" },
  { target: 10, source: 52, Type: "Feeding" },
  { target: 12, source: 52, Type: "Feeding" },
  { target: 17, source: 52, Type: "Feeding" },
  { target: 18, source: 52, Type: "Feeding" },
  { target: 19, source: 52, Type: "Feeding" },
  { target: 20, source: 52, Type: "Feeding" },
  { target: 21, source: 52, Type: "Feeding" },
  { target: 22, source: 52, Type: "Feeding" },
  { target: 23, source: 52, Type: "Feeding" },
  { target: 24, source: 52, Type: "Feeding" },
  { target: 25, source: 52, Type: "Feeding" },
  { target: 26, source: 52, Type: "Feeding" },
  { target: 33, source: 52, Type: "Feeding" },
  { target: 38, source: 52, Type: "Feeding" },
  { target: 39, source: 52, Type: "Feeding" },
  { target: 41, source: 52, Type: "Feeding" },
  { target: 44, source: 52, Type: "Feeding" },
  { target: 33, source: 53, Type: "Feeding" },
  { target: 38, source: 53, Type: "Feeding" },
  { target: 39, source: 53, Type: "Feeding" },
  { target: 5, source: 54, Type: "Feeding" },
  { target: 10, source: 54, Type: "Feeding" },
  { target: 11, source: 54, Type: "Feeding" },
  { target: 12, source: 54, Type: "Feeding" },
  { target: 10, source: 54, Type: "Feeding" },
  { target: 12, source: 54, Type: "Feeding" },
  { target: 15, source: 54, Type: "Feeding" },
  { target: 16, source: 54, Type: "Feeding" },
  { target: 17, source: 54, Type: "Feeding" },
  { target: 18, source: 54, Type: "Feeding" },
  { target: 19, source: 54, Type: "Feeding" },
  { target: 20, source: 54, Type: "Feeding" },
  { target: 21, source: 54, Type: "Feeding" },
  { target: 22, source: 54, Type: "Feeding" },
  { target: 23, source: 54, Type: "Feeding" },
  { target: 24, source: 54, Type: "Feeding" },
  { target: 25, source: 54, Type: "Feeding" },
  { target: 26, source: 54, Type: "Feeding" },
  { target: 27, source: 54, Type: "Feeding" },
  { target: 28, source: 54, Type: "Feeding" },
  { target: 33, source: 54, Type: "Feeding" },
  { target: 35, source: 54, Type: "Feeding" },
  { target: 38, source: 54, Type: "Feeding" },
  { target: 39, source: 54, Type: "Feeding" },
  { target: 41, source: 54, Type: "Feeding" },
  { target: 44, source: 54, Type: "Feeding" },
  { target: 31, source: 54, Type: "Feeding" },
  { target: 34, source: 54, Type: "Feeding" },
  { target: 36, source: 54, Type: "Feeding" },
  { target: 37, source: 54, Type: "Feeding" },
  { target: 5, source: 55, Type: "Feeding" },
  { target: 14, source: 55, Type: "Feeding" },
  { target: 33, source: 55, Type: "Feeding" },
  { target: 38, source: 55, Type: "Feeding" },
  { target: 39, source: 55, Type: "Feeding" },
  { target: 40, source: 55, Type: "Feeding" },
  { target: 43, source: 55, Type: "Feeding" },
  { target: 44, source: 55, Type: "Feeding" },
  { target: 36, source: 55, Type: "Feeding" },
  { target: 37, source: 55, Type: "Feeding" },
  { target: 37, source: 55, Type: "Feeding" },
  { target: 45, source: 55, Type: "Feeding" },
  { target: 46, source: 55, Type: "Feeding" },
  { target: 47, source: 55, Type: "Feeding" },
  { target: 48, source: 55, Type: "Feeding" },
  { target: 49, source: 55, Type: "Feeding" },
  { target: 52, source: 55, Type: "Feeding" },
  { target: 53, source: 55, Type: "Feeding" },
  { target: 54, source: 55, Type: "Feeding" },
  { target: 104, source: 55, Type: "Feeding" },
  { target: 12, source: 56, Type: "Feeding" },
  { target: 14, source: 56, Type: "Feeding" },
  { target: 16, source: 56, Type: "Feeding" },
  { target: 18, source: 56, Type: "Feeding" },
  { target: 19, source: 56, Type: "Feeding" },
  { target: 21, source: 56, Type: "Feeding" },
  { target: 22, source: 56, Type: "Feeding" },
  { target: 23, source: 56, Type: "Feeding" },
  { target: 24, source: 56, Type: "Feeding" },
  { target: 25, source: 56, Type: "Feeding" },
  { target: 26, source: 56, Type: "Feeding" },
  { target: 33, source: 56, Type: "Feeding" },
  { target: 38, source: 56, Type: "Feeding" },
  { target: 39, source: 56, Type: "Feeding" },
  { target: 40, source: 56, Type: "Feeding" },
  { target: 43, source: 56, Type: "Feeding" },
  { target: 45, source: 56, Type: "Feeding" },
  { target: 46, source: 56, Type: "Feeding" },
  { target: 47, source: 56, Type: "Feeding" },
  { target: 48, source: 56, Type: "Feeding" },
  { target: 49, source: 56, Type: "Feeding" },
  { target: 52, source: 56, Type: "Feeding" },
  { target: 53, source: 56, Type: "Feeding" },
  { target: 54, source: 56, Type: "Feeding" },
  { target: 104, source: 56, Type: "Feeding" },
  { target: 14, source: 57, Type: "Feeding" },
  { target: 33, source: 57, Type: "Feeding" },
  { target: 38, source: 57, Type: "Feeding" },
  { target: 40, source: 57, Type: "Feeding" },
  { target: 43, source: 57, Type: "Feeding" },
  { target: 45, source: 57, Type: "Feeding" },
  { target: 46, source: 57, Type: "Feeding" },
  { target: 47, source: 57, Type: "Feeding" },
  { target: 48, source: 57, Type: "Feeding" },
  { target: 49, source: 57, Type: "Feeding" },
  { target: 52, source: 57, Type: "Feeding" },
  { target: 53, source: 57, Type: "Feeding" },
  { target: 54, source: 57, Type: "Feeding" },
  { target: 104, source: 57, Type: "Feeding" },
  { target: 14, source: 58, Type: "Feeding" },
  { target: 33, source: 58, Type: "Feeding" },
  { target: 38, source: 58, Type: "Feeding" },
  { target: 40, source: 58, Type: "Feeding" },
  { target: 43, source: 58, Type: "Feeding" },
  { target: 47, source: 58, Type: "Feeding" },
  { target: 48, source: 58, Type: "Feeding" },
  { target: 50, source: 58, Type: "Feeding" },
  { target: 52, source: 58, Type: "Feeding" },
  { target: 53, source: 58, Type: "Feeding" },
  { target: 54, source: 58, Type: "Feeding" },
  { target: 56, source: 58, Type: "Feeding" },
  { target: 58, source: 58, Type: "Feeding" },
  { target: 14, source: 59, Type: "Feeding" },
  { target: 18, source: 59, Type: "Feeding" },
  { target: 19, source: 59, Type: "Feeding" },
  { target: 21, source: 59, Type: "Feeding" },
  { target: 22, source: 59, Type: "Feeding" },
  { target: 23, source: 59, Type: "Feeding" },
  { target: 24, source: 59, Type: "Feeding" },
  { target: 25, source: 59, Type: "Feeding" },
  { target: 26, source: 59, Type: "Feeding" },
  { target: 45, source: 59, Type: "Feeding" },
  { target: 46, source: 59, Type: "Feeding" },
  { target: 47, source: 59, Type: "Feeding" },
  { target: 48, source: 59, Type: "Feeding" },
  { target: 49, source: 59, Type: "Feeding" },
  { target: 51, source: 59, Type: "Feeding" },
  { target: 52, source: 59, Type: "Feeding" },
  { target: 53, source: 59, Type: "Feeding" },
  { target: 54, source: 59, Type: "Feeding" },
  { target: 55, source: 59, Type: "Feeding" },
  { target: 56, source: 59, Type: "Feeding" },
  { target: 57, source: 59, Type: "Feeding" },
  { target: 58, source: 59, Type: "Feeding" },
  { target: 104, source: 59, Type: "Feeding" },
  { target: 14, source: 60, Type: "Feeding" },
  { target: 18, source: 60, Type: "Feeding" },
  { target: 19, source: 60, Type: "Feeding" },
  { target: 21, source: 60, Type: "Feeding" },
  { target: 22, source: 60, Type: "Feeding" },
  { target: 23, source: 60, Type: "Feeding" },
  { target: 24, source: 60, Type: "Feeding" },
  { target: 25, source: 60, Type: "Feeding" },
  { target: 26, source: 60, Type: "Feeding" },
  { target: 45, source: 60, Type: "Feeding" },
  { target: 46, source: 60, Type: "Feeding" },
  { target: 47, source: 60, Type: "Feeding" },
  { target: 48, source: 60, Type: "Feeding" },
  { target: 49, source: 60, Type: "Feeding" },
  { target: 52, source: 60, Type: "Feeding" },
  { target: 104, source: 60, Type: "Feeding" },
  { target: 10, source: 61, Type: "Feeding" },
  { target: 11, source: 61, Type: "Feeding" },
  { target: 12, source: 61, Type: "Feeding" },
  { target: 15, source: 61, Type: "Feeding" },
  { target: 16, source: 61, Type: "Feeding" },
  { target: 29, source: 61, Type: "Feeding" },
  { target: 36, source: 61, Type: "Feeding" },
  { target: 37, source: 61, Type: "Feeding" },
  { target: 44, source: 61, Type: "Feeding" },
  { target: 31, source: 61, Type: "Feeding" },
  { target: 34, source: 61, Type: "Feeding" },
  { target: 52, source: 61, Type: "Feeding" },
  { target: 54, source: 61, Type: "Feeding" },
  { target: 0, source: 62, Type: "Feeding" },
  { target: 2, source: 62, Type: "Feeding" },
  { target: 3, source: 62, Type: "Feeding" },
  { target: 4, source: 62, Type: "Feeding" },
  { target: 5, source: 62, Type: "Feeding" },
  { target: 6, source: 62, Type: "Feeding" },
  { target: 8, source: 62, Type: "Feeding" },
  { target: 18, source: 62, Type: "Feeding" },
  { target: 19, source: 62, Type: "Feeding" },
  { target: 21, source: 62, Type: "Feeding" },
  { target: 38, source: 62, Type: "Feeding" },
  { target: 105, source: 62, Type: "Feeding" },
  { target: 106, source: 62, Type: "Feeding" },
  { target: 14, source: 63, Type: "Feeding" },
  { target: 18, source: 63, Type: "Feeding" },
  { target: 19, source: 63, Type: "Feeding" },
  { target: 21, source: 63, Type: "Feeding" },
  { target: 22, source: 63, Type: "Feeding" },
  { target: 23, source: 63, Type: "Feeding" },
  { target: 24, source: 63, Type: "Feeding" },
  { target: 25, source: 63, Type: "Feeding" },
  { target: 26, source: 63, Type: "Feeding" },
  { target: 47, source: 63, Type: "Feeding" },
  { target: 48, source: 63, Type: "Feeding" },
  { target: 51, source: 63, Type: "Feeding" },
  { target: 7, source: 64, Type: "Feeding" },
  { target: 8, source: 64, Type: "Feeding" },
  { target: 27, source: 64, Type: "Feeding" },
  { target: 38, source: 64, Type: "Feeding" },
  { target: 31, source: 64, Type: "Feeding" },
  { target: 34, source: 64, Type: "Feeding" },
  { target: 36, source: 64, Type: "Feeding" },
  { target: 37, source: 64, Type: "Feeding" },
  { target: 36, source: 64, Type: "Feeding" },
  { target: 37, source: 64, Type: "Feeding" },
  { target: 106, source: 64, Type: "Feeding" },
  { target: 10, source: 65, Type: "Feeding" },
  { target: 11, source: 65, Type: "Feeding" },
  { target: 12, source: 65, Type: "Feeding" },
  { target: 14, source: 65, Type: "Feeding" },
  { target: 15, source: 65, Type: "Feeding" },
  { target: 16, source: 65, Type: "Feeding" },
  { target: 17, source: 65, Type: "Feeding" },
  { target: 18, source: 65, Type: "Feeding" },
  { target: 19, source: 65, Type: "Feeding" },
  { target: 20, source: 65, Type: "Feeding" },
  { target: 21, source: 65, Type: "Feeding" },
  { target: 22, source: 65, Type: "Feeding" },
  { target: 23, source: 65, Type: "Feeding" },
  { target: 29, source: 65, Type: "Feeding" },
  { target: 33, source: 65, Type: "Feeding" },
  { target: 36, source: 65, Type: "Feeding" },
  { target: 37, source: 65, Type: "Feeding" },
  { target: 38, source: 65, Type: "Feeding" },
  { target: 40, source: 65, Type: "Feeding" },
  { target: 43, source: 65, Type: "Feeding" },
  { target: 47, source: 65, Type: "Feeding" },
  { target: 48, source: 65, Type: "Feeding" },
  { target: 49, source: 65, Type: "Feeding" },
  { target: 52, source: 65, Type: "Feeding" },
  { target: 14, source: 66, Type: "Feeding" },
  { target: 47, source: 66, Type: "Feeding" },
  { target: 48, source: 66, Type: "Feeding" },
  { target: 49, source: 66, Type: "Feeding" },
  { target: 50, source: 66, Type: "Feeding" },
  { target: 51, source: 66, Type: "Feeding" },
  { target: 52, source: 66, Type: "Feeding" },
  { target: 53, source: 66, Type: "Feeding" },
  { target: 54, source: 66, Type: "Feeding" },
  { target: 55, source: 66, Type: "Feeding" },
  { target: 56, source: 66, Type: "Feeding" },
  { target: 57, source: 66, Type: "Feeding" },
  { target: 58, source: 66, Type: "Feeding" },
  { target: 51, source: 67, Type: "Feeding" },
  { target: 53, source: 67, Type: "Feeding" },
  { target: 54, source: 67, Type: "Feeding" },
  { target: 14, source: 68, Type: "Feeding" },
  { target: 18, source: 68, Type: "Feeding" },
  { target: 19, source: 68, Type: "Feeding" },
  { target: 20, source: 68, Type: "Feeding" },
  { target: 21, source: 68, Type: "Feeding" },
  { target: 22, source: 68, Type: "Feeding" },
  { target: 23, source: 68, Type: "Feeding" },
  { target: 24, source: 68, Type: "Feeding" },
  { target: 25, source: 68, Type: "Feeding" },
  { target: 26, source: 68, Type: "Feeding" },
  { target: 47, source: 68, Type: "Feeding" },
  { target: 48, source: 68, Type: "Feeding" },
  { target: 51, source: 68, Type: "Feeding" },
  { target: 14, source: 69, Type: "Feeding" },
  { target: 18, source: 69, Type: "Feeding" },
  { target: 19, source: 69, Type: "Feeding" },
  { target: 20, source: 69, Type: "Feeding" },
  { target: 21, source: 69, Type: "Feeding" },
  { target: 22, source: 69, Type: "Feeding" },
  { target: 23, source: 69, Type: "Feeding" },
  { target: 27, source: 69, Type: "Feeding" },
  { target: 29, source: 69, Type: "Feeding" },
  { target: 38, source: 69, Type: "Feeding" },
  { target: 47, source: 69, Type: "Feeding" },
  { target: 48, source: 69, Type: "Feeding" },
  { target: 49, source: 69, Type: "Feeding" },
  { target: 7, source: 70, Type: "Feeding" },
  { target: 8, source: 70, Type: "Feeding" },
  { target: 27, source: 70, Type: "Feeding" },
  { target: 38, source: 70, Type: "Feeding" },
  { target: 31, source: 70, Type: "Feeding" },
  { target: 34, source: 70, Type: "Feeding" },
  { target: 36, source: 70, Type: "Feeding" },
  { target: 37, source: 70, Type: "Feeding" },
  { target: 36, source: 70, Type: "Feeding" },
  { target: 37, source: 70, Type: "Feeding" },
  { target: 106, source: 70, Type: "Feeding" },
  { target: 14, source: 71, Type: "Feeding" },
  { target: 18, source: 71, Type: "Feeding" },
  { target: 19, source: 71, Type: "Feeding" },
  { target: 20, source: 71, Type: "Feeding" },
  { target: 21, source: 71, Type: "Feeding" },
  { target: 22, source: 71, Type: "Feeding" },
  { target: 23, source: 71, Type: "Feeding" },
  { target: 24, source: 71, Type: "Feeding" },
  { target: 25, source: 71, Type: "Feeding" },
  { target: 26, source: 71, Type: "Feeding" },
  { target: 47, source: 71, Type: "Feeding" },
  { target: 48, source: 71, Type: "Feeding" },
  { target: 51, source: 71, Type: "Feeding" },
  { target: 54, source: 71, Type: "Feeding" },
  { target: 51, source: 72, Type: "Feeding" },
  { target: 52, source: 72, Type: "Feeding" },
  { target: 53, source: 72, Type: "Feeding" },
  { target: 54, source: 72, Type: "Feeding" },
  { target: 7, source: 73, Type: "Feeding" },
  { target: 8, source: 73, Type: "Feeding" },
  { target: 27, source: 73, Type: "Feeding" },
  { target: 38, source: 73, Type: "Feeding" },
  { target: 31, source: 73, Type: "Feeding" },
  { target: 34, source: 73, Type: "Feeding" },
  { target: 36, source: 73, Type: "Feeding" },
  { target: 37, source: 73, Type: "Feeding" },
  { target: 36, source: 73, Type: "Feeding" },
  { target: 37, source: 73, Type: "Feeding" },
  { target: 106, source: 73, Type: "Feeding" },
  { target: 76, source: 74, Type: "Feeding" },
  { target: 86, source: 74, Type: "Feeding" },
  { target: 96, source: 74, Type: "Feeding" },
  { target: 100, source: 74, Type: "Feeding" },
  { target: 50, source: 75, Type: "Feeding" },
  { target: 51, source: 75, Type: "Feeding" },
  { target: 52, source: 75, Type: "Feeding" },
  { target: 53, source: 75, Type: "Feeding" },
  { target: 54, source: 75, Type: "Feeding" },
  { target: 55, source: 75, Type: "Feeding" },
  { target: 56, source: 75, Type: "Feeding" },
  { target: 57, source: 75, Type: "Feeding" },
  { target: 58, source: 75, Type: "Feeding" },
  { target: 10, source: 76, Type: "Feeding" },
  { target: 11, source: 76, Type: "Feeding" },
  { target: 12, source: 76, Type: "Feeding" },
  { target: 15, source: 76, Type: "Feeding" },
  { target: 16, source: 76, Type: "Feeding" },
  { target: 18, source: 76, Type: "Feeding" },
  { target: 19, source: 76, Type: "Feeding" },
  { target: 21, source: 76, Type: "Feeding" },
  { target: 22, source: 76, Type: "Feeding" },
  { target: 23, source: 76, Type: "Feeding" },
  { target: 29, source: 76, Type: "Feeding" },
  { target: 33, source: 76, Type: "Feeding" },
  { target: 38, source: 76, Type: "Feeding" },
  { target: 40, source: 76, Type: "Feeding" },
  { target: 43, source: 76, Type: "Feeding" },
  { target: 53, source: 76, Type: "Feeding" },
  { target: 54, source: 76, Type: "Feeding" },
  { target: 50, source: 77, Type: "Feeding" },
  { target: 51, source: 77, Type: "Feeding" },
  { target: 52, source: 77, Type: "Feeding" },
  { target: 53, source: 77, Type: "Feeding" },
  { target: 54, source: 77, Type: "Feeding" },
  { target: 55, source: 77, Type: "Feeding" },
  { target: 56, source: 77, Type: "Feeding" },
  { target: 57, source: 77, Type: "Feeding" },
  { target: 58, source: 77, Type: "Feeding" },
  { target: 50, source: 78, Type: "Feeding" },
  { target: 51, source: 78, Type: "Feeding" },
  { target: 52, source: 78, Type: "Feeding" },
  { target: 53, source: 78, Type: "Feeding" },
  { target: 54, source: 78, Type: "Feeding" },
  { target: 55, source: 78, Type: "Feeding" },
  { target: 56, source: 78, Type: "Feeding" },
  { target: 57, source: 78, Type: "Feeding" },
  { target: 58, source: 78, Type: "Feeding" },
  { target: 51, source: 79, Type: "Feeding" },
  { target: 52, source: 79, Type: "Feeding" },
  { target: 53, source: 79, Type: "Feeding" },
  { target: 54, source: 79, Type: "Feeding" },
  { target: 10, source: 80, Type: "Feeding" },
  { target: 11, source: 80, Type: "Feeding" },
  { target: 12, source: 80, Type: "Feeding" },
  { target: 15, source: 80, Type: "Feeding" },
  { target: 16, source: 80, Type: "Feeding" },
  { target: 29, source: 80, Type: "Feeding" },
  { target: 36, source: 80, Type: "Feeding" },
  { target: 37, source: 80, Type: "Feeding" },
  { target: 31, source: 80, Type: "Feeding" },
  { target: 34, source: 80, Type: "Feeding" },
  { target: 52, source: 80, Type: "Feeding" },
  { target: 7, source: 81, Type: "Feeding" },
  { target: 8, source: 81, Type: "Feeding" },
  { target: 27, source: 81, Type: "Feeding" },
  { target: 38, source: 81, Type: "Feeding" },
  { target: 31, source: 81, Type: "Feeding" },
  { target: 34, source: 81, Type: "Feeding" },
  { target: 36, source: 81, Type: "Feeding" },
  { target: 37, source: 81, Type: "Feeding" },
  { target: 37, source: 81, Type: "Feeding" },
  { target: 106, source: 81, Type: "Feeding" },
  { target: 36, source: 82, Type: "Feeding" },
  { target: 37, source: 82, Type: "Feeding" },
  { target: 40, source: 82, Type: "Feeding" },
  { target: 43, source: 82, Type: "Feeding" },
  { target: 14, source: 83, Type: "Feeding" },
  { target: 17, source: 83, Type: "Feeding" },
  { target: 24, source: 83, Type: "Feeding" },
  { target: 25, source: 83, Type: "Feeding" },
  { target: 26, source: 83, Type: "Feeding" },
  { target: 45, source: 83, Type: "Feeding" },
  { target: 46, source: 83, Type: "Feeding" },
  { target: 47, source: 83, Type: "Feeding" },
  { target: 48, source: 83, Type: "Feeding" },
  { target: 49, source: 83, Type: "Feeding" },
  { target: 52, source: 83, Type: "Feeding" },
  { target: 104, source: 83, Type: "Feeding" },
  { target: 10, source: 84, Type: "Feeding" },
  { target: 11, source: 84, Type: "Feeding" },
  { target: 12, source: 84, Type: "Feeding" },
  { target: 14, source: 84, Type: "Feeding" },
  { target: 15, source: 84, Type: "Feeding" },
  { target: 16, source: 84, Type: "Feeding" },
  { target: 18, source: 84, Type: "Feeding" },
  { target: 19, source: 84, Type: "Feeding" },
  { target: 21, source: 84, Type: "Feeding" },
  { target: 22, source: 84, Type: "Feeding" },
  { target: 23, source: 84, Type: "Feeding" },
  { target: 24, source: 84, Type: "Feeding" },
  { target: 25, source: 84, Type: "Feeding" },
  { target: 26, source: 84, Type: "Feeding" },
  { target: 27, source: 84, Type: "Feeding" },
  { target: 28, source: 84, Type: "Feeding" },
  { target: 29, source: 84, Type: "Feeding" },
  { target: 30, source: 84, Type: "Feeding" },
  { target: 33, source: 84, Type: "Feeding" },
  { target: 38, source: 84, Type: "Feeding" },
  { target: 36, source: 84, Type: "Feeding" },
  { target: 37, source: 84, Type: "Feeding" },
  { target: 36, source: 84, Type: "Feeding" },
  { target: 37, source: 84, Type: "Feeding" },
  { target: 47, source: 84, Type: "Feeding" },
  { target: 52, source: 84, Type: "Feeding" },
  { target: 54, source: 84, Type: "Feeding" },
  { target: 14, source: 85, Type: "Feeding" },
  { target: 36, source: 85, Type: "Feeding" },
  { target: 37, source: 85, Type: "Feeding" },
  { target: 47, source: 85, Type: "Feeding" },
  { target: 48, source: 85, Type: "Feeding" },
  { target: 49, source: 85, Type: "Feeding" },
  { target: 50, source: 85, Type: "Feeding" },
  { target: 51, source: 85, Type: "Feeding" },
  { target: 52, source: 85, Type: "Feeding" },
  { target: 53, source: 85, Type: "Feeding" },
  { target: 54, source: 85, Type: "Feeding" },
  { target: 55, source: 85, Type: "Feeding" },
  { target: 56, source: 85, Type: "Feeding" },
  { target: 57, source: 85, Type: "Feeding" },
  { target: 58, source: 85, Type: "Feeding" },
  { target: 10, source: 86, Type: "Feeding" },
  { target: 11, source: 86, Type: "Feeding" },
  { target: 12, source: 86, Type: "Feeding" },
  { target: 15, source: 86, Type: "Feeding" },
  { target: 16, source: 86, Type: "Feeding" },
  { target: 18, source: 86, Type: "Feeding" },
  { target: 19, source: 86, Type: "Feeding" },
  { target: 21, source: 86, Type: "Feeding" },
  { target: 29, source: 86, Type: "Feeding" },
  { target: 36, source: 86, Type: "Feeding" },
  { target: 37, source: 86, Type: "Feeding" },
  { target: 38, source: 86, Type: "Feeding" },
  { target: 39, source: 86, Type: "Feeding" },
  { target: 40, source: 86, Type: "Feeding" },
  { target: 43, source: 86, Type: "Feeding" },
  { target: 36, source: 86, Type: "Feeding" },
  { target: 37, source: 86, Type: "Feeding" },
  { target: 36, source: 86, Type: "Feeding" },
  { target: 37, source: 86, Type: "Feeding" },
  { target: 53, source: 86, Type: "Feeding" },
  { target: 54, source: 86, Type: "Feeding" },
  { target: 10, source: 87, Type: "Feeding" },
  { target: 11, source: 87, Type: "Feeding" },
  { target: 12, source: 87, Type: "Feeding" },
  { target: 14, source: 87, Type: "Feeding" },
  { target: 15, source: 87, Type: "Feeding" },
  { target: 16, source: 87, Type: "Feeding" },
  { target: 18, source: 87, Type: "Feeding" },
  { target: 19, source: 87, Type: "Feeding" },
  { target: 21, source: 87, Type: "Feeding" },
  { target: 22, source: 87, Type: "Feeding" },
  { target: 23, source: 87, Type: "Feeding" },
  { target: 30, source: 87, Type: "Feeding" },
  { target: 33, source: 87, Type: "Feeding" },
  { target: 38, source: 87, Type: "Feeding" },
  { target: 40, source: 87, Type: "Feeding" },
  { target: 43, source: 87, Type: "Feeding" },
  { target: 47, source: 87, Type: "Feeding" },
  { target: 48, source: 87, Type: "Feeding" },
  { target: 49, source: 87, Type: "Feeding" },
  { target: 52, source: 87, Type: "Feeding" },
  { target: 0, source: 88, Type: "Feeding" },
  { target: 2, source: 88, Type: "Feeding" },
  { target: 3, source: 88, Type: "Feeding" },
  { target: 4, source: 88, Type: "Feeding" },
  { target: 5, source: 88, Type: "Feeding" },
  { target: 6, source: 88, Type: "Feeding" },
  { target: 8, source: 88, Type: "Feeding" },
  { target: 27, source: 88, Type: "Feeding" },
  { target: 29, source: 88, Type: "Feeding" },
  { target: 38, source: 88, Type: "Feeding" },
  { target: 31, source: 88, Type: "Feeding" },
  { target: 34, source: 88, Type: "Feeding" },
  { target: 36, source: 88, Type: "Feeding" },
  { target: 37, source: 88, Type: "Feeding" },
  { target: 37, source: 88, Type: "Feeding" },
  { target: 105, source: 88, Type: "Feeding" },
  { target: 106, source: 88, Type: "Feeding" },
  { target: 14, source: 89, Type: "Feeding" },
  { target: 18, source: 89, Type: "Feeding" },
  { target: 19, source: 89, Type: "Feeding" },
  { target: 20, source: 89, Type: "Feeding" },
  { target: 21, source: 89, Type: "Feeding" },
  { target: 22, source: 89, Type: "Feeding" },
  { target: 23, source: 89, Type: "Feeding" },
  { target: 24, source: 89, Type: "Feeding" },
  { target: 25, source: 89, Type: "Feeding" },
  { target: 26, source: 89, Type: "Feeding" },
  { target: 47, source: 89, Type: "Feeding" },
  { target: 48, source: 89, Type: "Feeding" },
  { target: 51, source: 89, Type: "Feeding" },
  { target: 14, source: 90, Type: "Feeding" },
  { target: 38, source: 90, Type: "Feeding" },
  { target: 47, source: 90, Type: "Feeding" },
  { target: 48, source: 90, Type: "Feeding" },
  { target: 49, source: 90, Type: "Feeding" },
  { target: 50, source: 90, Type: "Feeding" },
  { target: 51, source: 90, Type: "Feeding" },
  { target: 52, source: 90, Type: "Feeding" },
  { target: 53, source: 90, Type: "Feeding" },
  { target: 54, source: 90, Type: "Feeding" },
  { target: 55, source: 90, Type: "Feeding" },
  { target: 56, source: 90, Type: "Feeding" },
  { target: 57, source: 90, Type: "Feeding" },
  { target: 58, source: 90, Type: "Feeding" },
  { target: 14, source: 91, Type: "Feeding" },
  { target: 18, source: 91, Type: "Feeding" },
  { target: 19, source: 91, Type: "Feeding" },
  { target: 20, source: 91, Type: "Feeding" },
  { target: 21, source: 91, Type: "Feeding" },
  { target: 22, source: 91, Type: "Feeding" },
  { target: 23, source: 91, Type: "Feeding" },
  { target: 24, source: 91, Type: "Feeding" },
  { target: 25, source: 91, Type: "Feeding" },
  { target: 26, source: 91, Type: "Feeding" },
  { target: 47, source: 91, Type: "Feeding" },
  { target: 48, source: 91, Type: "Feeding" },
  { target: 51, source: 91, Type: "Feeding" },
  { target: 50, source: 92, Type: "Feeding" },
  { target: 51, source: 92, Type: "Feeding" },
  { target: 52, source: 92, Type: "Feeding" },
  { target: 53, source: 92, Type: "Feeding" },
  { target: 54, source: 92, Type: "Feeding" },
  { target: 55, source: 92, Type: "Feeding" },
  { target: 56, source: 92, Type: "Feeding" },
  { target: 57, source: 92, Type: "Feeding" },
  { target: 58, source: 92, Type: "Feeding" },
  { target: 0, source: 93, Type: "Feeding" },
  { target: 2, source: 93, Type: "Feeding" },
  { target: 3, source: 93, Type: "Feeding" },
  { target: 5, source: 93, Type: "Feeding" },
  { target: 6, source: 93, Type: "Feeding" },
  { target: 8, source: 93, Type: "Feeding" },
  { target: 9, source: 93, Type: "Feeding" },
  { target: 10, source: 93, Type: "Feeding" },
  { target: 11, source: 93, Type: "Feeding" },
  { target: 12, source: 93, Type: "Feeding" },
  { target: 16, source: 93, Type: "Feeding" },
  { target: 18, source: 93, Type: "Feeding" },
  { target: 19, source: 93, Type: "Feeding" },
  { target: 27, source: 93, Type: "Feeding" },
  { target: 29, source: 93, Type: "Feeding" },
  { target: 33, source: 93, Type: "Feeding" },
  { target: 38, source: 93, Type: "Feeding" },
  { target: 39, source: 93, Type: "Feeding" },
  { target: 31, source: 93, Type: "Feeding" },
  { target: 34, source: 93, Type: "Feeding" },
  { target: 36, source: 93, Type: "Feeding" },
  { target: 37, source: 93, Type: "Feeding" },
  { target: 36, source: 93, Type: "Feeding" },
  { target: 37, source: 93, Type: "Feeding" },
  { target: 106, source: 93, Type: "Feeding" },
  { target: 10, source: 94, Type: "Feeding" },
  { target: 11, source: 94, Type: "Feeding" },
  { target: 12, source: 94, Type: "Feeding" },
  { target: 15, source: 94, Type: "Feeding" },
  { target: 16, source: 94, Type: "Feeding" },
  { target: 18, source: 94, Type: "Feeding" },
  { target: 19, source: 94, Type: "Feeding" },
  { target: 21, source: 94, Type: "Feeding" },
  { target: 33, source: 94, Type: "Feeding" },
  { target: 36, source: 94, Type: "Feeding" },
  { target: 37, source: 94, Type: "Feeding" },
  { target: 14, source: 95, Type: "Feeding" },
  { target: 36, source: 95, Type: "Feeding" },
  { target: 37, source: 95, Type: "Feeding" },
  { target: 47, source: 95, Type: "Feeding" },
  { target: 48, source: 95, Type: "Feeding" },
  { target: 49, source: 95, Type: "Feeding" },
  { target: 50, source: 95, Type: "Feeding" },
  { target: 51, source: 95, Type: "Feeding" },
  { target: 52, source: 95, Type: "Feeding" },
  { target: 53, source: 95, Type: "Feeding" },
  { target: 54, source: 95, Type: "Feeding" },
  { target: 55, source: 95, Type: "Feeding" },
  { target: 56, source: 95, Type: "Feeding" },
  { target: 57, source: 95, Type: "Feeding" },
  { target: 58, source: 95, Type: "Feeding" },
  { target: 10, source: 96, Type: "Feeding" },
  { target: 11, source: 96, Type: "Feeding" },
  { target: 12, source: 96, Type: "Feeding" },
  { target: 14, source: 96, Type: "Feeding" },
  { target: 15, source: 96, Type: "Feeding" },
  { target: 16, source: 96, Type: "Feeding" },
  { target: 17, source: 96, Type: "Feeding" },
  { target: 18, source: 96, Type: "Feeding" },
  { target: 19, source: 96, Type: "Feeding" },
  { target: 21, source: 96, Type: "Feeding" },
  { target: 27, source: 96, Type: "Feeding" },
  { target: 29, source: 96, Type: "Feeding" },
  { target: 33, source: 96, Type: "Feeding" },
  { target: 36, source: 96, Type: "Feeding" },
  { target: 37, source: 96, Type: "Feeding" },
  { target: 47, source: 96, Type: "Feeding" },
  { target: 14, source: 97, Type: "Feeding" },
  { target: 18, source: 97, Type: "Feeding" },
  { target: 19, source: 97, Type: "Feeding" },
  { target: 20, source: 97, Type: "Feeding" },
  { target: 21, source: 97, Type: "Feeding" },
  { target: 22, source: 97, Type: "Feeding" },
  { target: 23, source: 97, Type: "Feeding" },
  { target: 24, source: 97, Type: "Feeding" },
  { target: 25, source: 97, Type: "Feeding" },
  { target: 26, source: 97, Type: "Feeding" },
  { target: 29, source: 97, Type: "Feeding" },
  { target: 38, source: 97, Type: "Feeding" },
  { target: 45, source: 97, Type: "Feeding" },
  { target: 46, source: 97, Type: "Feeding" },
  { target: 47, source: 97, Type: "Feeding" },
  { target: 48, source: 97, Type: "Feeding" },
  { target: 49, source: 97, Type: "Feeding" },
  { target: 104, source: 97, Type: "Feeding" },
  { target: 50, source: 99, Type: "Feeding" },
  { target: 51, source: 99, Type: "Feeding" },
  { target: 52, source: 99, Type: "Feeding" },
  { target: 53, source: 99, Type: "Feeding" },
  { target: 54, source: 99, Type: "Feeding" },
  { target: 55, source: 99, Type: "Feeding" },
  { target: 56, source: 99, Type: "Feeding" },
  { target: 57, source: 99, Type: "Feeding" },
  { target: 58, source: 99, Type: "Feeding" },
  { target: 1, source: 100, Type: "Feeding" },
  { target: 10, source: 100, Type: "Feeding" },
  { target: 11, source: 100, Type: "Feeding" },
  { target: 12, source: 100, Type: "Feeding" },
  { target: 14, source: 100, Type: "Feeding" },
  { target: 15, source: 100, Type: "Feeding" },
  { target: 16, source: 100, Type: "Feeding" },
  { target: 17, source: 100, Type: "Feeding" },
  { target: 18, source: 100, Type: "Feeding" },
  { target: 19, source: 100, Type: "Feeding" },
  { target: 21, source: 100, Type: "Feeding" },
  { target: 27, source: 100, Type: "Feeding" },
  { target: 29, source: 100, Type: "Feeding" },
  { target: 33, source: 100, Type: "Feeding" },
  { target: 36, source: 100, Type: "Feeding" },
  { target: 37, source: 100, Type: "Feeding" },
  { target: 47, source: 100, Type: "Feeding" },
  { target: 14, source: 101, Type: "Feeding" },
  { target: 30, source: 101, Type: "Feeding" },
  { target: 47, source: 101, Type: "Feeding" },
  { target: 48, source: 101, Type: "Feeding" },
  { target: 49, source: 101, Type: "Feeding" },
  { target: 10, source: 102, Type: "Feeding" },
  { target: 11, source: 102, Type: "Feeding" },
  { target: 12, source: 102, Type: "Feeding" },
  { target: 14, source: 102, Type: "Feeding" },
  { target: 15, source: 102, Type: "Feeding" },
  { target: 16, source: 102, Type: "Feeding" },
  { target: 17, source: 102, Type: "Feeding" },
  { target: 18, source: 102, Type: "Feeding" },
  { target: 19, source: 102, Type: "Feeding" },
  { target: 20, source: 102, Type: "Feeding" },
  { target: 21, source: 102, Type: "Feeding" },
  { target: 22, source: 102, Type: "Feeding" },
  { target: 23, source: 102, Type: "Feeding" },
  { target: 24, source: 102, Type: "Feeding" },
  { target: 25, source: 102, Type: "Feeding" },
  { target: 26, source: 102, Type: "Feeding" },
  { target: 29, source: 102, Type: "Feeding" },
  { target: 33, source: 102, Type: "Feeding" },
  { target: 38, source: 102, Type: "Feeding" },
  { target: 40, source: 102, Type: "Feeding" },
  { target: 43, source: 102, Type: "Feeding" },
  { target: 31, source: 102, Type: "Feeding" },
  { target: 34, source: 102, Type: "Feeding" },
  { target: 36, source: 102, Type: "Feeding" },
  { target: 37, source: 102, Type: "Feeding" },
  { target: 36, source: 102, Type: "Feeding" },
  { target: 37, source: 102, Type: "Feeding" },
  { target: 45, source: 102, Type: "Feeding" },
  { target: 46, source: 102, Type: "Feeding" },
  { target: 47, source: 102, Type: "Feeding" },
  { target: 48, source: 102, Type: "Feeding" },
  { target: 49, source: 102, Type: "Feeding" },
  { target: 52, source: 102, Type: "Feeding" },
  { target: 104, source: 102, Type: "Feeding" },
  { target: 14, source: 103, Type: "Feeding" },
  { target: 47, source: 103, Type: "Feeding" },
  { target: 48, source: 103, Type: "Feeding" },
  { target: 49, source: 103, Type: "Feeding" },
  { target: 51, source: 103, Type: "Feeding" },
  { target: 62, source: 103, Type: "Feeding" },
  { target: 82, source: 103, Type: "Feeding" },
  { target: 88, source: 103, Type: "Feeding" },
  { target: 45, source: 104, Type: "Feeding" },
  { target: 0, source: 105, Type: "Feeding" },
  { target: 8, source: 105, Type: "Feeding" },
  { target: 0, source: 106, Type: "Feeding" },
  { target: 2, source: 106, Type: "Feeding" },
  { target: 3, source: 106, Type: "Feeding" },
  { target: 6, source: 106, Type: "Feeding" },
  { target: 8, source: 106, Type: "Feeding" },
  { target: 0, source: 107, Type: "ES" },
  { target: 2, source: 107, Type: "ES" },
  { target: 3, source: 107, Type: "ES" },
  { target: 4, source: 107, Type: "ES" },
  { target: 6, source: 107, Type: "ES" },
  { target: 8, source: 107, Type: "ES" },
  { target: 0, source: 108, Type: "ES" },
  { target: 1, source: 108, Type: "ES" },
  { target: 2, source: 108, Type: "ES" },
  { target: 3, source: 108, Type: "ES" },
  { target: 4, source: 108, Type: "ES" },
  { target: 6, source: 108, Type: "ES" },
  { target: 7, source: 108, Type: "ES" },
  { target: 8, source: 108, Type: "ES" },
];

export const colors = [
  { name: "Black-1", hex: "#000" },
  { name: "Dark Teal-2", hex: "#004949" },
  { name: "Teal-3", hex: "#009292" },
  { name: "Dark Pink-4", hex: "#ff6db6" },
  { name: "Pink-5", hex: "#ffb6db" },
  { name: "Dark Purple-6", hex: "#490092" },
  { name: "Blue-7", hex: "#006ddb" },
  { name: "Purple-8", hex: "#b66dff" },
  { name: "Sky Blue-9", hex: "#6db6ff" },
  { name: "Light Blue-10", hex: "#b6dbff" },
  { name: "Maroon-11", hex: "#920000" },
  { name: "Brown-12", hex: "#924900" },
  { name: "Orange/brown-13", hex: "#db6d00" },
  { name: "Bright Green-14", hex: "#24ff24" },
  { name: "Light Yellow-15", hex: "#ffff6d" },
];

export const imgCredit = [
  {
    ImageFile: "salt-grass.jpg",
    ImageSource: "rkshiningtiger",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "heath.jpg",
    ImageSource: "USFWS Pacific Southwest Region",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "fleshy-j.jpg",
    ImageSource: "Franco Folini",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "ca-lav.jpg",
    ImageSource: "jodylehigh at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "macro.jpg",
    ImageSource: "Raquel Sanchez de Pedro",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "shore-grass.jpg",
    ImageSource: "AnnaER at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "phyto.jpg",
    ImageSource: "NEON ja",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "pickle.jpg",
    ImageSource: "Olivier",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "zoo.jpg",
    ImageSource:
      "Adriana Zingone, Domenico D'Alelio, Maria Grazia Mazzocchi, Marina Montresor, Diana Sarno, LTER-MC team",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "capitella.jpg",
    ImageSource: "EcologyWA",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Oligochaeta.jpg",
    ImageSource: "Erin Hayes-Pontius",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "leech.jpg",
    ImageSource:
      "Krittiya Chiangkul, Poramad Tricalairat and Watchariya Purivirojkul",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Carcinonemertes.jpg",
    ImageSource: "Christian Ferrer",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "nemeterea.jpg",
    ImageSource: "Rob Growler",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "softshell-ca.jpg",
    ImageSource: "Georges Jansoone",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "b-mussel.jpg",
    ImageSource: "Andreas Trepte",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "butt-clam.jpg",
    ImageSource: "Cody Logan",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "jack-clam.jpg",
    ImageSource: "North Madison",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "sea-snail.jpg",
    ImageSource: "Siegella",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "assiminea.jpg",
    ImageSource: "Whelan, NV, Johnson, PD, Harris, PM",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "ca-horn.jpg",
    ImageSource: "H. Zell",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "snail.jpg",
    ImageSource: "Josve05a",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "mosquito.jpg",
    ImageSource: "Haneef_honey",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "argulus.jpg",
    ImageSource: "Donald Hobern",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Corophiid.jpg",
    ImageSource: "Hans Hillewaert",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "copepod.jpg",
    ImageSource: "Jacabo Werther",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "fly.jpg",
    ImageSource: "fjcorado",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "amphipod.jpg",
    ImageSource: "Uwe Kils",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Oniscidean.jpg",
    ImageSource: "Ryan Hodnett",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Pardosa.jpg",
    ImageSource: "Alvesgaspar",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "ghost-shrimp.jpg",
    ImageSource: "Hans Hillewaert",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "mud-shrimp.jpg",
    ImageSource: "Hans Hillewaert",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "g-crab.jpg",
    ImageSource: "Ansgar Gruber",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "stripe-crab.jpg",
    ImageSource: "Rudolphous",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "fiddler.jpg",
    ImageSource: "NeedsPixels at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "goby.jpg",
    ImageSource: "Peter van der Sluijis",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "topsmelt.jpg",
    ImageSource: "Theo Modder",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "surfperch.jpg",
    ImageSource: "David Stang",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "turbot.jpg",
    ImageSource: "Matthieu Godbout (assumed based on copyright)",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "sculpin.jpg",
    ImageSource: "Stuart Halewood",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "halibut.jpg",
    ImageSource: "Kolmkolm",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "leo-shark.jpg",
    ImageSource: "Clark Anderson",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "Urobatis.jpg",
    ImageSource: "David Stang",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "avocet.jpg",
    ImageSource: "Raymond M. Coveney",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "amer-coot.jpg",
    ImageSource: "fletchershauna at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "crow.jpg",
    ImageSource: "Jasmin_Sessler at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "amer-wigeon.jpg",
    ImageSource: "Alan Wilson",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "bb-plover.jpg",
    ImageSource: "Caleb Putnam",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "night-heron.jpg",
    ImageSource: "lucas_pego at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "kingfisher.jpg",
    ImageSource: "JudaM at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "bonaparte.jpg",
    ImageSource: "tdfugere at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "bufflehead.jpg",
    ImageSource: "bryanhanson1956 at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "bw-teal.jpg",
    ImageSource: "HarmonyonPlanetEarth",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "ca-gull.jpg",
    ImageSource: "raggio5",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "c-tern.jpg",
    ImageSource: "Francesco Veronesi",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "cinn-teal.jpg",
    ImageSource: "jimsimmons at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "c-hawk.jpg",
    ImageSource: "wileydoc at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "cormorant.jpg",
    ImageSource: "Alan Vernon",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "dunlin.jpg",
    ImageSource: "Georg-Wietschorke at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "heron.jpg",
    ImageSource: "PublicDomainImages at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "egret.jpg",
    ImageSource: "sschema at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "green-heron.jpg",
    ImageSource: "zoosnow at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "g-yellow.jpg",
    ImageSource: "ftmartens at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "gw-teal.jpg",
    ImageSource: "Gennaro-Leonardi at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "killdeer.jpg",
    ImageSource: "Kenroy at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "lb-curlew.jpg",
    ImageSource: "Hasheem5 at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "lb-dowitcher.jpg",
    ImageSource: "Francesco Veronesi",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "lb-heron.jpg",
    ImageSource: "NextRoy at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "least-sand.jpg",
    ImageSource: "Alan Vernon",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "m-godwit.jpg",
    ImageSource: "moonietunes at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "mallard.jpg",
    ImageSource: "Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "gull.jpg",
    ImageSource: "PublicDomainPictures at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "pied-grebe.jpg",
    ImageSource: "Alan Vernon",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "rb-gull.jpg",
    ImageSource: "jodylehigh at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "merganser.jpg",
    ImageSource: "Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "ruddy.jpg",
    ImageSource: "Lancier at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "semi-plover.jpg",
    ImageSource: "Alan Vernon",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "snow-egret.jpg",
    ImageSource: "cegoh at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "spot-sandpiper.jpg",
    ImageSource: "sarangib at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "surf-scoter.jpg",
    ImageSource: "”mar RunÛlfsson",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "t-vulture.jpg",
    ImageSource: "Lostangelino at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "w-grebe.jpg",
    ImageSource: "Krazytea",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "west-sandpiper.jpg",
    ImageSource: "Ron Knight",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "whimbrel.jpg",
    ImageSource: "Ron Knight",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "willet.jpg",
    ImageSource: "Magnus Manske",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "raccoon.jpg",
    ImageSource: "Alexas_Fotos at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "dodder.jpg",
    ImageSource: "Jason Hollinger",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "wave.jpg",
    ImageSource: "Pexels at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "saltmarsh.jpg",
    ImageSource: "Emphyrio at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "grasses.jpg",
    ImageSource: "Nennieinszweidrei at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "carbon.jpg",
    ImageSource: "jeanlouisservais at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "insect.jpg",
    ImageSource: "liggraphy at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "plankton.jpg",
    ImageSource: "Fabianduran at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "comm-fish.jpg",
    ImageSource: "PublicDomainPictures at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "mussels.jpg",
    ImageSource: "donieve at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "birdwatch.jpg",
    ImageSource: "stevepb at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "crab.jpg",
    ImageSource: "pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "wfowl-hunt.jpg",
    ImageSource: "karisjo at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "birds.jpg",
    ImageSource: "Slavicsly at Pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "rec-fish.jpg",
    ImageSource: "aranha at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "filtration.jpg",
    ImageSource: "zoosnow at pixabay",
    ImageLiscence: "Free for commercial use",
  },
  {
    ImageFile: "rockweed.jpg",
    ImageSource: "Aislyn Keyes",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "phyto.jpg",
    ImageSource: "NEON ja",
    ImageLiscence: "Free to copy and adapt with credit",
  },
  {
    ImageFile: "b-mussel.jpg",
    ImageSource: "Aislyn Keyes",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "barnacles.jpg",
    ImageSource: "Aislyn Keyes",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "periwinkle.jpeg",
    ImageSource: "victor0001",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "isopod.jpeg",
    ImageSource: "hitchco",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "dog-whelk.jpeg",
    ImageSource: "steve_mcwilliam",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "urchin.jpg",
    ImageSource: "rambryum",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "green-crab.jpeg",
    ImageSource: "steve_mcwilliam",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "tautog.jpeg",
    ImageSource: "Lily M",
    ImageLiscence: "Attribution-NonCommercial 4.0 International†(CC BY-NC 4.0)",
  },
  {
    ImageFile: "rock-gunnel.jpg",
    ImageSource: "fynkynd",
    ImageLiscence: "Attribution-ShareAlike 4.0 International†(CC BY-SA 4.0)",
  },
  {
    ImageFile: "jonah-crab.jpeg",
    ImageSource: "ianmanning",
    ImageLiscence: "Attribution 4.0 International†(CC BY 4.0)",
  },
  {
    ImageFile: "purple-sandpiper.jpg",
    ImageSource: "Melanie Gaddy",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "gull.jpg",
    ImageSource: "Aislyn Keyes",
    ImageLiscence: "CC0 1.0 Universal†(CC0 1.0)",
  },
  {
    ImageFile: "epipelic-algae.jpg",
    ImageSource: "melodyhub",
    ImageLiscence: "Free for commercial use, No attribution required",
  },
  {
    ImageFile: "mahogany-clam.jpg",
    ImageSource: "kdaniel20816",
    ImageLiscence: "Public domain, no rights reserved",
  },
  {
    ImageFile: "mammel-icon.svg",
    ImageSource: "Raccoon by Georgiana Ionescu from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "microscopic-icon.svg",
    ImageSource: "Microbes by Dima Lagunov from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "plant-icon.svg",
    ImageSource: "Plant by Fiki Ahmadi from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "bird-icon.svg",
    ImageSource: "Bird by David Khai from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "insect-icon.svg",
    ImageSource: "Dragonfly by Deemak Daksina from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "fish-icon.svg",
    ImageSource: "Fish by trang5000 from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "plankton-icon.svg",
    ImageSource: "Microbe by Vectorstall from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "mollusc-icon.svg",
    ImageSource: "Snail by Juraj Sedl�k from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "bino.svg",
    ImageSource: "Binoculars by Chanut is Industries from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "water_plant.svg",
    ImageSource: "Water Plant by Dron Desain from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "wave.svg",
    ImageSource: "wave by Srinivas Agra from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "boat.svg",
    ImageSource: "fishing boat by Ph?m Thanh L?c from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "hook.svg",
    ImageSource: "Hook by iconoci from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "co2.svg",
    ImageSource: "Carbon Dioxide by Hamish from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "target.svg",
    ImageSource: "Target by Raden Septiana Faza from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "water_drop.svg",
    ImageSource: "Water Drop by Martins Ratkus from the Noun Project",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
  {
    ImageFile: "sea-urchin-icon.svg",
    ImageSource: "Sea Urchin by Hanna Vernydub from NounProject.com",
    ImageLiscence: "Creative Commons Attribution 3.0",
  },
];

export const levels = [
  {
    level: 1,
    intro:
      "You recently accepted a position working for the Carpinteria Salt Marsh Reserve in Santa Barbara, California. You work in conservation, and part of your job requires you to monitor the species living in the salt marsh, and to take action to maintain the ecosystem. The Carpinteria Salt Marsh Reserve supports a great diversity of life and plays an important role in many ecosystem services that your community relies on. In your first week on the job, your supervisor asks you to monitor the water quality of the reserve. As an ecologist, you have a general idea of which types of species help maintain water quality, and how they interact with other types of species. But, as a new resident in California, you still don't know the species themselves.",
    objective:
      "Ecosystem service are nature's contributions to people. Your supervisor requests that you conduct a brief study to maximize the number of ecosystem services at the reserve with a limited budget. Maintain as many ecosystem services as you can by protecting one type of species.",
    topBarCopy:
      "Level 1 Goal: Protect (left click [or single tap]) 1 species to maintain all 8 ecosystem services",
    removableIDs: [300],
    shownGraphs: ["num-services"],
    saves: 1,
    initialKills: 1,
    win: "You won! Often times in conservation, we don't have sufficient resources to protect all species or the ecosystem services they provide. Awesome job prioritizing species that play a significant role in maintaining the ecosystem and its services!",
    lose: "You lost! Often times in conservation, we don't have sufficient resources to protect all species or the ecosystem services they provide. Think about which types of species are really the foundation of many ecosystems.",
  },
  {
    level: 2,
    intro:
      "You make new friends at the local Audubon Society meeting and explain to them that you're new to the area and are still learning the local flora and fauna. They agree to take you birdwatching at the Carpinteria Salt Marsh Reserve, and you learn a few new bird species.",
    objective:
      "Since salt marshes exist at the intersection of fresh water from rivers and salt water from the ocean, ocean acidification - the ongoing decrease in the ocean's pH - is reaching the salt marsh.  Ocean acidifcation impacts calcifying organisms, including those that build shells. Habitat destruction also threatens coastal ecosystems. Maintain water filtration in the salt marsh to ensure water quality.",
    topBarCopy:
      "Level 2 Goal: Protect (left click [or single tap]) 1 species to maintain water filtration",
    removableIDs: [500, 300],
    shownGraphs: ["water-filtration"],
    saves: 1,
    initialKills: 2,
    win: "You won! Nice work using all the information provided. Ocean acidification and habitat loss are major threats to coastal ecosystems.",
    lose: "You lost! In conservation, it can be hard to know which species are going to be impacted by a given disturbance. On your next try, think about the species that form habitat since they likely are the ones directly impacted by habitat destruction. Remember that ocean acidification impacts shell-forming organims.",
  },
  {
    level: 3,
    intro:
      "While on your weekly monitoring walk through the reserve, you stumble upon some recreational fishers. Because you haven't had a chance to identify the local fish species yet, you decide to spend some time with the fishers, getting to know a few of the species. While talking to the fishers, you learn that they've noticed decreases in some of their favorite fish species.",
    objective:
      "Salt marshes are not only vulnerable to ocean acidification but also changes in nutrient levels, which can cause eutrophication, where excess nutrient levels lead to increased algal growth and decreased oxygen levels. Decreased oxygen, also known as hypoxia, can lead to fish kills. Maintain the recreational fishery.",
    removableIDs: [900, 500, 120, 118, 125, 800, 700],
    topBarCopy:
      "Level 3 Goal: Protect (left click [or single tap]) 2 species to maintain recreational fisheries",
    shownGraphs: ["recreational-fishery"],
    saves: 2,
    initialKills: 7,
    win: "You won! Recreational fisheries are an important ecosystem service in many coastal areas, so maintaining the levels of fish that people enjoy fishing is important. Great job!",
    lose: "You lost! Sometimes recreational fishers will collect other types of organisms besides fish for sustenance. As a hint, what types of seafood do you know of besides fish?",
  },
  [
    {
      level: 4,
      type: 0,
      intro:
        "After many weeks birdwatching with your friends, you finally know all of the birds in the Carpinteria Salt Marsh Reserve! You also had a meeting with local Fish and Wildlife offices, where you discussed waterfowl hunting permits in nearby salt marshes. Hunting doesn't take place in the reserve but is allowed in neighboring marshlands, and birds often move between the areas. Officials mention that these neighboring marshlands are hotspots for hunting, which generate important conservation resources through fees and permits. They request that your department surveys the waterfowl population in the reserve, and take any necessary actions to maintain stable populations.",
      objective: "Maintain waterfowl hunting in the surrounding area.",
      topBarCopy:
        "Level 4 Goal: Protect (left click [or single tap]) 2 species to maintain waterfowl hunting",
      removableIDs: [300, 500, 600],
      shownGraphs: ["waterfowl-hunting"],
      saves: 2,
      initialKills: 3,
      win: "You won! Sometimes, you can't predict what type of disturbance will impact an ecosystem. Excellent job using the information you have from the food web to maintain waterfowl hunting!",
      lose: "You lost! Sometimes you can't predict what type of disturbance will impact an ecosystem. Next time you try this level, try using the information you have about the food web to make the best decision.",
    },
    {
      level: 4,
      type: 1,
      intro:
        "After many weeks birdwatching with your friends, you finally know all of the birds in the Carpinteria Salt Marsh Reserve! After meeting with your team about how to draw in new visitors, you decide to put more effort into protecting and enhancing the birdwatching experience at the reserve.",
      objective:
        "Coastal wetlands, like salt marshes, can be contaminated with pesticides and other pollutants from upstream and adjacent lands. Recently, while monitoring water quality you noticed higher concentrations of these chemicals in the reserve. Maintain the birdwatching service by mitigating the impacts of pesticide contamination.",
      removableIDs: [600, 900, 800, 500],
      shownGraphs: ["bird-watching"],
      saves: 2,
      initialKills: 4,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 4,
      type: 2,
      intro:
        "After many weeks birdwatching with your friends, you finally know all of the birds in the Carpinteria Salt Marsh Reserve! Last week you attended a seminar on the impacts of climate change on coastal wetlands. You  learned that as sea levels rise, salt marsh plants move upland, potentially taking over previously forested or suburban areas, or squeezing salt marshes out of existence (called 'coastal squeeze'). Climate change can also play a major role in increasing soil salinity by increasing the rate or evaporation. ",
      objective:
        "Increasing soil salinty can lead to changes in community structure and function. There is also evidence that increased soil salinty can lead to increased below ground biomass, which is linked to carbon storage. Sea level rise can also result in lost habitat for fish and bird species. Maintain carbon storage and commercial fisheries.",
      removableIDs: [300, 500, 800, 900],
      shownGraphs: ["carbon-storage", "commercial-fishery"],
      saves: 2,
      initialKills: 4,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 4,
      type: 3,
      intro:
        "After many weeks birdwatching with your friends, you finally know all of the birds in the Carpinteria Salt Marsh Reserve! Coastal areas are often vulnerable to development and habitat loss. A large company has proposed a nearby project that could lead to habitat loss in the adjacent salt marsh that is not protected by the reserve.  Your department at the reserve was hired to provide expert opinion in the environmental impact assessment before the project can be approved.",
      objective:
        "Maintain a species richness of at least 70%, and maintain shoreline protection and wave attenuation. Given the budget, you can only protect 2 species.",
      removableIDs: [300, 600, 118, 120, 900],
      shownGraphs: [
        "shoreline-protection",
        "wave-attenuation",
        "species-remaining",
      ],
      saves: 2,
      initialKills: 5,
      win: "You Won!",
      lose: "You lost :(",
    },
  ],
  [
    {
      level: 5,
      type: 0,
      intro:
        "You spent some time this week surveying the mollsucs and crabs in the reserve, slowly building up your knowledge of the system.  You also had a meeting with local Fish and Wildlife offices, where you discussed waterfowl hunting permits in nearby salt marshes. Hunting doesn't take place in the reserve but is allowed in neighboring marshlands, and birds often move between the areas. Officials mention that these neighboring marshlands are hotspots for hunting, which generate important conservation resources through fees and permits. They request that your department surveys the waterfowl population in the reserve, and take any necessary actions to maintain stable populations.",
      objective: "Maintain waterfowl hunting in the surrounding area.",
      removableIDs: [61, 66, 68, 300, 500, 600],
      shownGraphs: ["waterfowl-hunting"],
      saves: 4,
      initialKills: 6,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 5,
      type: 1,
      intro:
        "You spent some time this week surveying the mollsucs and crabs in the reserve, slowly building up your knowledge of the system. After meeting with your team about how to draw in new visitors, you decide to put more effort into protecting and enhancing the birdwatching experience at the reserve. ",
      objective:
        "Coastal wetlands, like salt marshes, can be contaminated with pesticides and other pollutants from upstream and adjacent lands. Recently, while monitoring water quality you noticed higher concentrations of these chemicals in the reserve. Maintain the birdwatching service by mitigating the impacts of pesticide contamination.",
      topBarCopy:
        "Level 5 Goal: Protect (left click [or single tap]) 4 species to maintain birdwatching",
      removableIDs: [600, 900, 144, 66, 68, 118, 500, 61],
      shownGraphs: ["bird-watching"],
      saves: 4,
      initialKills: 8,
      win: "You won! Salt marshes are a great example of the connection between the ocean and land since they exist at the intersection of freshwater rivers and salty, marine water. The actions we take far away from the ocean can still impact these coastal areas, just as the pesticdes are in this scenario. Great work prioritizing which species to protect!",
      lose: "You lost! Salt marshes are a great example of the connection between the ocean and land since they exist at the intersection of freshwater rivers and salty, marine water. The actions we take far away from the ocean can still impact these coastal areas, just as the pesticdes are in this scenario. In conservation, protecting a species does not guarantee that it survives a disturbance, or long term changes. Remember, even if a species is not directly impacted by a disturbance, it can still be indirectly impacted if it loses its resources. Sifting through lots of information is challenging, so try using the sub-graph feature to simplify the decision. ",
    },
    {
      level: 5,
      type: 2,
      intro:
        "You spent some time this week surveying the mollsucs and crabs in the reserve, slowly building up your knowledge of the system. Last week you attended a seminar on the impacts of climate change on coastal wetlands. You learned that as sea levels rise, salt marsh plants move upland, potentially taking over previously forested or suburban areas, or squeezing salt marshes out of existence (called 'coastal squeeze'). Climate change can also play a major role in increasing soil salinity by increasing the rate or evaporation. ",
      objective:
        "Increasing soil salinty can lead to changes in community structure and function. There is also evidence that increased soil salinty can lead to increased below ground biomass, which is linked to carbon storage. Sea level rise can also result in lost habitat for fish and bird species. Maintain carbon storage and commercial fisheries.",
      removableIDs: [300, 68, 111, 120, 500],
      shownGraphs: ["carbon-storage", "commercial-fishery"],
      saves: 4,
      initialKills: 5,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 5,
      type: 3,
      intro:
        "You spent some time this week surveying the mollsucs and crabs in the reserve, slowly building up your knowledge of the system. Coastal areas are often vulnerable to development and habitat loss. A large company has proposed a nearby project that could lead to habitat loss in the adjacent salt marsh that is not protected by the reserve.  Your department at the reserve was hired to provide expert opinion in the environmental impact assessment before the project can be approved. ",
      objective:
        "Maintain a species richness of at least 70%, and maintain shoreline protection and wave attenuation. Given the budget, you can only protect 4 species.",
      removableIDs: [300, 118, 125, 500, 600, 900],
      shownGraphs: [
        "shoreline-protection",
        "wave-attenuation",
        "species-remaining",
      ],
      saves: 4,
      initialKills: 6,
      win: "You Won!",
      lose: "You lost :(",
    },
  ],
  [
    {
      level: 6,
      type: 0,
      intro:
        "Last week you attended a seminar on the impacts of climate change on coastal wetlands. The leading scientist at the seminar helped you identify more plant and mollusc species. You also had a meeting with local Fish and Wildlife offices, where you discussed waterfowl hunting permits in nearby salt marshes. Hunting doesn't take place in the reserve but is allowed in neighboring marshlands, and birds often move between the areas. Officials mention that these neighboring marshlands are hotspots for hunting, which generate important conservation resources through fees and permits. They request that your department surveys the waterfowl population in the reserve, and take any necessary actions to maintain stable populations.",
      objective: "Maintain waterfowl hunting in the surrounding area.",
      removableIDs: [
        61, 66, 68, 5, 6, 7, 8, 9, 10, 11, 16, 61, 64, 65, 66, 67, 68, 70, 71,
        72, 73, 75, 76, 79, 600,
      ],
      shownGraphs: ["waterfowl-hunting"],
      saves: 6,
      initialKills: 25,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 6,
      type: 1,
      intro:
        "Last week you attended a seminar on the impacts of climate change on coastal wetlands. The leading scientist at the seminar helped you identify more plant and mollusc species.  After meeting with your team about how to draw in new visitors, you decide to put more effort into protecting and enhancing the birdwatching experience at the reserve. ",
      objective:
        "Coastal wetlands, like salt marshes, can be contaminated with pesticides and other pollutants from upstream and adjacent lands. Recently, while monitoring water quality you noticed higher concentrations of these chemicals in the reserve. Maintain the birdwatching service and mitigate the impacts of pesticide contamination.",
      removableIDs: [600, 900, 118, 120, 125, 114, 72, 68, 75, 82, 66, 79],
      shownGraphs: ["bird-watching"],
      saves: 6,
      initialKills: 12,
      win: "You Won!",
      lose: "You lost :(",
    },
    {
      level: 6,
      type: 2,
      intro:
        "Last week you attended a seminar on the impacts of climate change on coastal wetlands. The leading scientist at the seminar helped you identify more plant and mollusc species. You also learned that as sea levels rise, salt marsh plants move upland, potentially taking over previously forested or suburban areas, or squeezing salt marshes out of existence (called 'coastal squeeze'). Climate change can also play a major role in increasing soil salinity by increasing the rate or evaporation.",
      objective:
        "Increasing soil salinty can lead to changes in community structure and function. There is also evidence that increased soil salinty can lead to increased below ground biomass, which is linked to carbon storage. Sea level rise can also result in lost habitat for fish and bird species. Maintain carbon storage level while protecting the fish species that are important for commerical fisheries.",
      topBarCopy:
        "Level 6 Goal: Protect (left click [or single tap]) 6 species to maintain carbon storage and commercial fisheries",
      removableIDs: [16, 11, 8, 68, 89, 111, 113, 114, 120, 109],
      shownGraphs: ["carbon-storage", "commercial-fishery"],
      saves: 6,
      initialKills: 10,
      win: "You won! It can be really challenging to try to maintain multiple ecosystem services at once. Excellent work!",
      lose: "You lost! It can be really challenging trying to maintain multiple ecosystem services at once. In conservation, protecting a species doesn't guarentee that it survives a disturbance. Remember, even if a species is not directly impacted by a disturbance, it can still be indirectly impacted if it loses its resources. Sifting through lots of information is challenging, so try using the sub-graph feature to simplify the decision.",
    },
    {
      level: 6,
      type: 3,
      intro:
        "Last week you attended a seminar on the impacts of climate change on coastal wetlands. The leading scientist at the seminar helped you identify more plant and mollusc species.  Coastal areas are often vulnerable to development and habitat loss. A large company has proposed a nearby project that could lead to habitat loss in the adjacent salt marsh that is not protected by the reserve.  Your department at the reserve was hired to provide expert opinion in the environmental impact assessment before the project can be approved.",
      objective:
        " Maintain a species richness of at least 70%, and maintain shoreline protection and wave attenuation. Given the budget, you can only protect 6 species.",
      removableIDs: [
        5, 6, 7, 8, 9, 10, 11, 12, 16, 118, 125, 64, 65, 67, 900, 600,
      ],
      shownGraphs: [
        "shoreline-protection",
        "wave-attenuation",
        "species-remaining",
      ],
      saves: 6,
      initialKills: 16,
      win: "You Won!",
      lose: "You lost :(",
    },
  ],
  [
    {
      level: 7,
      type: 0,
      intro:
        "You've been in Santa Barbara for some time now, and you've finally had a chance to take a diversity survey of the Carpinteria Salt Marsh Reserve. You spent some time in the field practicing species identification after you found a database with all of the local flora and fauna. You identified 107 species in the reserve and have watched them interact with each other. You also had a meeting with local Fish and Wildlife offices, where you discussed waterfowl hunting permits in nearby salt marshes. Hunting doesn't take place in the reserve but is allowed in neighboring marshlands, and birds often move between the areas. Officials mention that these neighboring marshlands are hotspots for hunting, which generate important conservation resources through fees and permits. They request that your department surveys the waterfowl population in the reserve, and take any necessary actions to maintain stable populations.",
      objective: "Maintain waterfowl hunting in the surrounding area.",
      removableIDs: [
        61, 66, 68, 5, 6, 7, 8, 9, 10, 11, 16, 61, 64, 65, 66, 67, 68, 70, 71,
        72, 73, 75, 76, 79, 25, 35, 44, 47, 51,
      ],
      shownGraphs: ["waterfowl-hunting"],
      saves: 8,
      initialKills: 29,
      win: "You Won!",
      lose: "You Lost :(",
    },
    {
      level: 7,
      type: 1,
      intro:
        "You've been in Santa Barbara for some time now, and you've finally had a chance to take a diversity survey of the Carpinteria Salt Marsh Reserve. You spent some time in the field practicing species identification after you found a database with all of the local flora and fauna. You identified 107 species in the reserve and have watched them interact with each other. After meeting with your team about how to draw in new visitors, you decide to put more effort into protecting and enhancing the birdwatching experience at the reserve.",
      objective:
        "Coastal wetlands, like salt marshes, can be contaminated with pesticides and other pollutants from upstream and adjacent lands. Recently, while monitoring water quality you noticed higher concentrations of these chemicals in the reserve. Maintain the birdwatching service and mitigate the impacts of pesticide contamination.",
      removableIDs: [
        25, 35, 44, 47, 50, 118, 120, 125, 114, 68, 75, 82, 66, 79, 61, 70, 87,
        89, 104, 99, 116, 119, 139,
      ],
      shownGraphs: ["bird-watching"],
      saves: 8,
      initialKills: 23,
      win: "You Won!",
      lose: "You Lost :(",
    },
    {
      level: 7,
      type: 2,
      intro:
        "You've been in Santa Barbara for some time now, and you've finally had a chance to take a diversity survey of the Carpinteria Salt Marsh Reserve. You spent some time in the field practicing species identification after you found a database with all of the local flora and fauna. You identified 107 species in the reserve and have watched them interact with each other. Last week you attended a seminar on the impacts of climate change on coastal wetlands. You  learned that as sea levels rise, salt marsh plants move upland, potentially taking over previously forested or suburban areas, or squeezing salt marshes out of existence (called 'coastal squeeze'). Climate change can also play a major role in increasing soil salinity by increasing the rate or evaporation. ",
      objective:
        "Increasing soil salinty can lead to changes in community structure and function. There is also evidence that increased soil salinty can lead to increased below ground biomass, which is linked to carbon storage. Sea level rise can also result in lost habitat for fish and bird species. Maintain carbon storage level while protecting the fish species that are important for commerical fisheries.",
      removableIDs: [16, 11, 8, 68, 89, 100, 111, 120, 109, 114, 113],
      shownGraphs: ["carbon-storage", "commercial-fishery"],
      saves: 8,
      initialKills: 11,
      win: "You Won!",
      lose: "You Lost :(",
    },
    {
      level: 7,
      type: 3,
      intro:
        "You've been in Santa Barbara for some time now, and you've finally had a chance to take a diversity survey of the Carpinteria Salt Marsh Reserve. You spent some time in the field practicing species identification after you found a database with all of the local flora and fauna. You identified 107 species in the reserve and have watched them interact with each other. Now that you know much more about the ecosystem, you're confident that you'll be able to make more informed decisions about protecting the ecosystem. Coastal areas are often vulnerable to development and habitat loss. A large company has proposed a nearby project that could lead to habitat loss in the adjacent salt marsh that is not protected by the reserve.  Your department at the reserve was hired to provide expert opinion in the environmental impact assessment before the project can be approved.",
      objective:
        " Maintain a species richness of at least 70%, and maintain shoreline protection and wave attenuation. Given the budget, you can only protect 7 species.",
      topBarCopy:
        "Level 7 Goal: Protect (left click [or single tap]) 7 species to maintain shoreline protection, wave attenuation, and at least 70% of species",
      removableIDs: [
        5, 6, 7, 8, 9, 10, 11, 12, 16, 118, 121, 122, 125, 64, 65, 67, 25,
      ],
      shownGraphs: [
        "shoreline-protection",
        "wave-attenuation",
        "species-remaining",
      ],
      saves: 7,
      initialKills: 17,
      win: "You won! Wow, you've really gotten good at your job. There are so many species at the Carpinteria Salt Marsh Reserve and you've managed to protect many of them, and the ecosystem services they provide. Well done!",
      lose: "You lost! In science, we often have to deal with high levels of complexity and uncertainty. You've acquired so much knowledge about the ecosystem you're working in, so now you have to try to simplify this information so it's easier to comprehend. Remember, protecting a species doesn't guarentee that it survives a disturbance. Even if a species is not directly impacted by a disturbance, it can still be indirectly impacted if it loses its resources. ",
    },
  ],
];
